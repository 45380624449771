import CheckIcon from '@mui/icons-material/Check';

type CheckListProps = {
  text: string;
  textColor: string;
  iconColor: string;
  plan: string;
};

export const CheckListItems = (props: CheckListProps) => {
  return (
    <>
      {props.plan === 'Beginner' && props.text[0] === '' ? (
        <div></div>
      ) : (
        <div className={`align-center flex gap-[16px]`}>
          <CheckIcon sx={{ color: `${props.iconColor}` }} />{' '}
          <span>
            {props.plan === 'Beginner'
              ? props.text[0]
              : props.plan === 'Advanced'
              ? props.text[1]
              : props.text[2]}
          </span>
        </div>
      )}
    </>
  );
};
