function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';
const SHOPLINE_HOME = '';

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  verify: path(ROOTS_AUTH, '/verify'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  newPassword: path(ROOTS_AUTH, '/new-password'),
};

export const PATH_PAGE = {
  page404: '/404',
  invalidToken: '/Invalid-token',
  components: '/components',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
  },
};

export const SHOPLINE_NAVIGATION = {
  root: SHOPLINE_HOME,
  onboard: path(SHOPLINE_HOME, '/setup'),
  login: (token: string) =>
    '/user/login/?user_token=' + path(SHOPLINE_HOME, token),
  general: {
    home: (portalId: number) =>
      '/' + portalId + path(SHOPLINE_HOME, '/dashboard'),
    deals: (portalId: number, tabIndex: string) =>
      '/' + portalId + path(SHOPLINE_HOME, '/deals/' + tabIndex),
    contacts: (portalId: number, tabIndex: string) =>
      '/' + portalId + path(SHOPLINE_HOME, '/contacts/' + tabIndex),
    products: (portalId: number, tabIndex: string) =>
      '/' + portalId + path(SHOPLINE_HOME, '/products/' + tabIndex),
    automation: (portalId: number) =>
      '/' + portalId + path(SHOPLINE_HOME, '/automation'),
    settings: (portalId: number) =>
      '/' + portalId + path(SHOPLINE_HOME, '/settings'),
    logs: (portalId: number) => '/' + portalId + path(SHOPLINE_HOME, '/logs'),
    activity: (portalId: number, tabIndex: string) =>
      '/' + portalId + path(SHOPLINE_HOME, '/activities/' + tabIndex),
    plan: (portalId: number) => '/' + portalId + path(SHOPLINE_HOME, '/plan'),
  },
};
