// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const cn = {
  shopline_integration: '店線集成',
  getting_started_with_shopline_hubspot_integration:
    '開始使用 HubSpot 和 Shopline',
  with_shopline_hubspot_integration_you_can_sync_shopline_contact_customer_with_hubspot_crm:
    '通過此Shopline HubSpot集成，您可以自動將所有Shopline聯繫人和客戶與HubSpot的CRM和行銷平臺同步。',
  once_set_up_integration_you_will_be_able_to: '設置此整合后，您將能夠',
  see_every_action_contact_including_page_view_past_orders_abondoned_card_and_more:
    '查看每個聯繫人採取的每個操作，包括他們的頁面流覽量、過去的訂單、廢棄的卡片等 - 在 HubSpot crm 整潔的時間線檢視中',
  segment_contact_customers_list_based_previous_interactions_with_store:
    '根據聯繫人和客戶以前與商店的互動，將他們細分為清單',
  easily_create_and_send_beautiful_responsive_emails_to_drive_sales:
    '輕鬆創建和發送美觀、回應迅速的電子郵件，以推動銷售',
  measure_store_performance_with_customer_reports_and__dashboards:
    '使用自定義報告和儀錶板衡量商店的績效',
  to_get_started_connect_hubspot_account_dont_have_account_create_one_then_return_to_this_window_to_connect:
    '要開始使用，請連接您的 HubSpot 帳戶。如果您沒有 HubSpot 帳戶，請創建一個帳戶，然後返回此視窗進行連接。',
  connect_your_account: '關聯您的帳戶',
  create_free_hubspot_account: '創建一個免費的 HubSpot 帳戶',
  redirecting_to_hubspot_account_page: '重定向至HubSpot帳戶頁面',
  lorem_ipsum:
    'Lorem ipsum dolor sit ametLorem ipsum dolor sit amet， consectetur adipiscing elit.A， ut ipsum lectus purus.Lorem ipsum dolor sit amet， consectetur adipiscing elit.A， ut ipsum lectus purus.',
  hubspot_integration: '樞紐點集成',
  by_makewebbetter: '作者：MakeWebBetter',
  please_wait_while_we_setup_your_account: '請稍候。。。當我們設置您的帳戶時',
  waiting_screen_description: '任何偉大的作品都沒有痛苦.....',
  starter_plan: '入門計劃',
  saving_24_per_year: '節省$ 24 /年',
  saving_49_per_year: '每年節省49美元',
  months_updates: '12個月更新',
  months_of_support: '6 個月的支援',
  javascript_version: 'JavaScript 版本',
  typescript_version: '打字稿版本',
  pro_plan: '專業計劃',
  design_resources: '設計資源',
  commercial_applications: '商業應用',
  choose_plan: '選擇計劃',
  selected_plan_for_your_bussiness: '選擇適合您業務的方案',
  need_any_help: '需要任何説明嗎？',
  import_from_shopline: '从 Shopline 导入数据',
  import_all_the_existing_data_from_your_store: '从您的商店导入所有现有数据。',
  choose_the_perfect_plan_for_your_need:
    '選擇適合您需求的完美計劃。始終靈活發展',
  sync_now: '立即同步',
  import_now: '立即导入',
  home: '家',
  skip_data_sync: '跳過數據同步',
  contact: '聯繫',
  deal: '交易',
  products: '產品',
  settings: '設置',
  logs: '原木',
  hubspot_portal_id: '中心點 {{value}}',
  historical_data_sync_enabled: '历史数据同步{{value}}',
  contact_synced: '連絡人已同步',
  deals_synced: '交易已同步',
  products_synced: '產品已同步',
  waiting_to_sync: '{{value}} 等待同步',
  last_sync: '上次同步：{{value}}',
  manage_notifications: '管理通知',
  alert_picklist_sync_error_found: '發現警報選擇清單同步錯誤',
  this_is_an_info_alert: '這是一個資訊警報 - 檢查一下！',
  creating_workflows: '建立工作流 . . .',
  creating_pipelines: '建立管道 . . .',
  creating_lists: '建立清單 . . .',
  yes_take_me_to_hubspot: '是的，帶我去HubSpot。',
  setup_group_properties: '在 HubSpot 中設置組和屬性',
  setup_line_1:
    '為了在HubSpot中正確查看您的Shopline數據，您需要在HubSpot帳戶中設置組和屬性。',
  setup_line_2: '設定群組和屬性後，您可以輕鬆查看有關聯絡人和客戶的以下資訊：',
  setup_line_3:
    '組和屬性創建正在進行中。這應該只需要幾分鐘。感謝您的耐心等待！',
  setup_line_4: '設置清單以根據聯繫人和客戶以前的操作和行為對其進行細分。',
  create_groups_properties: '創建組和屬性',
  view_dashboard: '查看儀錶板',
  dashboard: '擋泥板',
  product_information: '產品資訊',
  order_information: '訂單資訊',
  customer_information: '客戶資訊',
  previous_purchase: '以前的購買',
  abandoned_cart_details: '廢棄購物車詳細資訊',
  and_more: '以及更多',
  create_lists_in_hubspot: '在 HubSpot 中创建列表',
  lists_creation_in_progress:
    '清單創建正在進行中。這應該只需要幾分鐘。感謝您的耐心等待！',
  leads_contact: '潛在顧客：尚未下訂單的聯繫人',
  create_list: '創建清單',
  customer_contact: '客戶：至少下過一個訂單的聯繫人',
  abandoned_cart_contacts: '廢棄購物車：已將產品添加到購物車但尚未購買的聯繫人',
  workflows_creation_in_progress:
    '工作流創建正在進行中。這應該只需要幾分鐘。感謝您的耐心等待！',
  next_step: '下一步',
  gat_tailored_onboarding_emails_straight_to_your_inbox:
    '將定製的入職電子郵件直接發送到您的收件匣',
  once_sync_your_data_you_ll_see_you_Shopline_information_on_hubspot:
    '同步數據后，您將在HubSpot上看到所有Shopline資訊，以便您可以立即開始與他們互動。',
  almost_done_last_step_to_sync_existing_Shopline_data_to_hubspot:
    '你快完成了！最後一步是將您現有的Shopline數據同步到HubSpot。這會將您的所有聯繫人、交易和產品數據同步到 HubSpot。',
  sync_order_status_with_deal_stages: '將訂單狀態與交易階段同步',
  sync_order_status_line_1: '因此，您可以在商店中管理您的電子商務管道',
  shopline_order_status_deal_stages: '店鋪訂單狀態和促銷階段',
  reset_to_default_mapping: '重置為預設映射',
  congrats: '祝賀',
  save: '救',
  please_contact_support: '請聯繫支持人員',
  export: '出口',
  view_in_hubspot: '在 HubSpot 中查看',
  sync_selected_data_row: '同步所選取資料行',
  first_name: '名字',
  last_name: '姓',
  company: '公司',
  email: '電子郵件',
  phone: '電話',
  search_customer: '搜索邮箱',
  has_been_found: '已找到',
  customer: '客戶',
  you_can_see_your_sync_progress_in_the_activity_tab:
    '您可以在「活動」標籤中查看同步進度。',
  sync_unsuccessfull: '同步不成功',
  unable_to_sync_all_the_data: '無法同步數據。請重試同步。',
  close_this_window_to_see_updated_data_tables: '關閉此視窗以查看更新的數據表',
  select_atleast_one_product_to_export: '請至少選擇一個產品進行出口',
  status: '地位',
  all_the_data_is_successfully_synced: '數據已成功同步。',
  sync_in_progress: '同步正在進行中',
  sync_successfull: '同步成功',
  name: '名字',
  please_sync_first: '請先同步',
  search_product: '搜索产品名称',
  search_deals: '搜索交易編號',
  price: '價格',
  title: '標題',
  go_to_support: '轉到支援',
  product_type: '產品類型',
  contact_values: '聯繫值',
  deal_status: '交易狀態',
  sorry_text_404:
    '很抱歉，我們找不到您要查找的頁面。也許您輸入了錯誤的網址？請務必檢查您的拼寫。',
  no_activities_found: '未找到活動',
  activities: '活動',
  synced: '同步',
  active: '積極',
  inactive: '無效',
  not_synced: '未同步',
  product_mapping: '產品欄位映射',
  deal_mapping: '交易欄位映射',
  contact_mapping: '聯繫人欄位映射',
  view_details: '查看詳情',
  hubspot_data_logs: 'HubSpot 数据日志',
  note: '注意：HubSpot 紀錄可用於過濾資料同步期間發生的 HubSpot 錯誤/警告。',
  error_type: '錯誤類型：',
  object_type: '物件類型：',
  internal_object_id: '內部對象 ID',
  action: '行動',
  created_at: '創建於',
  view_all: '查看全部',
  no: '不',
  operation: '操作',
  deal_contact: '交易聯繫',
  line_item: '訂單項',
  line_item_deal: '訂單項交易',
  all: '都',
  token_got_expired_please_refresh: '令牌已過期，請刷新',
  select_a_time_period: '選擇時間段',
  start_date: '開始日期',
  end_date: '結束日期',
  clear_all: '全部清除',
  from: '從',
  save_mapping: '保存映射',
  go_to_dashboard: '轉到儀表板',
  date_range_for_user: '用戶/訂單同步的日期範圍',
  to: '至',
  created: '創建',
  create: '創建',
  lists: '列表',
  workflow: '工作流程',
  workflow_line_1: '工作流包含一组根据触发事件自动执行的操作。 ',
  workflow_details: '工作流程詳細信息',
  setup_onboarding: '設置入職',
  congrats_hubspot_connected: '恭喜 HubSpot 已連接',
  success: '成功',
  syncing_started: '同步過程現已開始',
  starting_sync: '開始同步',
  sync_error_occured: '發生一些錯誤',
  sync_updates: '同步更新',
  total_order: '总订单',
  available_order: '可用订单',
  used_order: '二手订单',
  your_active_plan: '您的活动计划',
  cancel_plan: '取消计划',
  upgrade_plan: '升级计划',
  start_plan: '启动计划',
  credit: '信用',
  start_setup_onboarding: '開始入職',
  recent_notifications_to_stay_up_to_date: '最近的通知保持最新。 ',
  enable_two_way_sync_for_contact_tags: '為聯繫人標籤啟用雙向同步',
  enable_abondoned_cart_sync: '啟用廢棄的購物車同步',
  enable_hubspot_tracking_script: '啟用 hubSpot 跟踪腳本',
  groups: '組',
  log_detail: '日誌詳細信息',
  close: '關閉',
  general_setting: '常規設置',
  rfm_roi_settings: 'RFM 和 ROI 設置',
  rfm_and_roi_line_1:
    '通过 RFM 设置，您可以根据客户的购买活动（如消费价值、订单频率等）对客户进行细分。',
  gropus_and_properties_line1:
    '组和属性存储 HubSpot 中某些对象的数据，例如联系人、公司、交易和票证。',
  list_line_1:
    '在列表中，您可以存储彼此具有相似特征的记录。您可以使用列表来细分数据。',
  plan: '计划',
  score: '分數',
  recency: '新近度',
  frequency: '頻率',
  monetary: '貨幣',
  less_than: '少於',
  more_than: '多於',
  rating_for_rfm_segmentation: '（RFM 細分評級）',
  days_since_last_order: '（自上次訂單以來的天數）',
  total_orders_placed: '（下訂單總數）',
  total_money_spent: '（花費的總金額）',
  roi_calculation_setting: 'ROI 計算設置',
  rfm_settings_manage_roi_tracking: 'RFM 設置 (管理 ROI 跟踪）',
  calculate_roi_for_the_selected_status: '計算所選狀態的 ROI',
  roi_setting: '投资回报率设定',
  rfm_setting: 'RFM设置',
  invalid_token: '令牌无效',
  deals: '交易',
  customers: '客户',
  deal_number: '交易编号',
  product_id: '产品 ID',
  unsynced: '未同步',
  monthly: '每月',
  month: '月',
  year: '年',
  yearly: '每年',
  all_sync_related_settings: '所有与同步相关的设置',
  something_went_wrong: '出现问题',
  select_a_date_range_for_syncing: '选择同步日期范围',
  select_your_plan: '选择您的计划',
  name_is_required: '姓名是必需的',
  phone_number_not_valid: '电话号码无效',
  email_must_be_a_valid_email_address: '电子邮件必须是有效的电子邮件地址',
  address_is_required: '地址是必需的',
  city_is_required: '城市是必需的',
  state_is_required: '州是必需的',
  zip_is_required: '邮编是必需的',
  country_is_required: '国家是必需的',
  amount_does_not_match_with_plan: '金额不匹配有计划',
  issue_payment_failed: '付款失败',
  payment_successful: '支付成功',
  plan_urgraded: '计划升级成功',
  payment: '电话号码无效1',
  billing_details: '电话号码无效2',
  full_name: '电话号码无效3',
  email_address: '电话号码无效4',
  address: '电话号码无效5',
  city: '电话号码无效6',
  state: '电话号码无效7',
  zip: '电话号码无效8',
  country: '电话号码无效9',
  select_country: '电子邮件必须是有效的电子邮件地址0',
  payment_info: '电子邮件必须是有效的电子邮件地址1',
  order_summary: '电子邮件必须是有效的电子邮件地址2',
  discount: '电子邮件必须是有效的电子邮件地址3',
  apply: '电子邮件必须是有效的电子邮件地址4',
  complete_payment: '电子邮件必须是有效的电子邮件地址5',
  saving_a_year: '保存 ${{value}} 一种年',
  save_yearly: '(保存 {{value}}%)',
  sub_total: '小计',
  add_new_portal: '添加新门户',
  subscription_cancel_redirect_plan: '订阅取消重定向到计划列表',
  previous_proccess_in_progress: '上一个流程正在进行中，敬请等待',
  invoice_history: '发票历史',
  no_record: '没有找到记录',
  confirm_popup: '如果此時切換，上傳任務將被終止 \n您真的要切換門戶嗎?',
  importing: '输入',
  your_plan_has_been_expired: '您的计划已过期!',
  expiry_date: '到期日',
  Hubspot: 'HubSpot',
  you_dont_have_any_active_plan: `您没有任何有效的计划!`,
  ongoing: '进行中',
  completed: '完全的',
  groups_properties: '组和属性',
  list: '列表',
  enabled: '启用',
  disabled: '残疾人',
  manage: '管理',
};

export default cn;
