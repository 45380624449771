import Invalid from 'pages/PageInvalidToken';
import React from 'react';

interface Props {}

class WebpackLoadError extends React.Component<any> {
  state: { hasError: boolean } = { hasError: false };

  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    console.log(error);

    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    console.log(error);
  }

  render() {
    if (this.state.hasError) {
      return <Invalid />;
    }

    return this.props.children;
  }
}

export default WebpackLoadError;
