import { Box } from '@mui/material';
import Page from 'components/Page';
import { useTranslation } from 'react-i18next';
import GroupsAndProperties from './child/GroupsAndProperties';
import RfmAndRoiSettings from './child/RFMandROISettings';
import SettingsCard from './child/SettingsCard';
import Lists from './child/Lists';
import { useSettings } from 'api/ShoplineApiHooks';
import PartialPageSpinner from 'components/PartialPageSpinner';
import useConnector from 'hooks/useConnector';
export default function Settings() {
  const { t } = useTranslation();
  const { sourceShopId, targetShopId } = useConnector();
  const { data } = useSettings(sourceShopId, targetShopId);

  if (data?.data === undefined) {
    return <PartialPageSpinner />;
  }

  return (
    <Page title={t('settings')}>
      <Box>
        <SettingsCard settings={data.data} />
        <RfmAndRoiSettings configurationsData={data.data} />
        <GroupsAndProperties />
        <Lists />
      </Box>
    </Page>
  );
}
