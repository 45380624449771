import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  open: boolean;
  responseContent: string;
  requestContent: string;
  handleOpenClose: () => void;
};
const TABS = [
  {
    value: 'request',
    label: 'Request',
  },
  {
    value: 'response',
    label: 'Response',
  },
];
const LogsDialog = ({
  open,
  responseContent,
  requestContent,
  handleOpenClose,
}: Props) => {
  const { t } = useTranslation();
  const [value, setValue] = useState('request');
  const changeComponent = (tab: any) => {
    setValue(tab.value);
  };

  return (
    <Dialog
      open={open}
      keepMounted
      onClose={handleOpenClose}
      aria-describedby='alert-dialog-slide-description'
    >
      <DialogTitle>
        <Tabs
          allowScrollButtonsMobile
          variant='scrollable'
          scrollButtons='auto'
          sx={{ px: 2, flex: '0 0 100%', maxWidth: '60%' }}
          value={value}
        >
          {TABS.map(tab => (
            <Tab
              disableRipple
              key={tab.value}
              value={tab.value}
              label={tab.label}
              onClick={() => {
                changeComponent(tab);
              }}
            />
          ))}
        </Tabs>
      </DialogTitle>
      {value == 'request' ? (
        <DialogContent>
          <pre>
            {requestContent
              ? JSON.stringify(JSON.parse(requestContent), null, 2)
              : ''}
          </pre>
        </DialogContent>
      ) : (
        <DialogContent>
          <pre>
            {responseContent
              ? JSON.stringify(JSON.parse(responseContent), null, 2)
              : ''}
          </pre>
        </DialogContent>
      )}
      <DialogActions>
        <Button onClick={handleOpenClose}>{t('close')}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default LogsDialog;
