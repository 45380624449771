import {
  Link,
  Checkbox,
  MenuItem,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  Box,
  IconButton,
  CircularProgress,
} from '@mui/material';
import IosShareIcon from '@mui/icons-material/IosShare';
import { DealList } from 'pages/ShoplineType';
import { useTranslation } from 'react-i18next';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { useState } from 'react';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import usePortalId from 'hooks/usePortalId';
import _ from 'lodash';
import useConnector from 'hooks/useConnector';
import Iconify from 'components/Iconify';
import axiosInstance from 'utils/axios';
import { UPLOAD_SINGLE_DEAL_API_URL } from 'api/ShoplineApiConstants';
import useAuth from 'hooks/useAuth';
import { useSnackbar } from 'notistack';
import { capitalizeFirstLetter } from 'utils/letterChange';

type Props = {
  row: DealList;
  selected: boolean;
  onSelectRow: VoidFunction;
};

export default function DealTableRow({ row, selected, onSelectRow }: Props) {
  const { user } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  let toolmsg = null;
  let status = null;
  let hsOrderId = null;
  const portalId = usePortalId();
  const { sourceShopId, targetShopId } = useConnector();
  const [loader, setLoader] = useState<boolean>(false);

  if (row?.targets.length > 0) {
    const hsOid = row.targets.filter(r => r.shop_id === targetShopId);
    if (hsOid.length === 1) {
      if (hsOid[0].status === 'active') {
        status = 'active';
        hsOrderId = hsOid[0].target_order_id;
      } else if (hsOid[0].status === 'error') {
        status = 'error';
        toolmsg = hsOid[0]?.errors[0];
      }
    } else if (hsOid.length > 1) {
      if (hsOid[1].status === 'active') {
        status = 'active';
        hsOrderId = hsOid[1].target_order_id;
      } else if (hsOid[1].status === 'error') {
        status = 'error';
        toolmsg = hsOid[1]?.errors[1];
      }
    } else {
      status = 'unactive';
    }
  }

  const exportSingle = async (id: string) => {
    setLoader(true);
    try {
      const response = await axiosInstance.post(
        UPLOAD_SINGLE_DEAL_API_URL,
        {
          marketplace: 'hubspot',
          source: {
            marketplace: 'shopline',
            shopId: sourceShopId,
          },
          target: {
            marketplace: 'hubspot',
            shopId: targetShopId,
          },
          limit: 10,
          source_order_id: id,
          user_id: (user?.user_id || '') as string,
        },
        {
          headers: {
            'Ced-Source-Id': sourceShopId,
            'Ced-Target-Id': targetShopId,
          },
        }
      );
      if (response.data.success) {
        enqueueSnackbar(capitalizeFirstLetter(response.data.message), {
          variant: 'success',
        });
        setLoader(false);
      } else {
        if (response.data.errors) {
          _.forEach(response.data.errors, error => {
            enqueueSnackbar(capitalizeFirstLetter(error), {
              variant: 'error',
            });
          });
          setLoader(false);
        } else if (response.data.message) {
          enqueueSnackbar(capitalizeFirstLetter(response.data.message), {
            variant: 'error',
          });
          setLoader(false);
        }
      }
    } catch (error) {
      enqueueSnackbar(capitalizeFirstLetter(`${error}`), {
        variant: 'error',
      });
      setLoader(false);
      console.log(error);
    }
  };

  return (
    <TableRow>
      <TableCell width='5%'>
        <Checkbox checked={selected} onClick={onSelectRow} />
      </TableCell>
      <TableCell align='center' width='18%'>
        <Typography variant='body2'>{row.marketplace_reference_id}</Typography>
      </TableCell>
      <TableCell align='center' width='18%'>
        <Typography variant='body2' sx={{ color: 'rgba(99, 115, 129, 1)' }}>
          {row.customer.email}
        </Typography>
      </TableCell>
      <TableCell align='center' width='20%'>
        <Typography variant='body2'>{`${row.marketplace_currency} ${row.total.price}`}</Typography>
      </TableCell>
      <TableCell align='center' width='10%'>
        <Typography
          variant='inherit'
          noWrap
          sx={
            status === 'active'
              ? {
                  // backgroundColor: '#ffedc9',
                  borderRadius: '10px',
                }
              : {
                  borderRadius: '10px',
                  color: 'rgba(99, 115, 129, 1)',
                }
          }
        >
          <Box className='flex items-center justify-center gap-[2px]'>
            {status !== 'active' ? (
              <svg
                width='10'
                height='10'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <circle
                  cx='4'
                  cy='4'
                  r='4'
                  fill='rgba(99, 115, 129, 1)'
                ></circle>
              </svg>
            ) : (
              <Iconify
                icon={'eva:checkmark-circle-fill'}
                color='rgba(34, 154, 22, 1)'
                sx={{ w: '10px', h: '10px' }}
              />
            )}
            <Typography>
              {status === 'active' ? t('synced') : t('unsynced')}
            </Typography>
          </Box>
        </Typography>
      </TableCell>
      <TableCell align='center' width='22%'>
        <Typography variant='body2' align='center'>
          {status === 'active' ? (
            <>
              <div className='flex items-center justify-center'>
                <span>{hsOrderId}</span>
                <Tooltip title={t('view_in_hubspot')}>
                  <Link
                    href={`https://app.hubspot.com/contacts/${portalId}/deal/${hsOrderId}`}
                    target='_blank'
                    sx={{ textDecoration: 'none' }}
                    rel='noreferrer'
                  >
                    <OpenInNewIcon sx={{ height: '15px', mt: '5px' }} />
                  </Link>
                </Tooltip>
              </div>
            </>
          ) : toolmsg !== null ? (
            <Tooltip title={toolmsg}>
              <RemoveRedEyeIcon />
            </Tooltip>
          ) : (
            '-'
          )}
        </Typography>
      </TableCell>
      <TableCell align='center' width='7%'>
        {loader ? (
          <CircularProgress size={20} />
        ) : (
          <Tooltip title='export' arrow>
            <IconButton
              onClick={() => {
                exportSingle(row.marketplace_reference_id);
              }}
            >
              <IosShareIcon sx={{ width: '15px', height: '15px' }} />
            </IconButton>
          </Tooltip>
        )}
      </TableCell>
    </TableRow>
  );
}
