import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import { FormProvider } from 'components/hook-form';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Button,
  CardHeader,
  Divider,
  Stack,
} from '@mui/material';
import Iconify from 'components/Iconify';
import {
  ConfigurationFormValuesProps,
  ConfigurationsDataType,
} from 'pages/ShoplineType';
import RFMcomponent from './RFMcomponent';
import { LoadingButton } from '@mui/lab';
import { useMutation } from 'react-query';
import { useSnackbar } from 'notistack';
import { updateConfigurations } from 'api/ShoplineApiHooks';
import useConnector from 'hooks/useConnector';
import useResponsive from 'hooks/useResponsive';
import { capitalizeFirstLetter } from 'utils/letterChange';

type Props = {
  configurationsData: ConfigurationsDataType | undefined;
};

const ConfigurationsForm: React.FunctionComponent<Props> = ({
  configurationsData,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const smUp = useResponsive('up', 'sm');
  const { sourceShopId, targetShopId } = useConnector();
  const [expanded, setExpanded] = useState({
    rfmAndroisettings: false,
  });
  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded({ ...expanded, [panel]: isExpanded });
    };

  const ACCORDIAN_DATA = [
    {
      title: t('rfm_settings_manage_roi_tracking'),
      subtitle: t('rfm_and_roi_line_1'),
      component: (
        <RFMcomponent
          orderStatus={
            configurationsData?.roi_calculation_setting.order_status ?? []
          }
        />
      ),
    },
  ];

  const defaultValues = {
    calculateRoi:
      configurationsData?.roi_calculation_setting.calculate_roi ?? 0,
    score5: configurationsData?.rfm_setting.score_5 ?? [],
    score4: configurationsData?.rfm_setting.score_4 ?? { from: [], to: [] },
    score3: configurationsData?.rfm_setting.score_3 ?? { from: [], to: [] },
    score2: configurationsData?.rfm_setting.score_2 ?? { from: [], to: [] },
    score1: configurationsData?.rfm_setting.score_1 ?? [],
  };

  const AddConfigurationsSchema = Yup.object().shape({
    calculateRoi: Yup.number().required(t('calculate_roi_required')),
    score5: Yup.array().of(
      Yup.number()
        .positive(t('value_must_be_positive_integer'))
        .typeError('required')
    ),
    score4: Yup.object().shape({
      from: Yup.array().of(
        Yup.number()
          .positive(t('value_must_be_positive_integer'))
          .typeError('required')
      ),
      to: Yup.array().of(
        Yup.number()
          .positive(t('value_must_be_positive_integer'))
          .typeError('required')
      ),
    }),
    score3: Yup.object().shape({
      from: Yup.array().of(
        Yup.number()
          .positive(t('value_must_be_positive_integer'))
          .typeError('required')
      ),
      to: Yup.array().of(
        Yup.number()
          .positive(t('value_must_be_positive_integer'))
          .typeError('required')
      ),
    }),
    score2: Yup.object().shape({
      from: Yup.array().of(
        Yup.number()
          .positive(t('value_must_be_positive_integer'))
          .typeError('required')
      ),
      to: Yup.array().of(
        Yup.number()
          .positive(t('value_must_be_positive_integer'))
          .typeError('required')
      ),
    }),
    score1: Yup.array().of(
      Yup.number()
        .positive(t('value_must_be_positive_integer'))
        .typeError('required')
    ),
  });

  const methods = useForm<ConfigurationFormValuesProps>({
    mode: 'all',
    resolver: yupResolver(AddConfigurationsSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { errors },
  } = methods;

  const mutation = useMutation(
    (data: ConfigurationFormValuesProps) =>
      updateConfigurations(data, targetShopId, sourceShopId),
    {
      onError: () => {
        enqueueSnackbar(capitalizeFirstLetter(t('something_went_wrong')), {
          variant: 'error',
        });
      },
      onSuccess: data => {
        if (data.data.success) {
          enqueueSnackbar(capitalizeFirstLetter(data.data.message), {
            variant: 'success',
          });
        } else {
          enqueueSnackbar(capitalizeFirstLetter(data.data.message), {
            variant: 'error',
          });
        }
      },
    }
  );

  const onSubmit = (data: ConfigurationFormValuesProps) => {
    mutation.mutate(data);
  };

  return (
    <Accordion sx={{ m: smUp ? 5 : 0, ml: smUp ? 8 : 0, mb: 5 }}>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        {!!errors.afterSubmit && (
          <Alert severity='error' sx={{ alignItems: 'center' }}>
            {errors.afterSubmit.message}
          </Alert>
        )}
        {ACCORDIAN_DATA.map((data, index) => (
          <Accordion
            square={true}
            key={index}
            onChange={handleChange('rfmAndroisettings')}
            disableGutters
          >
            <AccordionSummary
              expandIcon={
                <Button
                  variant='outlined'
                  endIcon={
                    <Iconify
                      icon={
                        expanded.rfmAndroisettings
                          ? 'ep:arrow-up'
                          : 'ep:arrow-down'
                      }
                    />
                  }
                  sx={{ pointerEvents: 'auto' }}
                >
                  {t('manage')}
                </Button>
              }
              aria-controls={`panel${index}-content`}
              id={`panel${index}-header`}
              sx={{
                pointerEvents: 'none',
                px: 2,
                '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
                  transform: 'rotate(0deg)',
                },
              }}
            >
              <Stack flexDirection='column'>
                <CardHeader title={data.title} subheader={data.subtitle} />
              </Stack>
            </AccordionSummary>

            <AccordionDetails>
              <Divider variant='middle' sx={{ mb: 2 }} />
              {data.component}
            </AccordionDetails>
            <Stack
              justifyContent='center'
              sx={{ ml: 'auto', mr: 'auto', width: '10%', mb: 3, mt: 5 }}
            >
              <LoadingButton
                size='medium'
                type='submit'
                variant='contained'
                aria-label={t('save')}
                loading={mutation.isLoading}
              >
                {t('save')}
              </LoadingButton>
            </Stack>
          </Accordion>
        ))}
      </FormProvider>
    </Accordion>
  );
};

export default ConfigurationsForm;
