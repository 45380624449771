import { useQuery, UseQueryResult } from 'react-query';
import {
  GET_PIPELINE_MAPPING_DETAILS,
  GET_GROUPS_AND_PROPERTIES,
  CREATE_GROUPS_AND_PROPERTIES_REQUEST,
  GET_LISTS_API_URL,
  GET_WORKFLOWS_API_URL,
  GET_SETTINGS_API_URL,
  GET_ALL_PRODUCTS_API_URL,
  CUSTOMER_LISTING_API_URL,
  GET_PRODUCTS_COUNT_API_URL,
  DEAL_LISTING_API_URL,
  COMPLETED_ACTIVITIES_API_URL,
  ONGOING_ACTIVITIES_API_URL,
  PRODUCT_FIELD_MAPPING_API_URL,
  DEAL_FIELD_MAPPING_API_URL,
  CONTACT_FIELD_MAPPING_API_URL,
  GET_LOGS_DATA_API_URL,
  DASHBOARD_LINK_UPDATES,
  SAVE_SETTINGS_API_URL,
  GET_ALL_APPS,
  GET_ALL_PLANS,
  GET_ALL_ACTIVE_PLANS,
} from 'api/ShoplineApiConstants';
import {
  PipelineMappingType,
  CreateGroupPropertiesListsWorkflowsType,
  GetGroupsPropertiesType,
  GroupPropertiesRequestPostParamsType,
  ListType,
  WorkFlowType,
  SettingsType,
  CustomerListingType,
  ProductsListType,
  ProductsCountType,
  DealListingType,
  ActivitiesType,
  FieldMappingType,
  DataLogDataType,
  DashboardDataType,
  StageMappingType,
  ConfigurationFormValuesProps,
  PlanListing,
  PlanDetails,
} from '../pages/ShoplineType';
import axiosInstance from 'utils/axios';
import { ConnectedAppsDataType } from 'contexts/Connectors';

export const ACCESS_TOKEN = window.sessionStorage.getItem('accessToken');
export const HEADERS = {
  Authorization: `Bearer ${ACCESS_TOKEN}`,
};

export const requestCreateGroupsPropertiesListsWorkflows = async (
  step: number,
  targetShopId: number,
  sourceShopId: number,
  url: string
): Promise<CreateGroupPropertiesListsWorkflowsType> => {
  const { data } =
    await axiosInstance.post<CreateGroupPropertiesListsWorkflowsType>(
      url,
      {
        step,
        targetShopId,
        sourceShopId,
      },
      {
        headers: {
          'Ced-Source-Id': sourceShopId,
          'Ced-Target-Id': targetShopId,
        },
      }
    );

  return data;
};
export const useCreatePipeLine = (
  sourceShopId: number,
  targetShopId: number
): UseQueryResult<PipelineMappingType> => {
  return useQuery(
    [GET_PIPELINE_MAPPING_DETAILS],
    () => requestCreatePipeline(sourceShopId, targetShopId),
    {
      refetchOnWindowFocus: false,
    }
  );
};

const requestCreatePipeline = async (
  sourceShopId: number,
  targetShopId: number
): Promise<PipelineMappingType> => {
  const { data } = await axiosInstance.get<PipelineMappingType>(
    `${GET_PIPELINE_MAPPING_DETAILS}`,
    {
      headers: {
        'Ced-Source-Id': sourceShopId,
        'Ced-Target-Id': targetShopId,
      },
      params: {
        sourceShopId,
        targetShopId,
      },
    }
  );

  return data;
};

// export const useCreatePipeLine = (): UseQueryResult<PipelineMappingType> => {
//   return useQuery(
//     [GET_PIPELINE_MAPPING_DETAILS],
//     () => requestCreatePipeline(),
//     {
//       refetchOnWindowFocus: false,
//     }
//   );
// };

// const requestCreatePipeline = async (): Promise<PipelineMappingType> => {
//   const { data } = await axiosinstance.get<PipelineMappingType>(
//     `${GET_PIPELINE_MAPPING_DETAILS}`,
//     {
//       params: {
//         sourceShopId,
//         targetShopId,
//       },
//       headers: HEADERS,
//     }
//   );

//   return data;
// };

export const useGetGroupsProperties = (
  targetShopId: number,
  sourceShopId: number
): UseQueryResult<GetGroupsPropertiesType> => {
  return useQuery(
    [GET_GROUPS_AND_PROPERTIES],
    () => requestGetGroupsProperties(targetShopId, sourceShopId),
    {
      refetchOnWindowFocus: false,
    }
  );
};

const requestGetGroupsProperties = async (
  targetShopId: number,
  sourceShopId: number
): Promise<GetGroupsPropertiesType> => {
  const { data } = await axiosInstance.get<GetGroupsPropertiesType>(
    `${GET_GROUPS_AND_PROPERTIES}`,
    {
      headers: {
        'Ced-Source-Id': sourceShopId,
        'Ced-Target-Id': targetShopId,
      },
      params: {
        sourceShopId,
        targetShopId,
      },
    }
  );

  return data;
};

export const groupsPropertiesRequestCreate = (
  data: GroupPropertiesRequestPostParamsType,
  targetShopId: number,
  sourceShopId: number
) => {
  return axiosInstance.post(CREATE_GROUPS_AND_PROPERTIES_REQUEST, data, {
    headers: {
      'Ced-Source-Id': sourceShopId,
      'Ced-Target-Id': targetShopId,
    },
  });
};

export const useLists = (
  sourceShopId: number,
  targetShopId: number
): UseQueryResult<ListType> => {
  return useQuery(
    [GET_LISTS_API_URL],
    () => requestgetLists(sourceShopId, targetShopId),
    {
      refetchOnWindowFocus: false,
    }
  );
};

const requestgetLists = async (
  sourceShopId: number,
  targetShopId: number
): Promise<ListType> => {
  const { data } = await axiosInstance.get<ListType>(`${GET_LISTS_API_URL}`, {
    params: {
      sourceShopId,
      targetShopId,
    },
    headers: {
      'Ced-Source-Id': sourceShopId,
      'Ced-Target-Id': targetShopId,
    },
  });

  return data;
};

const getWorkflows = async (
  sourceShopId: number,
  targetShopId: number
): Promise<WorkFlowType> => {
  const { data } = await axiosInstance.get<WorkFlowType>(
    `${GET_WORKFLOWS_API_URL}`,
    {
      params: {
        sourceShopId,
        targetShopId,
      },
      headers: {
        'Ced-Source-Id': sourceShopId,
        'Ced-Target-Id': targetShopId,
      },
    }
  );

  return data;
};

export const useWorkFlows = (
  sourceShopId: number,
  targetShopId: number
): UseQueryResult<WorkFlowType> => {
  return useQuery(
    [GET_WORKFLOWS_API_URL],
    () => getWorkflows(sourceShopId, targetShopId),
    {
      refetchOnWindowFocus: false,
    }
  );
};

const getSettings = async (
  sourceShopId: number,
  targetShopId: number
): Promise<SettingsType> => {
  const { data } = await axiosInstance.get<SettingsType>(
    `${GET_SETTINGS_API_URL}`,
    {
      params: {
        sourceShopId,
        targetShopId,
      },
      headers: {
        'Ced-Source-Id': sourceShopId,
        'Ced-Target-Id': targetShopId,
      },
    }
  );

  return data;
};

export const useSettings = (
  sourceShopId: number,
  targetShopId: number
): UseQueryResult<SettingsType> => {
  return useQuery(
    [GET_SETTINGS_API_URL],
    () => getSettings(sourceShopId, targetShopId),
    {
      refetchOnWindowFocus: false,
    }
  );
};

export const updateConfigurations = (
  configData: ConfigurationFormValuesProps,
  targetShopId: number,
  sourceShopId: number
) => {
  return axiosInstance.post(SAVE_SETTINGS_API_URL, {
    sourceShopId,
    targetShopId,
    headers: {
      'Ced-Source-Id': sourceShopId,
      'Ced-Target-Id': targetShopId,
    },
    calculate_roi: configData.calculateRoi,
    rfm_setting: {
      score_5: configData.score5,
      score_4: configData.score4,
      score_3: configData.score3,
      score_2: configData.score2,
      score_1: configData.score1,
    },
  });
};

/* Making a GET request to the `GET_ALL_CUSTOMERS_API_URL` endpoint. */
export const requestgetAllCustomers = async (
  count: number,
  activePage: number,
  filterName: string,
  sourceShopId: number,
  targetShopId: number,
  filterId: number | null = null
): Promise<CustomerListingType> => {
  const { data } = await axiosInstance.get<CustomerListingType>(
    `${CUSTOMER_LISTING_API_URL}`,
    {
      params:
        filterName === ''
          ? {
              count,
              activePage,
            }
          : {
              count,
              activePage,
              // 'filter[last_name][1]': filterName,
              // 'filter[first_name][1]': filterName,
              'filter[email][3]': filterName,
              // 'filter[email][1]': filterName,
              // filterType: 'OR',
              ...(filterId && { 'filter[_id][1]': filterId }),
            },
      headers: {
        'Ced-Source-Id': sourceShopId,
        'Ced-Target-Id': targetShopId,
      },
    }
  );

  return data;
};

export const requestgetAllProducts = async (
  sourceShopId: number,
  targetShopId: number,
  count: number,
  activePage: number,
  filterName: string,
  filterId: number | null = null
): Promise<ProductsListType> => {
  const { data } = await axiosInstance.get<ProductsListType>(
    `${GET_ALL_PRODUCTS_API_URL}`,
    {
      params:
        filterName !== ''
          ? {
              count,
              activePage,
              // 'filter[last_name][3]': filterName,
              // 'filter[first_name][3]': filterName,
              // 'filter[source_product_id][1]': filterName,
              'filter[title][3]': filterName,
              // filterType: 'OR',
              // ...(filterId && { 'filter[_id][1]': filterId }),
            }
          : {
              count,
              activePage,
              'filter[type][1]': 'simple',
            },
      headers: {
        'Ced-Source-Id': sourceShopId,
        'Ced-Target-Id': targetShopId,
      },
    }
  );

  return data;
};

export const getProductsCount = async (
  sourceShopId: number,
  targetShopId: number
): Promise<ProductsCountType> => {
  const { data } = await axiosInstance.get<ProductsCountType>(
    `${GET_PRODUCTS_COUNT_API_URL}`,
    {
      headers: {
        'Ced-Source-Id': sourceShopId,
        'Ced-Target-Id': targetShopId,
      },
    }
  );

  return data;
};

export const requestgetAllDeals = async (
  sourceShopId: number,
  targetShopId: number,
  count: number,
  activePage: number,
  filterName: string
): Promise<DealListingType> => {
  const { data } = await axiosInstance.get<DealListingType>(
    `${DEAL_LISTING_API_URL}`,
    {
      params:
        filterName !== ''
          ? {
              count,
              activePage,
              'filter[marketplace_reference_id][3]': filterName,
            }
          : {
              count,
              activePage,
              'filter[object_type][1]': 'source_order',
            },
      headers: {
        'Ced-Source-Id': sourceShopId,
        'Ced-Target-Id': targetShopId,
      },
    }
  );

  return data;
};

export const useOngoingActivities = (
  data: any
): UseQueryResult<ActivitiesType> => {
  return useQuery(
    [ONGOING_ACTIVITIES_API_URL],
    () => requestGetOngoingActivities(data),
    {
      refetchOnWindowFocus: false,
    }
  );
};

const requestGetOngoingActivities = async (
  reqData: any
): Promise<ActivitiesType> => {
  const { data } = await axiosInstance.post<ActivitiesType>(
    ONGOING_ACTIVITIES_API_URL,
    reqData,
    {
      headers: {
        'Ced-Source-Id': reqData?.source?.shopId,
        'Ced-Target-Id': reqData?.target?.shopId,
      },
    }
  );

  return data;
};

export const useDashboardData = (
  sourceShopId: number,
  targetShopId: number
): UseQueryResult<DashboardDataType> => {
  return useQuery(
    [DASHBOARD_LINK_UPDATES],
    () => requestGetDashboardData(sourceShopId, targetShopId),
    {
      refetchOnWindowFocus: false,
    }
  );
};

const requestGetDashboardData = async (
  sourceShopId: number,
  targetShopId: number
): Promise<DashboardDataType> => {
  const { data } = await axiosInstance.post<DashboardDataType>(
    DASHBOARD_LINK_UPDATES,
    { source_shop_id: sourceShopId, target_shop_id: targetShopId },
    {
      headers: {
        'Ced-Source-Id': sourceShopId,
        'Ced-Target-Id': targetShopId,
      },
    }
  );

  return data;
};

export const useCompletedActivities = (
  data: any
): UseQueryResult<ActivitiesType> => {
  return useQuery(
    [COMPLETED_ACTIVITIES_API_URL],
    () => requestGetCompletedActivities(data),
    {
      refetchOnWindowFocus: false,
    }
  );
};

const requestGetCompletedActivities = async (
  reqData: any
): Promise<ActivitiesType> => {
  const { data } = await axiosInstance.post<ActivitiesType>(
    COMPLETED_ACTIVITIES_API_URL,
    reqData,
    {
      headers: {
        'Ced-Source-Id': reqData?.source?.shopId,
        'Ced-Target-Id': reqData?.target?.shopId,
      },
    }
  );

  return data;
};

export const requestGetProductFieldMapping = async (
  sourceShopId: number,
  targetShopId: number,
  page: number,
  limit: number
): Promise<FieldMappingType> => {
  const { data } = await axiosInstance.get<FieldMappingType>(
    PRODUCT_FIELD_MAPPING_API_URL,
    {
      params: {
        page,
        limit,
        sourceShopId,
        targetShopId,
      },
      headers: {
        'Ced-Source-Id': sourceShopId,
        'Ced-Target-Id': targetShopId,
      },
    }
  );

  return data;
};

export const requestGetDealFieldMapping = async (
  sourceShopId: number,
  targetShopId: number,
  page: number,
  limit: number
): Promise<FieldMappingType> => {
  const { data } = await axiosInstance.get<FieldMappingType>(
    DEAL_FIELD_MAPPING_API_URL,
    {
      params: {
        page,
        limit,
        sourceShopId,
        targetShopId,
      },
      headers: {
        'Ced-Source-Id': sourceShopId,
        'Ced-Target-Id': targetShopId,
      },
    }
  );

  return data;
};

export const requestGetContactFieldMapping = async (
  sourceShopId: number,
  targetShopId: number,
  page: number,
  limit: number
): Promise<FieldMappingType> => {
  const { data } = await axiosInstance.get<FieldMappingType>(
    CONTACT_FIELD_MAPPING_API_URL,
    {
      params: {
        page,
        limit,
        sourceShopId,
        targetShopId,
      },
      headers: {
        'Ced-Source-Id': sourceShopId,
        'Ced-Target-Id': targetShopId,
      },
    }
  );

  return data;
};

export const getLogsData = async (
  targetShopId: number,
  sourceShopId: number,
  limit: number,
  skip: number,
  object: string
): Promise<DataLogDataType> => {
  const { data } = await axiosInstance.get<DataLogDataType>(
    `${GET_LOGS_DATA_API_URL}`,
    {
      params: {
        targetShopId,
        limit,
        skip,
        ...(object ? { object } : {}),
      },
      headers: {
        'Ced-Source-Id': sourceShopId,
        'Ced-Target-Id': targetShopId,
      },
    }
  );

  return data;
};

export const useDealStageMapping = (
  sourceShopId: number,
  targetShopId: number
): UseQueryResult<StageMappingType> => {
  return useQuery(
    [GET_SETTINGS_API_URL],
    () => requestDealStageMapping(sourceShopId, targetShopId),
    {
      refetchOnWindowFocus: false,
    }
  );
};

const requestDealStageMapping = async (
  sourceShopId: number,
  targetShopId: number
): Promise<StageMappingType> => {
  const { data } = await axiosInstance.get<StageMappingType>(
    GET_SETTINGS_API_URL,
    {
      params: {
        sourceShopId,
        targetShopId,
      },
      headers: {
        'Ced-Source-Id': sourceShopId,
        'Ced-Target-Id': targetShopId,
      },
    }
  );

  return data;
};

export const getAllConnectedAppsData = async (
  token: string
): Promise<ConnectedAppsDataType> => {
  const { data } = await axiosInstance.get<ConnectedAppsDataType>(
    GET_ALL_APPS,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return data;
};

export const useGetAllPlans = (
  sourceShopId: number,
  targetShopId: number
): UseQueryResult<PlanListing> => {
  return useQuery(
    [GET_ALL_PLANS],
    () => requestPlansdetails(sourceShopId, targetShopId),
    {
      refetchOnWindowFocus: false,
    }
  );
};

const requestPlansdetails = async (
  sourceShopId: number,
  targetShopId: number
): Promise<PlanListing> => {
  const { data } = await axiosInstance.get<PlanListing>(GET_ALL_PLANS, {
    params: {
      'filter[source_marketplace]': 'shopline',
      'filter[target_marketplace]': 'hubspot',
    },

    headers: {
      'Ced-Source-Id': sourceShopId,
      'Ced-Target-Id': targetShopId,
    },
  });

  return data;
};

export const useGetActivePlans = (
  sourceShopId: number,
  targetShopId: number,
  user_details: boolean
): UseQueryResult<PlanDetails> => {
  return useQuery(
    [GET_ALL_ACTIVE_PLANS],
    () => requestActivePlandetails(sourceShopId, targetShopId, user_details),
    {
      refetchOnWindowFocus: false,
    }
  );
};

const requestActivePlandetails = async (
  sourceShopId: number,
  targetShopId: number,
  user_details: boolean
): Promise<PlanDetails> => {
  const { data } = await axiosInstance.get<PlanDetails>(GET_ALL_ACTIVE_PLANS, {
    params: {
      'filter[source_marketplace]': 'shopline',
      'filter[target_marketplace]': 'hubspot',
      user_details: user_details,
    },

    headers: {
      'Ced-Source-Id': sourceShopId,
      'Ced-Target-Id': targetShopId,
    },
  });

  return data;
};
