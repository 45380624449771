import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { defaultLang, STRIPE_PUBLISHER_KEY } from 'config';
import { Payments } from 'pages/Checkout';

export default function PaymentsContainer() {
  const lang = localStorage.getItem('i18nextLng') || defaultLang.value;
  //   const portalId = usePortalId();
  const stripePromise = loadStripe(STRIPE_PUBLISHER_KEY, {
    //@ts-ignore
    locale: lang,
  });

  return (
    <Elements stripe={stripePromise}>
      <Payments />
    </Elements>
  );
}
