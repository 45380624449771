import { Box, Card, Grid } from '@mui/material';
import CreditInformation from 'components/CreditInformation';
import InvoiceHistory from 'components/InvoiceHistory';
import { PlanDescription } from 'components/PlanDescription';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import useConnector from 'hooks/useConnector';
import { useGetActivePlans } from 'api/ShoplineApiHooks';
import PartialPageSpinner from 'components/PartialPageSpinner';
import Scrollbar from 'components/Scrollbar';
import Page from 'components/Page';
import { SHOPLINE_NAVIGATION } from 'routes/paths';
import usePortalId from 'hooks/usePortalId';
import { useNavigate } from 'react-router';

function PlanDetail() {
  const { t } = useTranslation();
  const { sourceShopId, targetShopId } = useConnector();
  const portalId = usePortalId();
  const navigate = useNavigate();
  const { data } = useGetActivePlans(sourceShopId, targetShopId, false);

  if (!data?.success && data?.data === undefined) {
    navigate(`${SHOPLINE_NAVIGATION.general.plan(portalId)}/plan-listing`);
  }
  if (data?.data === undefined) {
    return <PartialPageSpinner />;
  }
  const rows = data?.data;
  _.reverse(rows?.payment_info);

  return (
    <Page title={t('Plan')}>
      <div className='mx-[40px] ml-[64px]'>
        <Grid container spacing={4}>
          <Grid item xs={12} md={8}>
            <PlanDescription />
            <CreditInformation
              total={rows.user_service.prepaid.service_credits}
              available={rows.user_service.prepaid.available_credits}
              done={rows.user_service.prepaid.total_used_credits}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Card sx={{ p: '24px' }}>
              <h3>{t('invoice_history')}</h3>
              {!rows.payment_info.length ? (
                <h4 className='mt-[5px] text-center'>{t('no_record')}</h4>
              ) : (
                <Box className='max-h-[400px] px-[2px] py-[5px]'>
                  <Scrollbar>
                    <Box className='max-h-[390px]'>
                      {_.map(rows?.payment_info, history => (
                        <InvoiceHistory
                          date={history.updated_at}
                          price={history.amount}
                          filetype={history.invoice_pdf}
                        />
                      ))}
                    </Box>
                  </Scrollbar>
                </Box>
              )}
            </Card>
          </Grid>
        </Grid>
      </div>
    </Page>
  );
}

export default PlanDetail;
