import {
  Checkbox,
  IconButton,
  Link,
  Stack,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  Box,
  CircularProgress,
} from '@mui/material';
import IosShareIcon from '@mui/icons-material/IosShare';
import { ProductsList, ProductsListType } from 'pages/ShoplineType';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { useTranslation } from 'react-i18next';
import Image from 'components/Image';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import usePortalId from 'hooks/usePortalId';
import useConnector from 'hooks/useConnector';
import { capitalizeFirstLetter } from 'utils/letterChange';
import Iconify from 'components/Iconify';
import {
  GET_ALL_PRODUCTS_API_URL,
  ROWS_PER_PAGE,
  UPLOAD_SINGLE_PRODUCT_API_URL,
} from 'api/ShoplineApiConstants';
import { useState } from 'react';
import axiosInstance from 'utils/axios';
import useAuth from 'hooks/useAuth';
import { useSnackbar } from 'notistack';
import { requestgetAllProducts } from 'api/ShoplineApiHooks';
import { useQuery, useQueryClient } from 'react-query';
import _ from 'lodash';

type Props = {
  row: ProductsList;
  selected: boolean;
  onSelectRow: VoidFunction;
  activePage: number;
  filterName: string;
  skip: number;
};

export default function ProductsTableRow({
  row,
  selected,
  onSelectRow,
  activePage,
  filterName,
  skip,
}: Props) {
  const { t } = useTranslation();
  const { user } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  let toolmsg = null;
  let hsProId = null;
  const { sourceShopId, targetShopId } = useConnector();
  const queryClient = useQueryClient();
  const [loader, setLoader] = useState<boolean>(false);

  const portalId = usePortalId();

  const { data } = useQuery<ProductsListType>(
    [GET_ALL_PRODUCTS_API_URL, skip, filterName],
    () =>
      requestgetAllProducts(
        sourceShopId,
        targetShopId,
        ROWS_PER_PAGE,
        activePage,
        filterName
      )
  );

  let rowIndex = -1;

  if (row?.marketplace) {
    rowIndex = row?.marketplace.findIndex(
      x => x.target_marketplace === 'hubspot'
    );
  }

  let rowStatus = null;
  if (rowIndex !== -1) {
    rowStatus = row.marketplace[rowIndex].status === 'active' ? true : false;
  }
  if (row.marketplace.length > 0) {
    const hsOid = row.marketplace.filter(r => r.shop_id === targetShopId);
    if (hsOid.length) {
      if (hsOid[0].status === 'active') {
        hsProId = hsOid[0].target_product_id;
      } else if (hsOid[0].status === 'error') {
        toolmsg = hsOid[0]?.errors[0];
      }
    }
  }

  const exportSingle = async (id: string, dbId: string) => {
    setLoader(true);
    try {
      const response = await axiosInstance.post(
        UPLOAD_SINGLE_PRODUCT_API_URL,
        {
          source: {
            marketplace: 'shopline',
            shopId: sourceShopId,
          },
          target: {
            marketplace: 'hubspot',
            shopId: targetShopId,
          },
          source_product_id: id,
          user_id: (user?.user_id || '') as string,
        },
        {
          headers: {
            'Ced-Source-Id': sourceShopId,
            'Ced-Target-Id': targetShopId,
          },
        }
      );
      if (response.data.success) {
        enqueueSnackbar(capitalizeFirstLetter(response.data.message), {
          variant: 'success',
        });
        setLoader(false);
        const cusData = await requestgetAllProducts(
          sourceShopId,
          targetShopId,
          ROWS_PER_PAGE,
          activePage,
          filterName,
          dbId as unknown as number
        );

        const products = data?.data.rows;

        if (products) {
          const productIndex = _.findIndex(products, {
            _id: dbId,
          });
          products[productIndex] = cusData.data.rows[productIndex];
          queryClient.setQueryData(
            [GET_ALL_PRODUCTS_API_URL, skip, filterName],
            { ...data, data: { ...data?.data, rows: products } }
          );
        }
      } else {
        enqueueSnackbar(capitalizeFirstLetter(response.data.message), {
          variant: 'error',
        });
        setLoader(false);
      }
    } catch (error) {
      enqueueSnackbar(capitalizeFirstLetter(`${error}`), {
        variant: 'error',
      });
      setLoader(false);
    }
  };

  return (
    <TableRow>
      <TableCell>
        <Checkbox checked={selected} onClick={onSelectRow} />
      </TableCell>
      <TableCell align='center' width='25%'>
        <Stack direction='row' ml='1'>
          <Image
            src={
              row?.image?.src ||
              'https://www.freeiconspng.com/thumbs/no-image-icon/no-image-icon-6.png'
            }
            sx={{
              objectFit: 'fill',
              width: '25px',
              height: '25px',
            }}
            alt='success'
          />
          <Typography variant='body2' sx={{ ml: 2 }}>
            {`${capitalizeFirstLetter(row.title)}`}
          </Typography>
        </Stack>
      </TableCell>
      <TableCell align='center'>
        <Typography variant='body2'>{row.source_product_id}</Typography>
      </TableCell>
      <TableCell align='center'>
        <Typography variant='body2'>{`${row.currency_code} ${
          row?.price ?? 0
        }`}</Typography>
      </TableCell>
      <TableCell align='center'>
        <Typography
          variant='body2'
          sx={
            hsProId
              ? {
                  borderRadius: '10px',
                }
              : {
                  borderRadius: '10px',
                  color: 'rgba(99, 115, 129, 1)',
                }
          }
        >
          <Box className='flex items-center justify-center gap-[2px]'>
            {!hsProId ? (
              <svg
                width='10'
                height='10'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <circle
                  cx='4'
                  cy='4'
                  r='4'
                  fill='rgba(99, 115, 129, 1)'
                ></circle>
              </svg>
            ) : (
              <Iconify
                icon={'eva:checkmark-circle-fill'}
                color='rgba(34, 154, 22, 1)'
                sx={{ w: '10px', h: '10px' }}
              />
            )}
            <Typography>{hsProId ? t('synced') : t('unsynced')}</Typography>
          </Box>
        </Typography>
      </TableCell>
      <TableCell align='right'>
        {hsProId ? (
          <>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Typography>{hsProId}</Typography>
              <Tooltip title={t('view_in_hubspot')}>
                <Link
                  href={`https://app.hubspot.com/settings/${portalId}/sales/products`}
                  target='_blank'
                  sx={{ textDecoration: 'none' }}
                  rel='noreferrer'
                >
                  <OpenInNewIcon sx={{ height: '15px', mt: '5px' }} />
                </Link>
              </Tooltip>
            </Box>
          </>
        ) : toolmsg !== null ? (
          <Tooltip title={toolmsg}>
            <RemoveRedEyeIcon />
          </Tooltip>
        ) : (
          '-'
        )}
      </TableCell>
      <TableCell align='right'>
        {loader ? (
          <CircularProgress size={20} />
        ) : (
          <Tooltip title='export' arrow>
            <IconButton
              onClick={() => {
                exportSingle(row.source_product_id, row._id);
              }}
            >
              <IosShareIcon sx={{ width: '15px', height: '15px' }} />
            </IconButton>
          </Tooltip>
        )}
      </TableCell>
    </TableRow>
  );
}
