import { LoadingButton } from '@mui/lab';
import {
  Accordion,
  AccordionSummary,
  Button,
  Stack,
  Typography,
  AccordionDetails,
  CardHeader,
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Divider,
  TableHead,
} from '@mui/material';
import {
  groupsPropertiesRequestCreate,
  useGetGroupsProperties,
} from 'api/ShoplineApiHooks';
import Iconify from 'components/Iconify';
import { index } from 'd3-array';
import useResponsive from 'hooks/useResponsive';
import { useSnackbar } from 'notistack';
import {
  GroupPropertiesRequestType,
  GroupPropertiesRequestPostParamsType,
} from 'pages/ShoplineType';
import React, { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import PartialPageSpinner from 'components/PartialPageSpinner';
import useConnector from 'hooks/useConnector';
import { capitalizeFirstLetter } from 'utils/letterChange';

type Props = {
  row: GroupPropertiesRequestType;
};

const Row: FunctionComponent<Props> = ({ row }) => {
  const { targetShopId, sourceShopId } = useConnector();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const { label, created, properties, name } = row;
  const [createGroup, setCreateGroup] = useState(created);
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    if (properties.length) {
      properties.map(property => {
        if (!property.created) {
          setCreateGroup(property.created);
        }
      });
    }
  }, []);

  const mutation = useMutation(
    (data: GroupPropertiesRequestPostParamsType) =>
      groupsPropertiesRequestCreate(data, targetShopId, sourceShopId),
    {
      onError: () => {
        enqueueSnackbar(capitalizeFirstLetter(t('something_went_wrong')), {
          variant: 'error',
        });
      },
      onSuccess: data => {
        setCreateGroup(true);
        enqueueSnackbar(capitalizeFirstLetter(data.data.message), {
          variant: 'success',
        });
      },
    }
  );

  return (
    <Fragment>
      <TableRow>
        <TableCell align='left' width='90%'>
          <IconButton
            aria-label='expand row'
            size='small'
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
          {label}
        </TableCell>
        <TableCell align='center' width='10%'>
          {createGroup ? (
            <Button disabled variant='outlined'>
              {t('created')}
            </Button>
          ) : (
            <LoadingButton
              variant='outlined'
              loading={mutation.isLoading}
              onClick={() => {
                mutation.mutate({
                  targetShopId: targetShopId,
                  sourceShopId: sourceShopId,
                  group: name,
                });
              }}
            >
              {t('create')}
            </LoadingButton>
          )}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout='auto' unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size='small' aria-label='purchases'>
                <TableBody>
                  {properties.map((property, key) => (
                    <TableRow key={key}>
                      <TableCell>
                        <Stack direction='row' alignItems='center'>
                          {property?.created && (
                            <Iconify
                              icon={'charm:tick'}
                              sx={{ color: '#54D62C' }}
                            />
                          )}
                          <Typography variant='body2' sx={{ ml: 2 }}>
                            {property.label}
                          </Typography>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  );
};

export default function GroupsAndProperties() {
  const smUp = useResponsive('up', 'sm');

  const [expanded, setExpanded] = useState({
    groupsAndProperties: false,
  });

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded({ ...expanded, [panel]: isExpanded });
    };
  const { sourceShopId, targetShopId } = useConnector();
  const { t } = useTranslation();
  const { data } = useGetGroupsProperties(targetShopId, sourceShopId);
  if (data === undefined) {
    return <PartialPageSpinner />;
  }

  const groupsData = data.data;

  return (
    <Accordion
      expanded={expanded.groupsAndProperties}
      sx={{ m: smUp ? 5 : 0, ml: smUp ? 8 : 0 }}
      onChange={handleChange('groupsAndProperties')}
      square={true}
      key={'index'}
      disableGutters
    >
      <AccordionSummary
        expandIcon={
          <Button
            variant='outlined'
            endIcon={
              <Iconify
                icon={
                  expanded.groupsAndProperties ? 'ep:arrow-up' : 'ep:arrow-down'
                }
              />
            }
            sx={{ pointerEvents: 'auto' }}
          >
            {t('manage')}
          </Button>
        }
        aria-controls={`panel${'index'}-content`}
        id={`panel${index}-header`}
        sx={{
          pointerEvents: 'none',
          px: 2,
          '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
            transform: 'rotate(0deg)',
          },
        }}
      >
        <Stack flexDirection='column'>
          <CardHeader
            title={t('groups_properties')}
            subheader={t('gropus_and_properties_line1')}
          />
        </Stack>
      </AccordionSummary>
      <Divider variant='middle' />
      <AccordionDetails sx={{ m: 1 }}>
        <Table sx={{ minWidth: 800 }} aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell align='left'>{t('groups')}</TableCell>
              <TableCell align='center'>{t('status')}</TableCell>
            </TableRow>
          </TableHead>
          {groupsData.map(group => (
            <Row key={group.name} row={group} />
          ))}
        </Table>
      </AccordionDetails>
    </Accordion>
  );
}
