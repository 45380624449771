import {
  Accordion,
  AccordionSummary,
  Button,
  Stack,
  CardHeader,
  AccordionDetails,
  Paper,
  TableContainer,
  Divider,
} from '@mui/material';
import { useLists } from 'api/ShoplineApiHooks';
import Iconify from 'components/Iconify';
import PartialPageSpinner from 'components/PartialPageSpinner';
import { index } from 'd3-array';
import useConnector from 'hooks/useConnector';
import useResponsive from 'hooks/useResponsive';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ListAndWorkflowsTable from './ListAndWorkflowsTable';

export default function Lists() {
  const smUp = useResponsive('up', 'sm');

  const [expanded, setExpanded] = useState({
    list: false,
  });

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded({ ...expanded, [panel]: isExpanded });
    };

  const { t } = useTranslation();
  const { sourceShopId, targetShopId } = useConnector();
  const { data, refetch } = useLists(sourceShopId, targetShopId);

  if (data === undefined) {
    return <PartialPageSpinner />;
  }

  const dataMap = data.data;

  return (
    <Accordion
      expanded={expanded.list}
      sx={{ m: smUp ? 5 : 0, ml: smUp ? 8 : 0, mb: 5 }}
      onChange={handleChange('list')}
      square={true}
      key={'index'}
      disableGutters
    >
      <AccordionSummary
        expandIcon={
          <Button
            variant='outlined'
            endIcon={
              <Iconify icon={expanded.list ? 'ep:arrow-up' : 'ep:arrow-down'} />
            }
            sx={{ pointerEvents: 'auto' }}
          >
            {t('manage')}
          </Button>
        }
        aria-controls={`panel${'index'}-content`}
        id={`panel${index}-header`}
        sx={{
          pointerEvents: 'none',
          px: 2,
          '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
            transform: 'rotate(0deg)',
          },
        }}
      >
        <Stack flexDirection='column'>
          <CardHeader title={t('list')} subheader={t('list_line_1')} />
        </Stack>
      </AccordionSummary>
      <AccordionDetails sx={{ m: 1 }}>
        <Divider variant='middle' />

        <TableContainer
          component={Paper}
          sx={{ mt: 2, backgroundColor: 'white' }}
        >
          <ListAndWorkflowsTable
            dataMap={dataMap}
            type='list'
            sourceShopId={sourceShopId}
            targetShopId={targetShopId}
            refetch={() => refetch()}
          />
        </TableContainer>
      </AccordionDetails>
    </Accordion>
  );
}
