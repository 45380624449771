import { m } from 'framer-motion';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Button, Typography, Container } from '@mui/material';
// components
import Page from '../components/Page';
import { MotionContainer, varBounce } from '../components/animate';
// assets
import { useTranslation } from 'react-i18next';
import { SeverErrorIllustration } from 'assets';

// ----------------------------------------------------------------------

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function InvalidToken() {
  const { t } = useTranslation();

  return (
    <Page title={t('invalid_token')}>
      <Container component={MotionContainer}>
        <ContentStyle sx={{ textAlign: 'center', alignItems: 'center' }}>
          <m.div variants={varBounce().in}>
            <SeverErrorIllustration
              sx={{ height: 260, my: { xs: 5, sm: 10 } }}
            />
          </m.div>
          <m.div variants={varBounce().in}>
            <Typography variant='h3' paragraph>
              {t('token_got_expired_please_refresh')}
            </Typography>
          </m.div>
        </ContentStyle>
      </Container>
    </Page>
  );
}
