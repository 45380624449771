import { Typography, Card, Grid, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useResponsive from 'hooks/useResponsive';
import {
  useCompletedActivities,
  useDashboardData,
  useOngoingActivities,
} from 'api/ShoplineApiHooks';
import LoadingScreen from 'components/LoadingScreen';
import useConnector from 'hooks/useConnector';
import { SHOPLINE_NAVIGATION } from 'routes/paths';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import useAuth from 'hooks/useAuth';
import CircularProgressWithLabel from 'components/progress/CircularProgressWithLabel';
import axiosInstance from 'utils/axios';
import { INITIAL_SYNC } from 'api/ShoplineApiConstants';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import { useEffect, useState } from 'react';
import { fDateTime } from 'utils/formatTime';
import Page from 'components/Page';
import { capitalizeFirstLetter } from 'utils/letterChange';
import { Link } from 'react-router-dom';
import PartialPageSpinner from 'components/PartialPageSpinner';

export default function HistoryDataSync() {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { user, loginAdmin } = useAuth();
  const { targetShopId, sourceShopId, portalId } = useConnector();
  const smUp = useResponsive('up', 'sm');
  const dashboardData = useDashboardData(sourceShopId, targetShopId);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [showSyncButton, setShowSyncButton] = useState(
    ((user?.shopline_onboarding_sync || '') as string) === 'inProgress'
      ? false
      : true
  );

  const onGoingActivities = useOngoingActivities({
    target: {
      marketplace: 'hubspot',
      shopId: targetShopId,
    },
    source: {
      marketplace: 'shopline',
      shopId: sourceShopId,
    },
    activePage: '1',
    count: '10',
  });

  const activities = useCompletedActivities({
    target: {
      marketplace: 'hubspot',
      shopId: targetShopId,
    },
    source: {
      marketplace: 'shopline',
      shopId: sourceShopId,
    },
    activePage: '1',
    count: '10',
  });

  useEffect(() => {
    const interval = setInterval(() => {
      dashboardData.refetch();
      onGoingActivities.refetch();
      activities.refetch();
      loginAdmin(window.sessionStorage.getItem('accessToken') as string);
    }, 10000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  if (
    dashboardData.data === undefined ||
    activities.data === undefined ||
    onGoingActivities.data === undefined
  ) {
    return <PartialPageSpinner />;
  }

  const customerData = dashboardData.data?.customers;
  const productData = dashboardData.data?.products;
  const orderData = dashboardData.data?.orders;
  const completedActivities = activities.data.data.rows.slice(0, 3);
  const pendingActivities = onGoingActivities.data.data;

  const handleSync = async () => {
    setButtonLoading(true);
    try {
      const response = await axiosInstance.post(INITIAL_SYNC, {
        target: {
          marketplace: 'hubspot',
          shopId: targetShopId,
        },
        source: {
          marketplace: 'shopline',
          shopId: sourceShopId,
        },
        status: 'initiaited',
      });
      if (response.data.success) {
        if (response.data.code === 'already_in_progress') {
          enqueueSnackbar(capitalizeFirstLetter(response.data.message), {
            variant: 'success',
          });
          setButtonLoading(false);
        } else {
          setShowSyncButton(!showSyncButton);
          enqueueSnackbar(
            capitalizeFirstLetter(response.data.rows[0].message),
            {
              variant: 'success',
            }
          );
          setButtonLoading(false);
          onGoingActivities.refetch();
        }
      } else {
        setButtonLoading(false);
        enqueueSnackbar(capitalizeFirstLetter(response.data.message), {
          variant: 'error',
        });
      }
    } catch (error) {}
  };

  return (
    <Page title={t('home')}>
      <Card sx={{ mx: '40px', ml: '64px' }}>
        <Card sx={{ m: 2, p: 2 }}>
          <Typography variant='h3' sx={{ ml: 2 }}>
            {t('import_from_shopline')}
          </Typography>
          <Typography variant='body1' sx={{ ml: 2, mb: 2 }}>
            {pendingActivities?.rows.length > 0
              ? pendingActivities?.rows[0].message
              : t('import_all_the_existing_data_from_your_store')}
          </Typography>
          {pendingActivities?.rows.length ? (
            <>
              <div
                style={{
                  position: 'absolute',
                  right: 90,
                  top: '45%',
                  transform: 'translateY(-50%)',
                }}
              >
                {pendingActivities?.rows[0]?.process_code === 'order_import' ? (
                  <span>{t('importing')}</span>
                ) : (
                  <CircularProgressWithLabel
                    size={75}
                    value={pendingActivities?.rows[0]?.progress}
                  />
                )}
              </div>
            </>
          ) : (
            <LoadingButton
              sx={
                smUp
                  ? {
                      position: 'absolute',
                      right: 32,
                      top: '50%',
                      transform: 'translateY(-50%)',
                    }
                  : {
                      position: 'unset',
                      display: 'flex',
                      flexDirection: 'column',
                      marginTop: '40px',
                      right: 32,
                      ml: 5,
                      top: '50%',
                      transform: 'translateY(-50%)',
                    }
              }
              variant='contained'
              loading={buttonLoading}
              onClick={() => {
                handleSync();
              }}
            >
              {t('import_now')}
            </LoadingButton>
          )}
        </Card>
        <Grid container>
          <Grid item xs={4}>
            <Card
              sx={{ m: 2, p: 2, textAlign: 'center' }}
              className='flex flex-col gap-1'
            >
              <Typography variant='h2' color='rgba(0, 97, 255, 1)'>
                {customerData?.synced}
              </Typography>
              <Typography variant='h6'>{t('contact_synced')}</Typography>
              <Typography variant='body1'>
                {t('waiting_to_sync', {
                  value: customerData?.total - customerData?.synced,
                })}
              </Typography>
              <Typography
                variant='caption'
                color='rgba(0, 97, 255, 1)'
                sx={{
                  background: 'rgba(0, 97, 255, 0.08)',
                  p: 1,
                  borderRadius: '8px',
                  mt: 1,
                }}
              >
                {t('last_sync', {
                  value:
                    customerData?.last_synced !== '' &&
                    customerData?.last_synced !== undefined
                      ? fDateTime(customerData?.last_synced)
                      : t('not_synced'),
                })}
              </Typography>
            </Card>
          </Grid>
          <Grid item xs={4}>
            <Card
              sx={{ m: 2, p: 2, textAlign: 'center' }}
              className='flex flex-col gap-1'
            >
              <Typography variant='h2' color='rgba(0, 97, 255, 1)'>
                {orderData?.synced}
              </Typography>
              <Typography variant='h6'>{t('deals_synced')}</Typography>
              <Typography variant='body1'>
                {t('waiting_to_sync', {
                  value: orderData?.total - orderData?.synced,
                })}
              </Typography>
              <Typography
                variant='caption'
                color='rgba(0, 97, 255, 1)'
                sx={{
                  background: 'rgba(0, 97, 255, 0.08)',
                  p: 1,
                  borderRadius: '8px',
                  mt: 1,
                }}
              >
                {t('last_sync', {
                  value:
                    orderData?.last_synced !== '' &&
                    orderData?.last_synced !== undefined
                      ? fDateTime(orderData?.last_synced)
                      : t('not_synced'),
                })}
              </Typography>
            </Card>
          </Grid>
          <Grid item xs={4}>
            <Card
              sx={{ m: 2, p: 2, textAlign: 'center' }}
              className='flex flex-col gap-1'
            >
              <Typography variant='h2' color='rgba(0, 97, 255, 1)'>
                {productData?.synced}
              </Typography>
              <Typography variant='h6'>{t('products_synced')}</Typography>
              <Typography variant='body1'>
                {t('waiting_to_sync', {
                  value: productData?.total - productData?.synced,
                })}
              </Typography>
              <Typography
                variant='caption'
                color='rgba(0, 97, 255, 1)'
                sx={{
                  background: 'rgba(0, 97, 255, 0.08)',
                  p: 1,
                  borderRadius: '8px',
                  mt: 1,
                }}
              >
                {t('last_sync', {
                  value:
                    productData?.last_synced !== '' &&
                    productData?.last_synced !== undefined
                      ? fDateTime(productData?.last_synced)
                      : t('not_synced'),
                })}
              </Typography>
            </Card>
          </Grid>
        </Grid>
        <Card sx={{ m: 2, p: 5, mb: 5, mt: 2 }}>
          <Typography variant='h4'>{t('sync_updates')}</Typography>
          <Typography
            sx={{
              mt: 1,
              mb: 2,
            }}
          >
            {t('recent_notifications_to_stay_up_to_date')}
            <Link
              className='rounded-[5px] border border-solid border-[#0061FF] bg-[#0061ff1a] p-[5px] text-[#0061FF] no-underline'
              to={SHOPLINE_NAVIGATION.general.activity(
                Number(portalId),
                'completed'
              )}
            >
              {t('manage_notifications')}
            </Link>
          </Typography>
          {completedActivities.length
            ? completedActivities.map((activity, index) => {
                return (
                  <Card
                    sx={{ background: 'rgba(208, 242, 255, 1)', p: 2, mb: 3 }}
                  >
                    <Typography
                      variant='subtitle2'
                      sx={{
                        pr: 1,
                        display: 'flex',
                        direction: 'row',
                        alignItems: 'center',
                      }}
                    >
                      {activity.severity === 'success' ? (
                        <CheckCircleRoundedIcon sx={{ color: 'blue' }} />
                      ) : null}
                      {activity.severity === 'error' ? (
                        <CancelRoundedIcon sx={{ color: 'red' }} />
                      ) : null}
                      {activity.severity === 'critical' ? (
                        <ErrorRoundedIcon sx={{ color: 'orange' }} />
                      ) : null}
                      <span style={{ paddingLeft: '10px' }}>
                        {activity.message}
                      </span>
                    </Typography>
                  </Card>
                );
              })
            : null}
        </Card>
      </Card>
    </Page>
  );
}
