import { Card, Box, Tab, Tabs } from '@mui/material';
import { capitalCase } from 'change-case';
import { useTranslation } from 'react-i18next';
import useResponsive from '../../hooks/useResponsive';
import { useNavigate, useParams } from 'react-router';
import Page from '../../components/Page';
import DealStageMapping from './Tabs/DealStageMapping';
import DealFieldMapping from './Tabs/DealFieldMapping';
import DealSync from './Tabs/DealSync';
import { SHOPLINE_NAVIGATION } from 'routes/paths';
import usePortalId from 'hooks/usePortalId';
import React from 'react';
import DealListing from './Tabs/DealList';

export const DEALS_TAB_NAMES = {
  DEAL_STAGE_MAPPING: 'deal-stage-mapping',
  DEAL_FIELD_MAPPING: 'deal-field-mapping',
  DEAL_SYNC: 'deal-sync',
  DEAL_LIST: 'deal-list',
};

export default function Deals() {
  const { t } = useTranslation();
  const portalId = usePortalId();
  const smUp = useResponsive('up', 'sm');
  const { tab } = useParams<{ tab: string }>();
  const navigate = useNavigate();
  const DEALS_TABS = React.useMemo(
    () => [
      {
        value: DEALS_TAB_NAMES.DEAL_LIST,
        icon: '',
        component: <DealListing />,
      },
      {
        value: DEALS_TAB_NAMES.DEAL_SYNC,
        icon: '',
        component: <DealSync />,
      },
      {
        value: DEALS_TAB_NAMES.DEAL_STAGE_MAPPING,
        icon: '',
        component: <DealStageMapping />,
      },
      {
        value: DEALS_TAB_NAMES.DEAL_FIELD_MAPPING,
        icon: '',
        component: <DealFieldMapping />,
      },
    ],
    []
  );

  return (
    <Page title={t('deals')}>
      <Box>
        <Card sx={{ m: smUp ? 5 : 0, ml: smUp ? 8 : 0, p: 2 }}>
          <Tabs
            allowScrollButtonsMobile
            variant='scrollable'
            scrollButtons='auto'
            sx={{ ml: '30px' }}
            value={tab}
            onChange={(e, tabIndex) =>
              navigate(SHOPLINE_NAVIGATION.general.deals(portalId, tabIndex))
            }
          >
            {DEALS_TABS.map(tab => (
              <Tab
                disableRipple
                key={t(tab.value)}
                label={capitalCase(t(tab.value))}
                icon={tab.icon}
                value={tab.value}
              />
            ))}
          </Tabs>

          <Box sx={{ mb: 5 }} />

          {DEALS_TABS.map(tabVal => {
            const isMatched = tabVal.value === tab;

            return (
              isMatched && <Box key={tabVal.value}>{tabVal.component}</Box>
            );
          })}
        </Card>
      </Box>
    </Page>
  );
}
