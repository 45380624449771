import { HOST_API } from 'config';
export const ROWS_PER_PAGE = 10;
export const ONBOARD_USER_LOGIN = HOST_API + '/shoplinehome/onboard/userLogin';
export const CONNECT_TO_HUBSPOT_API_URL =
  HOST_API + '/connector/get/installationForm';
export const CREATE_FREE_ACCOUNT = 'https://app.hubspot.com/signup-hubspot';
export const SAVE_MAPPING_DATA =
  HOST_API + '/hubspothome/onboard/saveMappingData';
export const CREATE_GROUPS_AND_PROPERTIES =
  HOST_API + '/hubspothome/onboard/createGroupsAndProperties';
export const CREATE_LISTS = HOST_API + '/hubspothome/onboard/createSmartLists';
export const LOGIN_API = HOST_API + '/user/login';
export const REGISTER_API = HOST_API + '/user/create';
export const VERIFY_EMAIL_TOKEN = HOST_API + '/user/verifyuser/';
export const USER_DETAILS_API_URL =
  HOST_API + '/shoplinehome/request/getUserDetails';
export const FORGOT_PASSWORD_API_URL = HOST_API + '/user/forgot';
export const FORGOT_RESET_PASSWORD_API_URL = HOST_API + '/user/forgotreset';
export const UPDATE_PROFILE_API_URL = HOST_API + '/user/updateProfile';
export const RESET_PASSWORD_API_URL = HOST_API + '/user/resetpassword';
export const GET_ALL_APPS = HOST_API + '/connector/get/all';
export const RESEND_VERIFICATION_MAIL_URL =
  HOST_API + '/user/resendConfirmation';
export const CREATE_PIPELINES =
  HOST_API + '/hubspothome/onboard/createPipelines';
export const GET_PIPELINE_MAPPING_DETAILS =
  HOST_API + '/hubspothome/onboard/getPipelineMapping';
export const INITIAL_SYNC =
  HOST_API + '/hubspothome/onboard/triggerInitialSync';
export const GET_GROUPS_AND_PROPERTIES =
  HOST_API + '/hubspothome/request/getGroupsAndProperties';
export const CREATE_GROUPS_AND_PROPERTIES_REQUEST =
  HOST_API + '/hubspothome/request/createGroupsAndProperties';
export const IMPORT_CUSTOMERS_API_URL = HOST_API + '/connector/customer/import';
export const CUSTOMER_LISTING_API_URL =
  HOST_API + '/shoplinehome/request/customerListing';
export const GET_ALL_PRODUCTS_API_URL =
  HOST_API + '/shoplinehome/request/productListing';
export const GET_LISTS_API_URL = HOST_API + '/hubspothome/request/getLists';
export const GET_WORKFLOWS_API_URL =
  HOST_API + '/hubspothome/request/getWorkflows';
export const CREATE_LIST_API_URL = HOST_API + '/hubspothome/request/createList';
export const CREATE_WORKFLOW_API_URL =
  HOST_API + '/hubspothome/request/createWorkflow';
export const GET_SETTINGS_API_URL =
  HOST_API + '/hubspothome/setting/getConfigurations';
export const SAVE_SETTINGS_API_URL =
  HOST_API + '/hubspothome/setting/saveConfigurations';
export const UPLOAD_BULK_CUSTOMER_API_URL =
  HOST_API + '/hubspothome/upload/uploadCustomer';
export const UPLOAD_SINGLE_CUSTOMER_API_URL =
  HOST_API + '/hubspothome/upload/uploadSingleCustomer';
export const DEAL_LISTING_API_URL =
  HOST_API + '/shoplinehome/request/orderListing';
export const GET_PRODUCTS_COUNT_API_URL =
  HOST_API + '/connector/product/getProductsCount';
export const UPLOAD_SINGLE_PRODUCT_API_URL =
  HOST_API + '/hubspothome/upload/uploadSingleProduct';
export const UPLOAD_MANY_PRODUCT_API_URL =
  HOST_API + '/hubspothome/upload/uploadProduct';
export const UPLOAD_BULK_DEAL_API_URL =
  HOST_API + '/hubspothome/upload/uploadOrder';
export const UPLOAD_SINGLE_DEAL_API_URL =
  HOST_API + '/hubspothome/upload/uploadSingleOrder';
export const ONGOING_ACTIVITIES_API_URL =
  HOST_API + '/connector/get/allQueuedTasks';
export const COMPLETED_ACTIVITIES_API_URL =
  HOST_API + '/connector/get/allNotifications';
export const PRODUCT_FIELD_MAPPING_API_URL =
  HOST_API + '/hubspothome/mapping/getProductFieldMapping';
export const DEAL_FIELD_MAPPING_API_URL =
  HOST_API + '/hubspothome/mapping/getOrderFieldMapping';
export const CONTACT_FIELD_MAPPING_API_URL =
  HOST_API + '/hubspothome/mapping/getCustomerFieldMapping';
export const GET_LOGS_DATA_API_URL = HOST_API + '/hubspothome/request/getLogs';
export const CLEAR_ALL_NOTIFICATIONS_API_URL =
  HOST_API + '/connector/get/clearNotifications';
export const DASHBOARD_LINK_UPDATES =
  HOST_API + '/shoplinehome/dashboard/syncDataSummary';
export const COMPLETE_ONBOARDING =
  HOST_API + '/hubspothome/onboard/CompleteOnboarding';
export const GET_ALL_PLANS = HOST_API + '/plan/plan/get';
export const GET_ALL_ACTIVE_PLANS = HOST_API + '/plan/plan/getActive';
export const GET_CANCEL_CURRENT_SUBSCRIPTION =
  HOST_API + '/plan/plan/cancelCurrentSubscription';
export const GET_PAYMENT_METHOD = 'https://api.stripe.com/v1/payment_methods';
export const GET_START_SUBSCRIPTION = HOST_API + '/plan/plan/startSubscription';
export const UPGRADE_SUBSCRIPTION = HOST_API + '/plan/plan/updateSubscription';
export const SWITCH_USER = HOST_API + '/hubspothome/request/switchConnection';
export const CONFIRM_PAYMENT = HOST_API + '/plan/plan/confirmCardPayment';
