import { TableCell, TableRow, Typography } from '@mui/material';
import { FieldMappings } from 'pages/ShoplineType';
import { useTranslation } from 'react-i18next';
import Image from 'components/Image';
import LeftArrow from '../../../../assets/images/arrow/LeftArrow.png';
import RightArrow from '../../../../assets/images/arrow/RightArrow.png';
import { capitalizeFirstLetter } from 'utils/letterChange';

type Props = {
  row: FieldMappings;
};

export default function ProductMappingTableRow({ row }: Props) {
  const { t } = useTranslation();

  return (
    <TableRow>
      <TableCell align='left'>
        <Typography
          variant='subtitle2'
          sx={{ fontWeight: 'bold' }}
        >{`${capitalizeFirstLetter(row.source.label)}`}</Typography>
        <Typography variant='body2' sx={{ color: 'rgba(99, 115, 129, 1)' }}>
          {row.source.name}
        </Typography>
      </TableCell>
      <TableCell align='left'>
        {row.direction === 'source_to_target' ? (
          <Image
            src={RightArrow}
            visibleByDefault
            disabledEffect
            sx={{
              objectFit: 'contain',
              width: '30px',
              height: '30px',
            }}
            alt='success'
          />
        ) : (
          <Image
            src={LeftArrow}
            visibleByDefault
            disabledEffect
            sx={{
              objectFit: 'contain',
              width: '30px',
              height: '30px',
            }}
            alt='success'
          />
        )}
      </TableCell>
      <TableCell align='left'>
        <Typography
          variant='subtitle2'
          sx={{ fontWeight: 'bold' }}
        >{`${capitalizeFirstLetter(row.target.label)}`}</Typography>
        <Typography variant='body2' sx={{ color: 'rgba(99, 115, 129, 1)' }}>
          {row.target.name}
        </Typography>
      </TableCell>
      <TableCell align='center' width='10%'>
        <Typography
          variant='body2'
          sx={
            row.enable
              ? {
                  backgroundColor: '#ffedc9',
                  color: '#FE9E0F',
                  fontWeight: 'bold',
                  borderRadius: '15px',
                }
              : {
                  backgroundColor: '#c1d0de',
                  color: 'rgba(99, 115, 129, 1)',
                  fontWeight: 'bold',
                  borderRadius: '15px',
                }
          }
        >
          {row.enable ? t('active') : t('inactive')}
        </Typography>
      </TableCell>
    </TableRow>
  );
}
