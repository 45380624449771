import { Outlet } from 'react-router-dom';
import { Box } from '@mui/material';
import Header from 'components/header/Header';

export default function DashboardLayout() {
  return (
    <Box>
      <Header />
      {/* <Outlet /> */}
    </Box>
  );
}
