import {
  Link,
  Checkbox,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  Box,
  IconButton,
  CircularProgress,
} from '@mui/material';
import IosShareIcon from '@mui/icons-material/IosShare';
import { CustomerListingType, CustomersList } from 'pages/ShoplineType';
import { useTranslation } from 'react-i18next';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import usePortalId from 'hooks/usePortalId';
import useConnector from 'hooks/useConnector';
import Iconify from 'components/Iconify';
import { useState } from 'react';
import axiosInstance from 'utils/axios';
import {
  CUSTOMER_LISTING_API_URL,
  ROWS_PER_PAGE,
  UPLOAD_SINGLE_CUSTOMER_API_URL,
} from 'api/ShoplineApiConstants';
import useAuth from 'hooks/useAuth';
import { useSnackbar } from 'notistack';
import { capitalizeFirstLetter } from 'utils/letterChange';
import { requestgetAllCustomers } from 'api/ShoplineApiHooks';
import { useQuery, useQueryClient } from 'react-query';
import _ from 'lodash';

type Props = {
  row: CustomersList;
  selected: boolean;
  onSelectRow: VoidFunction;
  activePage: number;
  filterName: string;
  skip: number;
};

export default function CustomersTableRow({
  row,
  selected,
  onSelectRow,
  activePage,
  filterName,
  skip,
}: Props) {
  const { user } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const portalId = usePortalId();
  const [loader, setLoader] = useState<boolean>(false);
  const { sourceShopId, targetShopId } = useConnector();
  let toolmsg = null;
  let hsCustomerId = null;
  const queryClient = useQueryClient();

  const { data } = useQuery<CustomerListingType>(
    [CUSTOMER_LISTING_API_URL, skip, filterName],
    () =>
      requestgetAllCustomers(
        ROWS_PER_PAGE,
        activePage,
        filterName,
        sourceShopId,
        targetShopId
      ),
    {
      refetchOnWindowFocus: false,
    }
  );

  const rowIndex = row.marketplace.findIndex(
    x => x.target_marketplace === 'hubspot'
  );

  let rowStatus = null;
  if (rowIndex !== -1) {
    rowStatus = row.marketplace[rowIndex].status === 'active' ? true : false;
  }
  if (row.marketplace.length > 0) {
    const hsOid = row.marketplace.filter(r => r.shop_id === targetShopId);
    if (hsOid.length) {
      if (hsOid[0].status === 'active') {
        hsCustomerId = hsOid[0].target_customer_id;
      }
      if (hsOid[0].status === 'error') {
        toolmsg = hsOid[0]?.errors[0];
      }
    }
  }

  const exportSingle = async (id: string, dbId: string) => {
    setLoader(true);
    try {
      const response = await axiosInstance.post(
        UPLOAD_SINGLE_CUSTOMER_API_URL,
        {
          source: {
            marketplace: 'shopline',
            shopId: sourceShopId,
          },
          target: {
            marketplace: 'hubspot',
            shopId: targetShopId,
          },
          marketplace: 'hubspot',
          limit: 10,
          source_customer_id: id,
          user_id: (user?.user_id || '') as string,
        },
        {
          headers: {
            'Ced-Source-Id': sourceShopId,
            'Ced-Target-Id': targetShopId,
          },
        }
      );
      if (response.data.success) {
        enqueueSnackbar(capitalizeFirstLetter(response.data.message), {
          variant: 'success',
        });
        setLoader(false);

        const cusData = await requestgetAllCustomers(
          ROWS_PER_PAGE,
          activePage,
          filterName,
          sourceShopId,
          targetShopId,
          dbId as unknown as number
        );

        const contacts = data?.data.rows;

        if (contacts) {
          const customerIndex = _.findIndex(contacts, {
            _id: dbId,
          });

          contacts[customerIndex] = cusData.data.rows[customerIndex];
          queryClient.setQueryData(
            [CUSTOMER_LISTING_API_URL, skip, filterName],
            { ...data, data: { ...data?.data, rows: contacts } }
          );
        }
      } else {
        if (response.data.errors) {
          _.forEach(response.data.errors, error => {
            enqueueSnackbar(capitalizeFirstLetter(error), {
              variant: 'error',
            });
          });
          setLoader(false);
        } else if (response.data.message) {
          enqueueSnackbar(capitalizeFirstLetter(response.data.message), {
            variant: 'error',
          });
          setLoader(false);
        }
      }
    } catch (error) {
      enqueueSnackbar(capitalizeFirstLetter(`${error}`), {
        variant: 'error',
      });
      setLoader(false);
      console.log(error);
    }
  };

  return (
    <TableRow>
      <TableCell align='center' width='5%'>
        <Checkbox checked={selected} onClick={onSelectRow} />
      </TableCell>
      <TableCell align='center' width='18%'>
        <Typography variant='body2'>{`${row.first_name} ${row.last_name}`}</Typography>
      </TableCell>
      <TableCell align='center' width='18%'>
        <Typography variant='body2' sx={{ color: 'rgba(99, 115, 129, 1)' }}>
          {row.email}
        </Typography>
      </TableCell>
      <TableCell align='center' width='20%'>
        <Typography variant='body2'>{row.phone}</Typography>
      </TableCell>
      <TableCell align='center' width='10%'>
        <Typography
          variant='body2'
          sx={
            hsCustomerId
              ? {
                  borderRadius: '10px',
                }
              : {
                  borderRadius: '10px',
                  color: 'rgba(99, 115, 129, 1)',
                }
          }
        >
          <Box className='flex items-center justify-center gap-[2px]'>
            {!hsCustomerId ? (
              <svg
                width='10'
                height='10'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <circle
                  cx='4'
                  cy='4'
                  r='4'
                  fill='rgba(99, 115, 129, 1)'
                ></circle>
              </svg>
            ) : (
              <Iconify
                icon={'eva:checkmark-circle-fill'}
                color='rgba(34, 154, 22, 1)'
                sx={{ w: '10px', h: '10px' }}
              />
            )}
            <Typography>
              {hsCustomerId ? t('synced') : t('unsynced')}
            </Typography>
          </Box>
        </Typography>
      </TableCell>
      <TableCell align='center' width='22%'>
        {hsCustomerId ? (
          <>
            <div className='flex items-center justify-center'>
              <span>{hsCustomerId}</span>
              <Tooltip title={t('view_in_hubspot')}>
                <Link
                  href={`https://app.hubspot.com/contacts/${portalId}/contact/${hsCustomerId}`}
                  target='_blank'
                  sx={{ textDecoration: 'none' }}
                  rel='noreferrer'
                >
                  <OpenInNewIcon sx={{ height: '15px', mt: '5px' }} />
                </Link>
              </Tooltip>
            </div>
          </>
        ) : toolmsg !== null ? (
          <Tooltip title={toolmsg}>
            <RemoveRedEyeIcon />
          </Tooltip>
        ) : (
          '-'
        )}
      </TableCell>
      <TableCell align='center' width='7%'>
        {loader ? (
          <CircularProgress size={20} />
        ) : (
          <Tooltip title='export' arrow>
            <IconButton
              onClick={() => {
                exportSingle(row.source_customer_id, row._id);
              }}
            >
              <IosShareIcon sx={{ width: '15px', height: '15px' }} />
            </IconButton>
          </Tooltip>
        )}
      </TableCell>
    </TableRow>
  );
}
