import { ReactNode } from 'react';
import { Outlet } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import LoadingScreen from '../components/LoadingScreen';
import PageInvalidToken from 'pages/PageInvalidToken';

type AuthGuardProps = {
  children?: ReactNode;
};

export default function AuthGuard({ children }: AuthGuardProps) {
  const { isAuthenticated, isInitialized } = useAuth();

  if (!isInitialized) {
    return <LoadingScreen />;
  }

  if (!isAuthenticated) {
    return <PageInvalidToken />;
  }

  return (
    <>
      {children}
      <Outlet />
    </>
  );
}
