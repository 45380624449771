import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useOngoingActivities } from 'api/ShoplineApiHooks';
import PartialPageSpinner from 'components/PartialPageSpinner';
import CircularProgressWithLabel from 'components/progress/CircularProgressWithLabel';
import useConnector from 'hooks/useConnector';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { fDateTime } from 'utils/formatTime';

export default function OngoingActivities() {
  const { t } = useTranslation();
  const { sourceShopId, targetShopId } = useConnector();
  const { data, refetch } = useOngoingActivities({
    target: {
      marketplace: 'hubspot',
      shopId: targetShopId,
    },
    source: {
      marketplace: 'shopline',
      shopId: sourceShopId,
    },
    activePage: '1',
    count: '10',
  });

  useEffect(() => {
    const interval = setInterval(() => {
      refetch();
    }, 10000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  if (data === undefined) {
    return <PartialPageSpinner />;
  }

  const ongoingActivities = data.data.rows;

  return (
    <Table>
      <TableHead>
        <TableRow />
      </TableHead>
      <TableBody>
        {ongoingActivities.length ? (
          ongoingActivities.map(activity => {
            return (
              <TableRow>
                <TableCell width='10%'>
                  <CircularProgressWithLabel
                    size={75}
                    value={activity.progress}
                  />
                </TableCell>
                <TableCell width='90%'>
                  <Typography variant='h6'>{activity.message}</Typography>
                  <Typography
                    variant='body2'
                    sx={{ color: 'text.secondary' }}
                    noWrap
                  >
                    {fDateTime(activity.created_at)}
                  </Typography>
                </TableCell>
              </TableRow>
            );
          })
        ) : (
          <Stack
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              minHeight: '500px',
            }}
          >
            <Typography variant='h4'>{t('no_activities_found')}</Typography>
          </Stack>
        )}
      </TableBody>
    </Table>
  );
}
