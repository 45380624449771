import {
  Card,
  CircularProgress,
  Grid,
  MenuItem,
  Select,
  Switch,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import React, { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useResponsive from 'hooks/useResponsive';
import { SHOPLINE_NAVIGATION } from 'routes/paths';
import { useNavigate } from 'react-router';
import { DEALS_TAB_NAMES } from 'pages/Deals/Deals';
import { useSnackbar } from 'notistack';
import useConnector from 'hooks/useConnector';
import { CONTACTS_TAB_NAMES } from 'pages/Contact/Contact';
import { PRODUCTS_TAB_NAMES } from 'pages/Products/Products';
import {
  CONNECT_TO_HUBSPOT_API_URL,
  GET_ALL_APPS,
  GET_SETTINGS_API_URL,
  SAVE_SETTINGS_API_URL,
  SWITCH_USER,
} from 'api/ShoplineApiConstants';
import axiosInstance from 'utils/axios';
import { StageMappingType } from 'pages/ShoplineType';
import LanguagePopover from 'layouts/dashboard/header/LanguagePopover';
import { capitalizeFirstLetter } from 'utils/letterChange';
import _ from 'lodash';
import { useSearchParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { ConnectedAppsDataType } from 'contexts/Connectors';
import { getAllConnectedAppsData } from 'api/ShoplineApiHooks';
import PartialPageSpinner from 'components/PartialPageSpinner';
import { LoadingButton } from '@mui/lab';
import OauthPopup from 'react-oauth-popup';
import { REDIRECT_URL } from 'config';

const Header = () => {
  const { portalId, sourceShopId, targetShopId, refetchConnectedAddData } =
    useConnector();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  let selectedDefaultValue = null;
  let previousShopId: number;
  let Token = searchParams.get('user_token');
  if (Token == null) {
    Token = window.sessionStorage.getItem('accessToken');
  }
  const { data } = useQuery<ConnectedAppsDataType>([GET_ALL_APPS], () =>
    getAllConnectedAppsData(Token || '')
  );

  useEffect(() => {
    const initialize = async () => {
      const response = await axiosInstance.get<StageMappingType>(
        GET_SETTINGS_API_URL,
        {
          params: {
            sourceShopId,
            targetShopId,
          },
          headers: {
            'Ced-Source-Id': sourceShopId,
            'Ced-Target-Id': targetShopId,
          },
        }
      );
      if (response.data.success) {
        const state = response.data.data;
        setChecked(state.global_sync);
      }
    };

    initialize();
  }, []);

  let tabValue = 'home';

  if (window.location.href.includes('contacts')) {
    tabValue = 'contacts';
  }
  if (window.location.href.includes('deals')) {
    tabValue = 'deals';
  }
  if (window.location.href.includes('products')) {
    tabValue = 'products';
  }
  if (window.location.href.includes('settings')) {
    tabValue = 'settings';
  }
  if (window.location.href.includes('activities')) {
    tabValue = 'activity';
  }
  if (window.location.href.includes('logs')) {
    tabValue = 'logs';
  }
  if (window.location.href.includes('automation')) {
    tabValue = 'automation';
  }
  if (window.location.href.includes('plan')) {
    tabValue = 'plan';
  }
  const [value, setValue] = useState(tabValue);

  useEffect(() => {
    setValue(tabValue);
  }, [tabValue]);

  const TABS = React.useMemo(
    () => [
      {
        value: 'home',
        label: 'Home',
        redirectTo: SHOPLINE_NAVIGATION.general.home(portalId),
      },
      {
        value: 'contacts',
        label: 'Contacts',
        redirectTo: SHOPLINE_NAVIGATION.general.contacts(
          portalId,
          CONTACTS_TAB_NAMES.CONTACT_LIST
        ),
      },
      {
        value: 'deals',
        label: 'Deals',
        redirectTo: SHOPLINE_NAVIGATION.general.deals(
          portalId,
          DEALS_TAB_NAMES.DEAL_LIST
        ),
      },
      {
        value: 'products',
        label: 'Products',
        redirectTo: SHOPLINE_NAVIGATION.general.products(
          portalId,
          PRODUCTS_TAB_NAMES.PRODUCT_LISTING
        ),
      },
      {
        value: 'automation',
        label: 'Automation',
        redirectTo: SHOPLINE_NAVIGATION.general.automation(portalId),
      },
      {
        value: 'settings',
        label: 'Settings',
        redirectTo: SHOPLINE_NAVIGATION.general.settings(portalId),
      },
      {
        value: 'logs',
        label: 'Logs',
        redirectTo: SHOPLINE_NAVIGATION.general.logs(portalId),
      },
      {
        value: 'activity',
        label: 'Activity',
        redirectTo: SHOPLINE_NAVIGATION.general.activity(
          portalId,
          t('ongoing')
        ),
      },
      {
        value: 'plan',
        label: 'plan',
        redirectTo: SHOPLINE_NAVIGATION.general.plan(portalId),
      },
    ],
    []
  );

  const smUp = useResponsive('up', 'sm');
  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const defaultVal = checked;
    setChecked(event.target.checked);
    try {
      const response = await axiosInstance.post(SAVE_SETTINGS_API_URL, {
        targetShopId: targetShopId,
        sourceShopId: sourceShopId,
        global_sync: event.target.checked,
        headers: {
          'Ced-Source-Id': sourceShopId,
          'Ced-Target-Id': targetShopId,
        },
      });
      if (response.data.success) {
        setChecked(event.target.checked);
        enqueueSnackbar(capitalizeFirstLetter(response.data.message), {
          variant: 'success',
        });
      } else {
        setChecked(defaultVal);
        enqueueSnackbar(capitalizeFirstLetter(response.data.message), {
          variant: 'error',
        });
      }
    } catch (err: any) {
      setChecked(defaultVal);
      enqueueSnackbar(capitalizeFirstLetter(err.message), {
        variant: 'error',
      });
    }
  };

  const handleSelect = async (value: any, terminate: boolean) => {
    setLoading(true);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    selectedDefaultValue = value;
    try {
      const response = await axiosInstance.post(SWITCH_USER, {
        sourceShopId: sourceShopId,
        from: previousShopId,
        to: value,
        terminate: terminate,
        headers: {
          'Ced-Source-Id': sourceShopId,
          'Ced-Target-Id': targetShopId,
        },
      });
      if (response.data.success) {
        try {
          const res = await axiosInstance.get<ConnectedAppsDataType>(
            GET_ALL_APPS,
            {
              headers: {
                Authorization: `Bearer ${Token}`,
                'Ced-Target-Id': targetShopId,
                'Ced-Source-Id': sourceShopId,
              },
            }
          );
          const activeConnection = res?.data.data.hubspot.installed.filter(
            active => active.is_connected
          );
          if (activeConnection[0].onboarded) {
            navigate(
              SHOPLINE_NAVIGATION.general.home(activeConnection[0]?.hub_id)
            );
          } else {
            navigate(SHOPLINE_NAVIGATION.login(Token || ''));
          }
          setLoading(false);
          window.location.reload();
        } catch (err: any) {
          setLoading(false);
          enqueueSnackbar(capitalizeFirstLetter(err.message), {
            variant: 'error',
            autoHideDuration: 3000,
          });
        }
      } else {
        setLoading(false);
        enqueueSnackbar(capitalizeFirstLetter(response.data.message), {
          variant: 'warning',
          autoHideDuration: 3000,
        });
        if (response.data.alert) {
          if (window.confirm(t('confirm_popup')) === true) {
            handleSelect(selectedDefaultValue, true);
          }
        }
      }
    } catch (err: any) {
      setLoading(false);
      enqueueSnackbar(capitalizeFirstLetter(err.message), {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }
  };

  const onCode = (code: string) => {
    refetchConnectedAddData && refetchConnectedAddData();
    navigate(SHOPLINE_NAVIGATION.login(Token || ''));
  };

  const onClose = () => console.log('closed!');

  const changeComponent = useCallback(
    (tab: any) => {
      setValue(tab.value);
      navigate(tab.redirectTo);
    },
    [navigate]
  );
  const portals = data?.data.hubspot;
  if (portals !== undefined) {
    const value = portals?.installed.filter(val => val.is_connected);
    selectedDefaultValue = value[0]._id;
    previousShopId = value[0]._id;
  }
  useEffect(() => {
    if (portals !== undefined) {
      setLoading(false);
    }
  }, [portals]);

  return (
    <Card
      sx={{
        m: smUp ? 5 : 0,
        ml: smUp ? 8 : 0,
        p: 2,
        flexWrap: smUp ? 'wrap' : null,
        display: smUp ? 'flex' : null,
        justifyContent: smUp ? 'space-between' : 'flex-start',
      }}
    >
      <Tabs
        allowScrollButtonsMobile
        variant='scrollable'
        scrollButtons='auto'
        sx={{
          pr: 2,
          flex: '0 0 100%',
          maxWidth: smUp ? '60%' : '100%',
          alignItems: 'center',
        }}
        textColor='secondary'
        indicatorColor='secondary'
        aria-label='secondary tabs example'
        value={value}
      >
        {TABS.map(tab => (
          <Tab
            disableRipple
            key={tab.value}
            value={tab.value}
            label={tab.label}
            onClick={() => {
              changeComponent(tab);
            }}
          />
        ))}
      </Tabs>
      <div
        style={{
          display: 'flex',
          alignItems: smUp ? 'center' : 'center',
          justifyContent: smUp ? 'flex-end' : 'center',
          flex: '0 0 100%',
          maxWidth: smUp ? '40%' : '100%',
        }}
      >
        <div>
          {!loading ? (
            <Select
              variant='outlined'
              sx={{
                width: '200px',
                boxShadow: 'none',
              }}
              value={selectedDefaultValue}
              onChange={e => handleSelect(e.target.value, false)}
            >
              {_.map(portals?.installed, portals => (
                <MenuItem
                  value={portals._id}
                  sx={{
                    mx: 1,
                    borderBottom: '1px solid #e6e9ec',
                  }}
                >
                  <Typography variant='subtitle2'>
                    {portals.is_connected ? (
                      <svg
                        width='10'
                        height='10'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <circle
                          cx='4'
                          cy='4'
                          r='4'
                          fill='rgba(34, 154, 22, 1)'
                        ></circle>
                      </svg>
                    ) : (
                      ''
                    )}{' '}
                    {portals.hub_id} {' - '}
                  </Typography>
                  <Typography
                    sx={{
                      whiteSpace: 'nowrap',
                      maxWidth: '160px',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      userSelect: 'all',
                    }}
                  >
                    {portals.hub_domain}
                  </Typography>
                </MenuItem>
              ))}
              <OauthPopup
                url={`${CONNECT_TO_HUBSPOT_API_URL}?code=hubspot&bearer=${Token}&source=shopline&source_shop_id=${sourceShopId}&redirect_return_type=custom&custom_url=${REDIRECT_URL}?code=hubspot&success=true`}
                onCode={onCode}
                onClose={onClose}
                title={''}
                width={725}
                height={725}
              >
                <LoadingButton
                  type='button'
                  variant='contained'
                  sx={{
                    mx: '10px',
                    borderRadius: 0,
                    mt: 1,
                    boxShadow: 'none',
                    width: '-webkit-fill-available',
                  }}
                >
                  + {t('add_new_portal')}
                </LoadingButton>
              </OauthPopup>
            </Select>
          ) : (
            <Grid sx={{ p: 2 }}>
              <CircularProgress size={20} />
            </Grid>
          )}
        </div>
        <div>
          <Typography
            variant='caption'
            color={checked ? 'rgba(34, 154, 22, 1)' : '#FF4842'}
            sx={{ ml: 2, fontSize: '13px' }}
          >
            {t('historical_data_sync_enabled', {
              value: checked ? t('enabled') : t('disabled'),
            })}
          </Typography>
          <Switch
            checked={checked}
            onChange={event => {
              handleChange(event);
            }}
            inputProps={{ 'aria-label': 'controlled' }}
          />
        </div>
        <LanguagePopover />
      </div>
    </Card>
  );
};

export default Header;
