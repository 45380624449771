import { LoadingButton } from '@mui/lab';
import { Card, Typography, Stack, Button } from '@mui/material';
import { GET_CANCEL_CURRENT_SUBSCRIPTION } from 'api/ShoplineApiConstants';
import { useGetActivePlans } from 'api/ShoplineApiHooks';
import useConnector from 'hooks/useConnector';
import usePortalId from 'hooks/usePortalId';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { SHOPLINE_NAVIGATION } from 'routes/paths';
import axiosInstance from 'utils/axios';
import PartialPageSpinner from './PartialPageSpinner';

const ButtonSX = {
  color: '#000000',
  border: '1px solid',
};

export const PlanDescription = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const portalId = usePortalId();
  const { enqueueSnackbar } = useSnackbar();
  const { sourceShopId, targetShopId } = useConnector();
  const [isCancel, setIsCancel] = useState<boolean>(false);
  const { data, refetch } = useGetActivePlans(
    sourceShopId,
    targetShopId,
    false
  );
  if (!data?.success && data?.data === undefined) {
    return (
      <Card sx={{ padding: '24px' }}>
        <Typography variant='h5'>
          {t('you_dont_have_any_active_plan')}
        </Typography>
      </Card>
    );
  }
  if (data?.data === undefined) {
    return <PartialPageSpinner />;
  }

  const rows = data?.data;
  const cancelSubscription = async () => {
    if (window.confirm('Are you sure') === true) {
      setIsCancel(true);
      try {
        const res = await axiosInstance.get(GET_CANCEL_CURRENT_SUBSCRIPTION, {
          headers: {
            'Ced-Source-Id': sourceShopId,
            'Ced-Target-Id': targetShopId,
          },
        });
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const { message, success } = res.data;
        enqueueSnackbar(message, {
          variant: `${success ? 'success' : 'error'}`,
          autoHideDuration: 4000,
        });
        setIsCancel(false);
        refetch();
        navigate(`${SHOPLINE_NAVIGATION.general.plan(portalId)}`);
      } catch (error: any) {
        setIsCancel(false);
        enqueueSnackbar(error.message, {
          variant: 'error',
          autoHideDuration: 4000,
        });
      }
    }
  };

  return (
    <Card sx={{ padding: '24px' }}>
      <Stack direction='row' justifyContent='space-between' alignItems='center'>
        <Typography sx={{ color: '#637381' }} className='text-[14px]'>
          {data?.success
            ? t('your_active_plan')
            : t('your_plan_has_been_expired')}
        </Typography>
        <div className='flex gap-[10px]'>
          {rows.active_plan.plan_details.title !== 'Free' && (
            <>
              <LoadingButton
                loading={isCancel}
                sx={ButtonSX}
                onClick={cancelSubscription}
              >
                {t('cancel_plan')}
              </LoadingButton>
              <Button
                variant='outlined'
                disabled={isCancel}
                onClick={() =>
                  navigate(
                    `${SHOPLINE_NAVIGATION.general.plan(
                      portalId
                    )}/plan-listing`,
                    {
                      state: { title: rows.active_plan.plan_details.title },
                    }
                  )
                }
              >
                {t('upgrade_plan')}
              </Button>
            </>
          )}
          {rows.active_plan.plan_details.title === 'Free' && (
            <Button
              variant='outlined'
              onClick={() =>
                navigate(
                  `${SHOPLINE_NAVIGATION.general.plan(portalId)}/plan-listing`,
                  {
                    state: { title: rows.active_plan.plan_details.title },
                  }
                )
              }
            >
              {t('start_plan')}
            </Button>
          )}
        </div>
      </Stack>
      <div>
        <h1>{rows.active_plan.plan_details.title}</h1>
        {!data?.success ? (
          <Typography>
            {t('expiry_date')}:- {rows.active_plan.expiring_at}
          </Typography>
        ) : (
          ''
        )}
      </div>
    </Card>
  );
};
