// @mui
import {
  Box,
  Card,
  Container,
  Grid,
  Paper,
  Button,
  Table,
  TableContainer,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Slide,
  TableBody,
  Pagination,
} from '@mui/material';
import { TableHeadCustom, TableNoData } from 'components/table';
import { useTranslation } from 'react-i18next';
import Page from '../../../components/Page';
import Image from 'components/Image';
import syncStage from 'assets/images/sync.png';
import processComplete from 'assets/images/processComplete.png';
import onFail from 'assets/images/onfail.png';
import { LoadingButton } from '@mui/lab';
import { TransitionProps } from '@mui/material/transitions';
import React, { useEffect, useState } from 'react';
import { requestgetAllCustomers } from 'api/ShoplineApiHooks';
import PartialPageSpinner from 'components/PartialPageSpinner';
import Scrollbar from 'components/Scrollbar';
import { CustomersList, CustomerListingType } from '../../ShoplineType';
import CustomersTableRow from './child/CustomersTableRow';
import CustomerTableToolbar from './child/CustomerTableToolbar';
import { useQuery, useQueryClient } from 'react-query';
import {
  CUSTOMER_LISTING_API_URL,
  UPLOAD_BULK_CUSTOMER_API_URL,
} from 'api/ShoplineApiConstants';
import useTable from 'hooks/useTable';
import { useSnackbar } from 'notistack';
import useConnector from 'hooks/useConnector';
import _ from 'lodash';
import axiosInstance from 'utils/axios';
import useAuth from 'hooks/useAuth';
import { capitalizeFirstLetter } from 'utils/letterChange';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction='up' ref={ref} {...props} />;
});

export default function Customers() {
  const { user } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { sourceShopId, targetShopId } = useConnector();
  const [openSync, setOpenSync] = React.useState(false);
  const [openComplete, setopenComplete] = React.useState(false);
  const [openError, setError] = React.useState(false);
  const [filterName, setFilterName] = useState('');
  const [filter, setFilter] = useState('');
  const [skip, setSkip] = useState(0);
  const TABLE_HEAD = [
    { id: 'name', label: t('name'), align: 'center' },
    { id: 'email', label: t('email'), align: 'center' },
    { id: 'phone', label: t('phone'), align: 'center' },
    { id: 'status', label: t('status'), align: 'center' },
    { id: 'link', label: t('view_in_hubspot'), align: 'center' },
    { id: 'action', label: t('action'), align: 'right' },
  ];

  const ROWS_PER_PAGE = 10;

  let contentLoad: React.ReactNode = null;
  const handleClose = () => {
    setOpenSync(false);
    setError(false);
    setopenComplete(false);
  };

  const { selected, onSelectRow, onSelectAllRows } = useTable({
    defaultOrderBy: 'first_name',
  });

  const activePage = skip / ROWS_PER_PAGE + 1;

  const { data, isLoading } = useQuery<CustomerListingType>(
    [CUSTOMER_LISTING_API_URL, skip, filterName],
    () =>
      requestgetAllCustomers(
        ROWS_PER_PAGE,
        activePage,
        filterName,
        sourceShopId,
        targetShopId
      ),
    {
      refetchOnWindowFocus: false,
    }
  );

  const exportBulk = async () => {
    if (selected.length) {
      setOpenSync(true);
      try {
        const response = await axiosInstance.post(
          UPLOAD_BULK_CUSTOMER_API_URL,
          {
            marketplace: 'hubspot',
            source: {
              marketplace: 'shopline',
              shopId: sourceShopId,
            },
            target: {
              marketplace: 'hubspot',
              shopId: targetShopId,
            },
            limit: 10,
            source_customer_ids: selected,
            user_id: (user?.user_id || '') as string,
          },
          {
            headers: {
              'Ced-Source-Id': sourceShopId,
              'Ced-Target-Id': targetShopId,
            },
          }
        );
        if (response.data.success) {
          enqueueSnackbar(capitalizeFirstLetter(response.data.message), {
            variant: 'success',
          });
          setOpenSync(false);
          setopenComplete(true);
        } else {
          if (response.data.errors) {
            _.forEach(response.data.errors, error => {
              enqueueSnackbar(capitalizeFirstLetter(error), {
                variant: 'error',
              });
            });
          } else if (response.data.message) {
            enqueueSnackbar(capitalizeFirstLetter(response.data.message), {
              variant: 'error',
            });
          }
          setOpenSync(false);
          setError(true);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      enqueueSnackbar(
        capitalizeFirstLetter(t('select_atleast_one_product_to_export')),
        {
          variant: 'error',
        }
      );
    }
  };

  const mapPages = (data: CustomersList[]) => {
    const rows: React.ReactNode[] = [];
    data.forEach(row => {
      rows.push(
        <CustomersTableRow
          key={row._id}
          row={row}
          selected={selected.includes(row.source_customer_id)}
          onSelectRow={() => onSelectRow(row.source_customer_id)}
          activePage={activePage}
          filterName={filterName}
          skip={skip}
        />
      );
    });

    return rows;
  };

  const handleFilterName = (filterName: string) => {
    setFilter(filterName);
  };
  useEffect(() => {
    if (filter === '' || filter.length >= 3) {
      setFilterName(filter);
      setSkip(0);
    }
  }, [filter]);

  const contacts = data?.data;

  const totalPage = contacts?.count
    ? Math.ceil((contacts?.count ?? 0) / ROWS_PER_PAGE)
    : 0;

  if (contacts) {
    contentLoad = contacts?.rows.length ? (
      <TableBody>{mapPages(contacts?.rows)}</TableBody>
    ) : (
      <>
        <TableNoData isNotFound={true} Message={t('customer')} />
      </>
    );
  }

  return (
    <Page title={t('contact')}>
      {/* <Container maxWidth='xl' sx={{ padding: '5px' }}> */}
      {/* <Box sx={{ flexGrow: 1 }} /> */}
      <Card sx={{ mx: 4 }}>
        <Grid container sx={{ p: 1 }}>
          <CustomerTableToolbar
            filterName={filter}
            onFilterName={handleFilterName}
          />
          <LoadingButton
            sx={{
              position: 'absolute',
              top: '35px',
              right: '20px',
            }}
            variant='contained'
            loading={false}
            onClick={exportBulk}
          >
            {t('sync_selected_data_row')}
          </LoadingButton>
        </Grid>
        {isLoading ? (
          <PartialPageSpinner />
        ) : (
          <>
            <Scrollbar>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 800 }} aria-label='simple table'>
                  <TableHeadCustom
                    headLabel={TABLE_HEAD}
                    rowCount={ROWS_PER_PAGE}
                    numSelected={selected.length}
                    onSelectAllRows={checked =>
                      onSelectAllRows(
                        checked,
                        contacts?.rows
                          ? contacts?.rows.map(row => row.source_customer_id)
                          : []
                      )
                    }
                  />
                  {contentLoad}
                </Table>
              </TableContainer>
            </Scrollbar>
            {contacts && totalPage > 1 && (
              <Card
                sx={{
                  display: 'flex',
                  paddingBottom: '25px',
                  justifyContent: 'center',
                  paddingTop: '10px',
                }}
              >
                <Pagination
                  color='primary'
                  variant='outlined'
                  count={totalPage}
                  page={Math.ceil(skip / ROWS_PER_PAGE + 1)}
                  onChange={(e, pageNo) =>
                    setSkip((pageNo - 1) * ROWS_PER_PAGE)
                  }
                />
              </Card>
            )}
          </>
        )}
        <Dialog
          open={openSync}
          TransitionComponent={Transition}
          keepMounted
          aria-describedby='alert-dialog-slide-description'
        >
          <Box
            noValidate
            component='form'
            sx={{
              textAlign: 'center',
            }}
          >
            <DialogContent>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  m: 'auto',
                  width: 'fit-content',
                }}
              >
                <DialogContentText id='alert-dialog-slide-description'>
                  <Image
                    src={syncStage}
                    visibleByDefault
                    disabledEffect
                    sx={{
                      objectFit: 'contain',
                      width: '120px',
                      height: '120px',
                    }}
                    alt='success'
                  />
                </DialogContentText>
              </Box>
              <DialogTitle>{t('sync_in_progress')}</DialogTitle>
              <DialogContentText sx={{ justifyContent: 'center' }}>
                {t('you_can_see_your_sync_progress_in_the_activity_tab')}
              </DialogContentText>
            </DialogContent>
          </Box>
        </Dialog>
        <Dialog
          open={openComplete}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-describedby='alert-dialog-slide-description'
        >
          <Box
            noValidate
            component='form'
            sx={{
              textAlign: 'center',
              justifyContent: 'center',
            }}
          >
            <DialogContent>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  m: 'auto',
                  width: 'fit-content',
                }}
              >
                <DialogContentText id='alert-dialog-slide-description'>
                  <Image
                    src={processComplete}
                    visibleByDefault
                    disabledEffect
                    sx={{
                      objectFit: 'contain',
                      width: '120px',
                      height: '120px',
                    }}
                    alt='success'
                  />
                </DialogContentText>
              </Box>
              <DialogTitle>{t('sync_successfull')}</DialogTitle>
              <DialogContentText sx={{ justifyContent: 'center' }}>
                {t('all_the_data_is_successfully_synced')}
              </DialogContentText>
              <DialogContentText sx={{ justifyContent: 'center' }}>
                {t('close_this_window_to_see_updated_data_tables')}
              </DialogContentText>
            </DialogContent>
          </Box>
          <DialogActions>
            <Button onClick={handleClose} autoFocus>
              {t('close')}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={openError}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-describedby='alert-dialog-slide-description'
        >
          <Box
            noValidate
            component='form'
            sx={{
              textAlign: 'center',
              justifyContent: 'center',
            }}
          >
            <DialogContent>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  m: 'auto',
                  width: 'fit-content',
                }}
              >
                <DialogContentText id='alert-dialog-slide-description'>
                  <Image
                    src={onFail}
                    visibleByDefault
                    disabledEffect
                    sx={{
                      objectFit: 'contain',
                      width: '120px',
                      height: '120px',
                    }}
                    alt='success'
                  />
                </DialogContentText>
              </Box>
              <DialogTitle>{t('sync_unsuccessfull')}</DialogTitle>
              <DialogContentText sx={{ justifyContent: 'center' }}>
                {t('unable_to_sync_all_the_data')}
              </DialogContentText>
              <DialogContentText sx={{ justifyContent: 'center' }}>
                {t('close_this_window_to_see_updated_data_tables')}
              </DialogContentText>
            </DialogContent>
          </Box>
          <DialogActions>
            <Button onClick={handleClose} autoFocus>
              {t('close')}
            </Button>
          </DialogActions>
        </Dialog>
      </Card>
      {/* </Container> */}
    </Page>
  );
}
