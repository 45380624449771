import { capitalCase } from 'change-case';
import { Container, Tab, Box, Tabs, Typography, Card } from '@mui/material';
import Page from 'components/Page';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import OngoingActivities from './OngoingActivities';
import CompletedActivities from './CompletedActivities';
import AccessTimeFilledRoundedIcon from '@mui/icons-material/AccessTimeFilledRounded';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import { SHOPLINE_NAVIGATION } from 'routes/paths';
import useConnector from 'hooks/useConnector';

export default function Settings() {
  const { portalId } = useConnector();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { tab } = useParams<{ tab: string }>();
  const ACCOUNT_TABS = [
    {
      value: t('ongoing'),
      icon: <AccessTimeFilledRoundedIcon />,
      component: <OngoingActivities />,
    },
    {
      value: t('completed'),
      icon: <CheckCircleRoundedIcon />,
      component: <CompletedActivities />,
    },
  ];

  return (
    <Page title={t('activities')}>
      {/* <Container maxWidth='xl' sx={{ padding: '5px' }}> */}
      <Card sx={{ padding: '24px' }} className='mx-[40px] ml-[64px]'>
        <Typography variant='h4' gutterBottom>
          {t('activities')}
        </Typography>

        <Box sx={{ mb: 5 }} />

        <Card sx={{ padding: '10px' }}>
          <Tabs
            allowScrollButtonsMobile
            variant='scrollable'
            scrollButtons='auto'
            value={tab}
            onChange={(e, tabIndex) =>
              navigate(
                SHOPLINE_NAVIGATION.general.activity(Number(portalId), tabIndex)
              )
            }
          >
            {ACCOUNT_TABS.map(tab => (
              <Tab
                disableRipple
                key={t(tab.value)}
                label={t(tab.value)}
                icon={tab.icon}
                value={tab.value}
              />
            ))}
          </Tabs>
        </Card>

        <Box sx={{ mb: 5 }} />

        {ACCOUNT_TABS.map(tabVal => {
          const isMatched = tabVal.value === tab;

          return isMatched && <Box key={tabVal.value}>{tabVal.component}</Box>;
        })}
      </Card>
      {/* </Container> */}
    </Page>
  );
}
