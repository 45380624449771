import {
  Button,
  Checkbox,
  TableCell,
  Tooltip,
  TableRow,
  Typography,
} from '@mui/material';
import { DataLogType } from 'pages/ShoplineType';
import { useTranslation } from 'react-i18next';
import { fDate } from 'utils/formatTime';

type Props = {
  row: DataLogType;
  selected: boolean;
  onSelectRow: VoidFunction;
  handleViewDetails: (contentRequest: string, contentResponse: string) => void;
};

const DataLogsTablesRow = ({
  row,
  selected,
  onSelectRow,
  handleViewDetails,
}: Props) => {
  const { t } = useTranslation();

  return (
    <TableRow>
      <TableCell padding='checkbox'>
        <Checkbox checked={selected} onClick={onSelectRow} />
      </TableCell>
      <TableCell align='center'>
        <Typography variant='body2'>{row?.objectType ?? ''}</Typography>
      </TableCell>
      <TableCell align='center'>
        {row.internalObjectId.length > 20 ? (
          <Tooltip title={row?.internalObjectId.split(',').map(email => email)}>
            <Typography variant='body2'>
              {`${row?.internalObjectId.substring(0, 21)} ...` ?? ''}
            </Typography>
          </Tooltip>
        ) : (
          <Typography variant='body2'>{row?.internalObjectId}</Typography>
        )}
      </TableCell>
      <TableCell align='center'>
        <Typography variant='body2'>
          {fDate(Number(row?.created_at)) ?? ''}
        </Typography>
      </TableCell>
      <TableCell align='center'>
        <Typography variant='body2'>{row?.operation ?? ''}</Typography>
      </TableCell>
      <TableCell align='center'>
        <Typography variant='body2'>{row?.action ?? ''}</Typography>
      </TableCell>
      <TableCell align='center'>
        <Button
          variant='text'
          onClick={() => handleViewDetails(row?.request, row?.response)}
        >
          {t('view_details')}
        </Button>
      </TableCell>
    </TableRow>
  );
};

export default DataLogsTablesRow;
