import {
  Button,
  Stack,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useCompletedActivities } from 'api/ShoplineApiHooks';
import PartialPageSpinner from 'components/PartialPageSpinner';
import useConnector from 'hooks/useConnector';
import { useTranslation } from 'react-i18next';
import { fDateTime } from 'utils/formatTime';
import { CLEAR_ALL_NOTIFICATIONS_API_URL } from 'api/ShoplineApiConstants';
import { useSnackbar } from 'notistack';
import axiosInstance from 'utils/axios';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import { capitalizeFirstLetter } from 'utils/letterChange';

export default function CompletedActivities() {
  const { enqueueSnackbar } = useSnackbar();
  const { sourceShopId, targetShopId } = useConnector();
  const { t } = useTranslation();
  const { data, refetch } = useCompletedActivities({
    target: {
      marketplace: 'hubspot',
      shopId: targetShopId,
    },
    source: {
      marketplace: 'shopline',
      shopId: sourceShopId,
    },
    activePage: '1',
    count: '10',
  });

  if (data === undefined) {
    return <PartialPageSpinner />;
  }

  const completedActivities = data.data.rows;

  const handleClearAll = async () => {
    try {
      const response = await axiosInstance.post(
        CLEAR_ALL_NOTIFICATIONS_API_URL,
        {
          appTag: 'default',
          target: {
            shopId: { targetShopId },
          },
          source: {
            shopId: { sourceShopId },
          },
        },
        {
          headers: {
            'Ced-Source-Id': sourceShopId,
            'Ced-Target-Id': targetShopId,
          },
        }
      );
      if (response.data.success) {
        enqueueSnackbar(capitalizeFirstLetter(response.data.message), {
          variant: 'success',
        });
        refetch();
      } else {
        enqueueSnackbar(capitalizeFirstLetter(response.data.message), {
          variant: 'error',
        });
      }
    } catch (error: any) {
      enqueueSnackbar(capitalizeFirstLetter(error.message), {
        variant: 'error',
      });
    }
  };

  return (
    <>
      {completedActivities.length ? (
        <Stack sx={{ display: 'flex', flexDirection: 'row-reverse' }}>
          <Button variant='outlined' onClick={() => handleClearAll()}>
            {t('clear_all')}
          </Button>
        </Stack>
      ) : null}
      <Table>
        <TableHead>
          <TableRow />
        </TableHead>
        <TableBody>
          {completedActivities.length ? (
            completedActivities.map(activity => {
              return (
                <Card sx={{ background: 'rgba(208, 242, 255, 1)', m: 2 }}>
                  <TableRow>
                    <TableCell>
                      {activity.severity === 'success' ? (
                        <CheckCircleRoundedIcon
                          sx={{ height: '50px', width: '50px', color: 'blue' }}
                        />
                      ) : null}
                      {activity.severity === 'error' ? (
                        <CancelRoundedIcon
                          sx={{ height: '50px', width: '50px', color: 'red' }}
                        />
                      ) : null}
                      {activity.severity === 'critical' ? (
                        <ErrorRoundedIcon
                          sx={{
                            height: '50px',
                            width: '50px',
                            color: 'orange',
                          }}
                        />
                      ) : null}
                    </TableCell>
                    <TableCell width='90%'>
                      <Typography variant='h6'>{activity.message}</Typography>
                      <Typography
                        variant='body2'
                        sx={{ color: 'text.secondary' }}
                        noWrap
                      >
                        {fDateTime(activity.created_at)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </Card>
              );
            })
          ) : (
            <Stack
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '500px',
              }}
            >
              <Typography variant='h4'>{t('no_activities_found')}</Typography>
            </Stack>
          )}
        </TableBody>
      </Table>
    </>
  );
}
