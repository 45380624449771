import { Suspense, lazy, ElementType } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
import LoadingScreen from '../components/LoadingScreen';
import { shopLineRoutes } from './shopLineRoutes';
import TopHeader from 'layouts/dashboard/header/TopHeader';
import WebpackLoadError from 'pages/others/ErrorBoundary';

const Loadable = (Component: ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { isAuthenticated } = useAuth();

  const isDashboard = pathname.includes('/dashboard') && isAuthenticated;

  return (
    <WebpackLoadError>
      <Suspense fallback={<LoadingScreen isDashboard={isDashboard} />}>
        <Component {...props} />
      </Suspense>
    </WebpackLoadError>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'user/login',
      element: <Login />,
    },
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <Page404 /> },
        { path: 'Invalid-token', element: <PageInvalidToken /> },
        { path: '*', element: <Navigate to='/404' replace /> },
      ],
    },

    {
      path: '/setup',
      element: (
        <GuestGuard>
          <ConnectToHubspot />
        </GuestGuard>
      ),
    },
    {
      path: ':portalId',
      element: (
        <AuthGuard>
          <TopHeader />
        </AuthGuard>
      ),
      children: [
        {
          path: '',
          children: shopLineRoutes,
        },
      ],
    },
  ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/user/Login')));

// MAIN
const Page404 = Loadable(lazy(() => import('../pages/Page404')));
const PageInvalidToken = Loadable(
  lazy(() => import('../pages/PageInvalidToken'))
);
const ConnectToHubspot = Loadable(
  lazy(() => import('../pages/ConnectToHubspot'))
);
