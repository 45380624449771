import { LoadingButton } from '@mui/lab';
import {
  // Autocomplete,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  TextField,
  Typography,
} from '@mui/material';
import { UPLOAD_MANY_PRODUCT_API_URL } from 'api/ShoplineApiConstants';
import axiosInstance from 'utils/axios';
import toolTip from 'assets/images/toolTip.png';
import Image from 'components/Image';
import useAuth from 'hooks/useAuth';
import useConnector from 'hooks/useConnector';
import useResponsive from 'hooks/useResponsive';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetter } from 'utils/letterChange';

export default function ProductSync() {
  const { user } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const { sourceShopId, targetShopId } = useConnector();
  const [dateCheck, setDateCheck] = useState(false);
  const defaultDate = new Date().toISOString().slice(0, 10);
  const [startDate, setStartDate] = useState(defaultDate);
  const [endDate, setEndDate] = useState(defaultDate);
  const isDesktop = useResponsive('up', 'md');
  const handleSyncNow = async () => {
    let filter = null;
    if (dateCheck) {
      filter = {
        source: {
          marketplace: 'shopline',
          shopId: sourceShopId,
        },
        target: {
          marketplace: 'hubspot',
          shopId: targetShopId,
        },
        limit: 10,
        user_id: (user?.user_id || '') as string,
        filter: {
          source_created_at: {
            7: {
              from: startDate,
              to: endDate,
            },
          },
        },
      };
    } else {
      filter = {
        source: {
          marketplace: 'shopline',
          shopId: sourceShopId,
        },
        target: {
          marketplace: 'hubspot',
          shopId: targetShopId,
        },
        limit: 10,
        profile_id: 'all_products',
        user_id: (user?.user_id || '') as string,
      };
    }
    setIsLoading(true);
    try {
      await axiosInstance
        .post(UPLOAD_MANY_PRODUCT_API_URL, filter, {
          headers: {
            'Ced-Source-Id': sourceShopId,
            'Ced-Target-Id': targetShopId,
          },
        })
        .then(result => {
          setIsLoading(false);
          if (result.data.success) {
            enqueueSnackbar(capitalizeFirstLetter(result.data.message), {
              variant: 'success',
            });
          } else {
            enqueueSnackbar(capitalizeFirstLetter(result.data.message), {
              variant: 'error',
            });
          }
        });
    } catch (error: any) {
      setIsLoading(false);
      enqueueSnackbar(capitalizeFirstLetter(error.message), {
        variant: 'error',
      });
    }
  };
  const handleDateShow = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setDateCheck(checked);
  };

  const handleDateChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    startOrFrom: boolean
  ) => {
    if (startOrFrom) {
      setStartDate(event.target.value);
    } else {
      setEndDate(event.target.value);
    }
  };

  return (
    <Grid container spacing={3} sx={{ p: 2 }}>
      <Grid item xs={12} md={12}>
        <Stack>
          <Typography variant='h6'>
            {t('select_a_time_period')}
            <Tooltip title={t('select_a_date_range_for_syncing')}>
              <IconButton>
                <Image
                  src={toolTip}
                  visibleByDefault
                  disabledEffect
                  sx={{
                    position: 'absolute',
                    width: '20px',
                    ml: 3,
                    height: '20px',
                  }}
                />
              </IconButton>
            </Tooltip>
          </Typography>
        </Stack>
      </Grid>
      <Grid item xs={12} md={12}>
        <Stack sx={{ mb: 4 }}>
          <Typography variant='body2'>
            <FormControlLabel
              control={
                <Checkbox
                  sx={{ ml: 1 }}
                  onChange={(event, checked) => handleDateShow(event, checked)}
                  checked={dateCheck}
                />
              }
              label={''}
            />
            {t('date_range_for_user')}
          </Typography>
        </Stack>
        {dateCheck ? (
          <Grid container sx={{ mb: 4 }}>
            <Grid item sx={{ mt: 2, mb: 2 }}>
              <Stack
                sx={{
                  minWidth: '60%',
                  display: 'flex',
                  flexDirection: isDesktop ? 'row' : 'column',
                }}
              >
                <TextField
                  id='from'
                  label={t('start_date')}
                  type='date'
                  value={startDate}
                  inputProps={{
                    max: defaultDate,
                  }}
                  onChange={event => handleDateChange(event, true)}
                  sx={{ width: 220 }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <Typography
                  variant='subtitle2'
                  sx={{ ml: 4, mr: 4, mt: 2, mb: 2 }}
                >
                  {t('to')}
                </Typography>
                <TextField
                  id='to'
                  label={t('end_date')}
                  type='date'
                  inputProps={{
                    max: defaultDate,
                  }}
                  onChange={event => handleDateChange(event, false)}
                  value={endDate}
                  sx={{ width: 220 }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Stack>
            </Grid>
          </Grid>
        ) : (
          ''
        )}
        <Stack sx={{ p: 1, width: '10%' }}>
          <LoadingButton
            type='submit'
            variant='contained'
            onClick={handleSyncNow}
            loading={isLoading}
          >
            {t('sync_now')}
          </LoadingButton>
        </Stack>
      </Grid>
    </Grid>
  );
}
