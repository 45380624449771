import { Card, Box, Tab, Tabs } from '@mui/material';
import { capitalCase } from 'change-case';
import { useTranslation } from 'react-i18next';
import useResponsive from '../../hooks/useResponsive';
import { useNavigate, useParams } from 'react-router';
import Page from '../../components/Page';
import ContactFieldMapping from 'pages/Contact/Tabs/ContactFieldMapping';
import ContactList from './Tabs/ContactList';
import ContactSync from './Tabs/ContactSync';
import { SHOPLINE_NAVIGATION } from 'routes/paths';
import usePortalId from 'hooks/usePortalId';
import React from 'react';

export const CONTACTS_TAB_NAMES = {
  CONTACT_LIST: 'contact-list',
  CONTACT_FIELD_MAPPING: 'contact-field-mapping',
  CONTACT_SYNC: 'contact-sync',
};

export default function Contact() {
  const { t } = useTranslation();
  const portalId = usePortalId();
  const navigate = useNavigate();
  const smUp = useResponsive('up', 'sm');
  const { tab } = useParams<{ tab: string }>();

  const CONTACTS_TABS = React.useMemo(
    () => [
      {
        value: CONTACTS_TAB_NAMES.CONTACT_LIST,
        icon: '',
        component: <ContactList />,
      },
      {
        value: CONTACTS_TAB_NAMES.CONTACT_SYNC,
        icon: '',
        component: <ContactSync />,
      },
      {
        value: CONTACTS_TAB_NAMES.CONTACT_FIELD_MAPPING,
        icon: '',
        component: <ContactFieldMapping />,
      },
    ],
    []
  );

  return (
    <Page title={t('contact')}>
      <Box>
        <Card sx={{ m: smUp ? 5 : 0, ml: smUp ? 8 : 0, p: 2 }}>
          <Tabs
            allowScrollButtonsMobile
            variant='scrollable'
            sx={{ ml: '30px' }}
            scrollButtons='auto'
            value={tab}
            onChange={(e, tabIndex) =>
              navigate(SHOPLINE_NAVIGATION.general.contacts(portalId, tabIndex))
            }
          >
            {CONTACTS_TABS.map(tab => (
              <Tab
                disableRipple
                key={t(tab.value)}
                label={capitalCase(t(tab.value))}
                icon={tab.icon}
                value={tab.value}
              />
            ))}
          </Tabs>

          <Box sx={{ mb: 5 }} />

          {CONTACTS_TABS.map(tabVal => {
            const isMatched = tabVal.value === tab;

            return (
              isMatched && <Box key={tabVal.value}>{tabVal.component}</Box>
            );
          })}
        </Card>
      </Box>
    </Page>
  );
}
