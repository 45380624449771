// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import ThemeSettings from './components/settings';
import { ChartStyle } from './components/chart';
import ScrollToTop from './components/ScrollToTop';
import { ProgressBarStyle } from './components/ProgressBar';
import MotionLazyContainer from './components/animate/MotionLazyContainer';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ConnectedAppsProvider } from 'contexts/Connectors';
const queryClient = new QueryClient();

// ----------------------------------------------------------------------

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ConnectedAppsProvider>
        <MotionLazyContainer>
          <ThemeProvider>
            <ThemeSettings>
              <ProgressBarStyle />
              <ChartStyle />
              <ScrollToTop />
              <Router />
            </ThemeSettings>
          </ThemeProvider>
        </MotionLazyContainer>
      </ConnectedAppsProvider>
    </QueryClientProvider>
  );
}
