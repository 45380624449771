import {
  Typography,
  TableContainer,
  Paper,
  Card,
  Container,
} from '@mui/material';
import { useWorkFlows } from 'api/ShoplineApiHooks';
import Page from 'components/Page';
import PartialPageSpinner from 'components/PartialPageSpinner';
import useConnector from 'hooks/useConnector';
import { useTranslation } from 'react-i18next';
import ListAndWorkflowsTable from './child/ListAndWorkflowsTable';

export default function WorkFlowDetails() {
  const { t } = useTranslation();
  const { sourceShopId, targetShopId } = useConnector();
  const { data, refetch } = useWorkFlows(sourceShopId, targetShopId);

  if (data === undefined) {
    return <PartialPageSpinner />;
  }

  const dataMap = data.data;

  return (
    <Page title={t('workflow')}>
      <Container maxWidth='xl' sx={{ padding: '5px' }}>
        <Card sx={{ padding: '5px' }}>
          <Typography
            variant='h3'
            sx={{
              ml: 6,
            }}
          >
            {t('workflow')}
          </Typography>
          <Typography
            variant='body2'
            sx={{
              ml: 6,
            }}
          >
            {t('workflow_line_1')}
          </Typography>

          <TableContainer component={Paper} sx={{ mt: 2, p: 3 }}>
            <ListAndWorkflowsTable
              dataMap={dataMap}
              type='workflow'
              sourceShopId={sourceShopId}
              targetShopId={targetShopId}
              refetch={() => refetch()}
            />
          </TableContainer>
        </Card>
      </Container>
    </Page>
  );
}
