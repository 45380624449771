import {
  CardHeader,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import { RHFSelect, RHFTextField } from 'components/hook-form';
import { TableHeadCustom } from 'components/table';
import { OrderStatusType } from 'pages/ShoplineType';
import { useTranslation } from 'react-i18next';

type Props = {
  orderStatus: OrderStatusType[];
};
const RFMSetting: React.FunctionComponent<Props> = ({ orderStatus }) => {
  const { t } = useTranslation();
  const TABLE_HEAD = [
    {
      id: 'score',
      label: t('score'),
      info: t('rating_for_rfm_segmentation'),
      width: '30%',
    },
    {
      id: 'recency',
      label: t('recency'),
      info: t('days_since_last_order'),
    },
    {
      id: 'frequency',
      label: t('frequency'),
      info: t('total_orders_placed'),
    },
    {
      id: 'monetary',
      label: t('monetary'),
      info: t('total_money_spent'),
    },
  ];

  const mapTableRow = () => {
    const rows: React.ReactNode[] = [];
    for (let i = 5; i > 0; i--) {
      rows.push(
        <TableRow key={i}>
          <TableCell align='left'>{i}</TableCell>
          <TableCell align='left'>
            {i === 1 || i === 5 ? (
              <RHFTextField
                label={i === 5 ? t('less_than') : t('more_than')}
                type='number'
                id={`recency${i}`}
                name={`score${i}[0]`}
              />
            ) : (
              <Stack spacing={2}>
                <RHFTextField
                  label={t('from')}
                  type='number'
                  name={`score${i}.from[0]`}
                />
                <RHFTextField
                  label={t('to')}
                  type='number'
                  name={`score${i}.to[0]`}
                />
              </Stack>
            )}
          </TableCell>
          <TableCell align='left'>
            {i === 1 || i === 5 ? (
              <RHFTextField
                label={i === 1 ? t('less_than') : t('more_than')}
                type='number'
                name={`score${i}[1]`}
              />
            ) : (
              <Stack spacing={2}>
                <RHFTextField
                  label={t('from')}
                  type='number'
                  name={`score${i}.from[1]`}
                />

                <RHFTextField
                  label={t('to')}
                  type='number'
                  name={`score${i}.to[1]`}
                />
              </Stack>
            )}
          </TableCell>
          <TableCell align='left'>
            {i === 1 || i === 5 ? (
              <RHFTextField
                label={i === 1 ? t('less_than') : t('more_than')}
                type='number'
                name={`score${i}[2]`}
              />
            ) : (
              <Stack spacing={2}>
                <RHFTextField
                  label={t('from')}
                  type='number'
                  name={`score${i}.from[2]`}
                />
                <RHFTextField
                  label={t('to')}
                  type='number'
                  name={`score${i}.to[2]`}
                />
              </Stack>
            )}
          </TableCell>
        </TableRow>
      );
    }

    return rows;
  };

  return (
    <>
      <CardHeader title={t('roi_setting')} />
      <Stack direction='row' alignItems='center' ml={3} mt={4} mb={5}>
        <Typography variant='subtitle2' sx={{ mr: 3 }}>
          {t('calculate_roi_for_the_selected_status')}
        </Typography>

        <RHFSelect name='calculateRoi' size='small' fullWidth={false}>
          {orderStatus.map((option, index) => (
            <option key={index} value={option.value}>
              {option.label}
            </option>
          ))}
        </RHFSelect>
      </Stack>
      <CardHeader title={t('rfm_setting')} sx={{ mb: 3 }} />
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 800, width: '80%' }} aria-label='simple table'>
          <TableHeadCustom headLabel={TABLE_HEAD} />
          <TableBody>{mapTableRow()}</TableBody>
          <TableHeadCustom headLabel={TABLE_HEAD} />
        </Table>
      </TableContainer>
    </>
  );
};

export default RFMSetting;
