import Contact from 'pages/Contact/Contact';
import Products from 'pages/Products/Products';
import Deals from 'pages/Deals/Deals';
import Settings from 'pages/settings/Settings';
import HistoryDataSync from 'pages/HistoryDataSync';
import Activity from 'pages/activity/Activity';
import Logs from 'pages/logs/Logs';
import Plans from 'pages/logs/Planlisting';
import WorkFlowDetails from 'pages/settings/WorkflowDetails';
import PlanDetail from 'pages/logs/PlanDetail';
import PaymentsContainer from 'pages/logs/PaymentsContainer';

export const shopLineRoutes = [
  {
    path: '',
    children: [
      {
        path: 'dashboard',
        index: true,
        element: <HistoryDataSync />,
      },
      {
        path: 'deals/:tab',
        index: true,
        element: <Deals />,
      },
      {
        path: 'contacts/:tab',
        index: true,
        element: <Contact />,
      },
      {
        path: 'products/:tab',
        index: true,
        element: <Products />,
      },
      {
        path: 'automation',
        index: true,
        element: <WorkFlowDetails />,
      },
      {
        path: 'settings',
        index: true,
        element: <Settings />,
      },
      {
        path: 'activities/:tab',
        index: true,
        element: <Activity />,
      },
      { path: 'dashboard', element: <HistoryDataSync /> },
      { path: 'logs', element: <Logs /> },
      { path: 'plan', element: <PlanDetail /> },
      { path: 'plan/plan-listing', element: <Plans /> },
      { path: 'plan/payment', element: <PaymentsContainer /> },
    ],
  },
];
