import { Card, LinearProgress, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

type CreditInformationProps = {
  total: number;
  available: number;
  done: number;
};

const CreditInformation = (props: CreditInformationProps) => {
  const { t } = useTranslation();

  return (
    <Card sx={{ padding: '24px' }} className='mt-[40px]'>
      <h2>{t('credit')}</h2>
      <Stack className='mt-[32px] flex flex-col gap-[24px]'>
        <LinearProgress
          variant='determinate'
          value={(props.done * 100) / props.total}
          color={`${
            (props.done * 100) / props.total < 90
              ? (props.done * 100) / props.total < 40
                ? 'success'
                : 'warning'
              : 'error'
          }`}
          sx={{
            backgroundColor: 'rgba(145, 158, 171, 0.16)',
            height: 10,
            borderRadius: '50px',
          }}
        />
      </Stack>
      <div className='mt-[40px] flex justify-between'>
        <div>
          <div className='flex flex-col items-center justify-center'>
            <div className='flex items-center gap-[10px]'>
              <div className='h-[12px] w-[12px] rounded-[4px] bg-[#FFC107] '></div>
              <div className='text-[#637381]'>{t('used_order')}</div>
            </div>
            <div className='text-[18px] font-bold'>{props.done}</div>
          </div>
        </div>
        <div>
          <div className='flex flex-col items-center justify-center'>
            <div className='flex items-center gap-[10px]'>
              <div className='h-[12px] w-[12px] rounded-[4px] bg-[#54D62C]'></div>
              <div className='text-[#637381]'>{t('available_order')}</div>
            </div>
            <div className='text-[18px] font-bold'>{props.available}</div>
          </div>
        </div>
        <div>
          <div className='flex flex-col items-center justify-center'>
            <div className='flex items-center gap-[10px]'>
              <div className='h-[12px] w-[12px] rounded-[4px] bg-[#0061FF]'></div>
              <div className='text-[#637381]'>{t('total_order')}</div>
            </div>
            <div className='text-[18px] font-bold'>{props.total}</div>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default CreditInformation;
