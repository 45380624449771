type InvoiceHistoryProps = {
  date: string;
  price: number;
  filetype: string;
};

const InvoiceHistory = (props: InvoiceHistoryProps) => {
  function formatDate(str: string) {
    const month = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    const date = new Date(str);

    return `${date.getDate()} ${month[date.getMonth()]} ${date.getFullYear()}`;
  }

  return (
    <div>
      <table className='w-full border-collapse'>
        <tbody className='mt-[20px] flex border-collapse flex-col gap-[16px]'>
          <tr className='flex w-full justify-between text-[14px] text-[#212B36]'>
            <td>{formatDate(props.date)}</td>
            <td>${props.price}.00</td>
            <td>
              <a href={props.filetype} className='text-[#0061FF] no-underline'>
                PDF
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default InvoiceHistory;
