import React, { useRef, useState, FunctionComponent, useEffect } from 'react';
import {
  Grid,
  Card,
  TextField,
  Select,
  MenuItem,
  TableBody,
  Table,
  TableRow,
  FormControl,
  InputLabel,
  TableCell,
  TableContainer,
  SelectChangeEvent,
  FormHelperText,
  Box,
  Typography,
} from '@mui/material';
import Page from 'components/Page';
import { useLocation, useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import axiosInstance from 'utils/axios';
import CardInput from './logs/CardInput';
import _ from 'lodash';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import usePortalId from 'hooks/usePortalId';
import { SHOPLINE_NAVIGATION } from 'routes/paths';
import {
  CONFIRM_PAYMENT,
  GET_START_SUBSCRIPTION,
  UPGRADE_SUBSCRIPTION,
} from 'api/ShoplineApiConstants';
import useConnector from 'hooks/useConnector';
import { useGetActivePlans } from 'api/ShoplineApiHooks';
const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const ContentStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(25, 0, 0),
}));

interface PaymentType {
  full_name: string;
  phone: string;
  email: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  plan_id: string;
}

const countries = [
  { code: 'AF', country: 'Afghanistan' },
  { code: 'AX', country: 'Åland Islands' },
  { code: 'AL', country: 'Albania' },
  { code: 'DZ', country: 'Algeria' },
  { code: 'AS', country: 'American Samoa' },
  { code: 'AD', country: 'Andorra' },
  { code: 'AO', country: 'Angola' },
  { code: 'AI', country: 'Anguilla' },
  { code: 'AQ', country: 'Antarctica' },
  { code: 'AG', country: 'Antigua and Barbuda' },
  { code: 'AR', country: 'Argentina' },
  { code: 'AM', country: 'Armenia' },
  { code: 'AW', country: 'Aruba' },
  { code: 'AU', country: 'Australia' },
  { code: 'AT', country: 'Austria' },
  { code: 'AZ', country: 'Azerbaijan' },
  { code: 'BS', country: 'Bahamas' },
  { code: 'BH', country: 'Bahrain' },
  { code: 'BD', country: 'Bangladesh' },
  { code: 'BB', country: 'Barbados' },
  { code: 'BY', country: 'Belarus' },
  { code: 'BE', country: 'Belgium' },
  { code: 'BZ', country: 'Belize' },
  { code: 'BJ', country: 'Benin' },
  { code: 'BM', country: 'Bermuda' },
  { code: 'BT', country: 'Bhutan' },
  { code: 'BO', country: 'Bolivia' },
  { code: 'BA', country: 'Bosnia and Herzegovina' },
  { code: 'BW', country: 'Botswana' },
  { code: 'BV', country: 'Bouvet Island' },
  { code: 'BR', country: 'Brazil' },
  { code: 'IO', country: 'British Indian Ocean Territory' },
  { code: 'VG', country: 'British Virgin Islands' },
  { code: 'BN', country: 'Brunei' },
  { code: 'BG', country: 'Bulgaria' },
  { code: 'BF', country: 'Burkina Faso' },
  { code: 'BI', country: 'Burundi' },
  { code: 'KH', country: 'Cambodia' },
  { code: 'CM', country: 'Cameroon' },
  { code: 'CA', country: 'Canada' },
  { code: 'CV', country: 'Cape Verde' },
  { code: 'KY', country: 'Cayman Islands' },
  { code: 'CF', country: 'Central African Republic' },
  { code: 'TD', country: 'Chad' },
  { code: 'CL', country: 'Chile' },
  { code: 'CN', country: 'China' },
  { code: 'CX', country: 'Christmas Island' },
  { code: 'CC', country: 'Cocos  Keeling  Islands' },
  { code: 'CO', country: 'Colombia' },
  { code: 'KM', country: 'Comoros' },
  { code: 'CG', country: 'Congo - Brazzaville' },
  { code: 'CD', country: 'Congo - Kinshasa' },
  { code: 'CK', country: 'Cook Islands' },
  { code: 'CR', country: 'Costa Rica' },
  { code: 'CI', country: 'Côte d’Ivoire' },
  { code: 'HR', country: 'Croatia' },
  { code: 'CU', country: 'Cuba' },
  { code: 'CY', country: 'Cyprus' },
  { code: 'CZ', country: 'Czech Republic' },
  { code: 'DK', country: 'Denmark' },
  { code: 'DJ', country: 'Djibouti' },
  { code: 'DM', country: 'Dominica' },
  { code: 'DO', country: 'Dominican Republic' },
  { code: 'EC', country: 'Ecuador' },
  { code: 'EG', country: 'Egypt' },
  { code: 'SV', country: 'El Salvador' },
  { code: 'GQ', country: 'Equatorial Guinea' },
  { code: 'ER', country: 'Eritrea' },
  { code: 'EE', country: 'Estonia' },
  { code: 'ET', country: 'Ethiopia' },
  { code: 'FK', country: 'Falkland Islands' },
  { code: 'FO', country: 'Faroe Islands' },
  { code: 'FJ', country: 'Fiji' },
  { code: 'FI', country: 'Finland' },
  { code: 'FR', country: 'France' },
  { code: 'GF', country: 'French Guiana' },
  { code: 'PF', country: 'French Polynesia' },
  { code: 'TF', country: 'French Southern Territories' },
  { code: 'GA', country: 'Gabon' },
  { code: 'GM', country: 'Gambia' },
  { code: 'GE', country: 'Georgia' },
  { code: 'DE', country: 'Germany' },
  { code: 'GH', country: 'Ghana' },
  { code: 'GI', country: 'Gibraltar' },
  { code: 'GR', country: 'Greece' },
  { code: 'GL', country: 'Greenland' },
  { code: 'GD', country: 'Grenada' },
  { code: 'GP', country: 'Guadeloupe' },
  { code: 'GU', country: 'Guam' },
  { code: 'GT', country: 'Guatemala' },
  { code: 'GG', country: 'Guernsey' },
  { code: 'GN', country: 'Guinea' },
  { code: 'GW', country: 'Guinea-Bissau' },
  { code: 'GY', country: 'Guyana' },
  { code: 'HT', country: 'Haiti' },
  { code: 'HM', country: 'Heard Island and McDonald Islands' },
  { code: 'HN', country: 'Honduras' },
  { code: 'HK', country: 'Hong Kong SAR China' },
  { code: 'HU', country: 'Hungary' },
  { code: 'IS', country: 'Iceland' },
  { code: 'IN', country: 'India' },
  { code: 'ID', country: 'Indonesia' },
  { code: 'IR', country: 'Iran' },
  { code: 'IQ', country: 'Iraq' },
  { code: 'IE', country: 'Ireland' },
  { code: 'IM', country: 'Isle of Man' },
  { code: 'IL', country: 'Israel' },
  { code: 'IT', country: 'Italy' },
  { code: 'JM', country: 'Jamaica' },
  { code: 'JP', country: 'Japan' },
  { code: 'JE', country: 'Jersey' },
  { code: 'JO', country: 'Jordan' },
  { code: 'KZ', country: 'Kazakhstan' },
  { code: 'KE', country: 'Kenya' },
  { code: 'KI', country: 'Kiribati' },
  { code: 'KW', country: 'Kuwait' },
  { code: 'KG', country: 'Kyrgyzstan' },
  { code: 'LA', country: 'Laos' },
  { code: 'LV', country: 'Latvia' },
  { code: 'LB', country: 'Lebanon' },
  { code: 'LS', country: 'Lesotho' },
  { code: 'LR', country: 'Liberia' },
  { code: 'LY', country: 'Libya' },
  { code: 'LI', country: 'Liechtenstein' },
  { code: 'LT', country: 'Lithuania' },
  { code: 'LU', country: 'Luxembourg' },
  { code: 'MO', country: 'Macau SAR China' },
  { code: 'MK', country: 'Macedonia' },
  { code: 'MG', country: 'Madagascar' },
  { code: 'MW', country: 'Malawi' },
  { code: 'MY', country: 'Malaysia' },
  { code: 'MV', country: 'Maldives' },
  { code: 'ML', country: 'Mali' },
  { code: 'MT', country: 'Malta' },
  { code: 'MH', country: 'Marshall Islands' },
  { code: 'MQ', country: 'Martinique' },
  { code: 'MR', country: 'Mauritania' },
  { code: 'MU', country: 'Mauritius' },
  { code: 'YT', country: 'Mayotte' },
  { code: 'MX', country: 'Mexico' },
  { code: 'FM', country: 'Micronesia' },
  { code: 'MD', country: 'Moldova' },
  { code: 'MC', country: 'Monaco' },
  { code: 'MN', country: 'Mongolia' },
  { code: 'ME', country: 'Montenegro' },
  { code: 'MS', country: 'Montserrat' },
  { code: 'MA', country: 'Morocco' },
  { code: 'MZ', country: 'Mozambique' },
  { code: 'MM', country: 'Myanmar  Burma ' },
  { code: 'NA', country: 'Namibia' },
  { code: 'NR', country: 'Nauru' },
  { code: 'NP', country: 'Nepal' },
  { code: 'NL', country: 'Netherlands' },
  { code: 'AN', country: 'Netherlands Antilles' },
  { code: 'NC', country: 'New Caledonia' },
  { code: 'NZ', country: 'New Zealand' },
  { code: 'NI', country: 'Nicaragua' },
  { code: 'NE', country: 'Niger' },
  { code: 'NG', country: 'Nigeria' },
  { code: 'NU', country: 'Niue' },
  { code: 'NF', country: 'Norfolk Island' },
  { code: 'MP', country: 'Northern Mariana Islands' },
  { code: 'KP', country: 'North Korea' },
  { code: 'NO', country: 'Norway' },
  { code: 'OM', country: 'Oman' },
  { code: 'PK', country: 'Pakistan' },
  { code: 'PW', country: 'Palau' },
  { code: 'PS', country: 'Palestinian Territories' },
  { code: 'PA', country: 'Panama' },
  { code: 'PG', country: 'Papua New Guinea' },
  { code: 'PY', country: 'Paraguay' },
  { code: 'PE', country: 'Peru' },
  { code: 'PH', country: 'Philippines' },
  { code: 'PN', country: 'Pitcairn Islands' },
  { code: 'PL', country: 'Poland' },
  { code: 'PT', country: 'Portugal' },
  { code: 'QA', country: 'Qatar' },
  { code: 'RE', country: 'Réunion' },
  { code: 'RO', country: 'Romania' },
  { code: 'RU', country: 'Russia' },
  { code: 'RW', country: 'Rwanda' },
  { code: 'BL', country: 'Saint Barthélemy' },
  { code: 'SH', country: 'Saint Helena' },
  { code: 'KN', country: 'Saint Kitts and Nevis' },
  { code: 'LC', country: 'Saint Lucia' },
  { code: 'MF', country: 'Saint Martin' },
  { code: 'PM', country: 'Saint Pierre and Miquelon' },
  { code: 'VC', country: 'Saint Vincent and the Grenadines' },
  { code: 'WS', country: 'Samoa' },
  { code: 'SM', country: 'San Marino' },
  { code: 'ST', country: 'São Tomé and Príncipe' },
  { code: 'SA', country: 'Saudi Arabia' },
  { code: 'SN', country: 'Senegal' },
  { code: 'RS', country: 'Serbia' },
  { code: 'SC', country: 'Seychelles' },
  { code: 'SL', country: 'Sierra Leone' },
  { code: 'SG', country: 'Singapore' },
  { code: 'SK', country: 'Slovakia' },
  { code: 'SI', country: 'Slovenia' },
  { code: 'SB', country: 'Solomon Islands' },
  { code: 'SO', country: 'Somalia' },
  { code: 'ZA', country: 'South Africa' },
  { code: 'GS', country: 'South Georgia and the South Sandwich Islands' },
  { code: 'KR', country: 'South Korea' },
  { code: 'ES', country: 'Spain' },
  { code: 'LK', country: 'Sri Lanka' },
  { code: 'SD', country: 'Sudan' },
  { code: 'SR', country: 'Suriname' },
  { code: 'SJ', country: 'Svalbard and Jan Mayen' },
  { code: 'SZ', country: 'Swaziland' },
  { code: 'SE', country: 'Sweden' },
  { code: 'CH', country: 'Switzerland' },
  { code: 'SY', country: 'Syria' },
  { code: 'TW', country: 'Taiwan' },
  { code: 'TJ', country: 'Tajikistan' },
  { code: 'TZ', country: 'Tanzania' },
  { code: 'TH', country: 'Thailand' },
  { code: 'TL', country: 'Timor-Leste' },
  { code: 'TG', country: 'Togo' },
  { code: 'TK', country: 'Tokelau' },
  { code: 'TO', country: 'Tonga' },
  { code: 'TT', country: 'Trinidad and Tobago' },
  { code: 'TN', country: 'Tunisia' },
  { code: 'TR', country: 'Turkey' },
  { code: 'TM', country: 'Turkmenistan' },
  { code: 'TC', country: 'Turks and Caicos Islands' },
  { code: 'TV', country: 'Tuvalu' },
  { code: 'UG', country: 'Uganda' },
  { code: 'UA', country: 'Ukraine' },
  { code: 'AE', country: 'United Arab Emirates' },
  { code: 'GB', country: 'United Kingdom' },
  { code: 'US', country: 'United States' },
  { code: 'UY', country: 'Uruguay' },
  { code: 'UM', country: 'U.S. Minor Outlying Islands' },
  { code: 'VI', country: 'U.S. Virgin Islands' },
  { code: 'UZ', country: 'Uzbekistan' },
  { code: 'VU', country: 'Vanuatu' },
  { code: 'VA', country: 'Vatican City' },
  { code: 'VE', country: 'Venezuela' },
  { code: 'VN', country: 'Vietnam' },
  { code: 'WF', country: 'Wallis and Futuna' },
  { code: 'EH', country: 'Western Sahara' },
  { code: 'YE', country: 'Yemen' },
  { code: 'ZM', country: 'Zambia' },
  { code: 'ZW', country: 'Zimbabwe' },
];

const initialErrorStateData = {
  full_name: '',
  phone: '',
  email: '',
  address: '',
  city: '',
  state: '',
  zip: '',
  country: '',
  amount: '',
  card: '',
};

export const Payments: FunctionComponent = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { sourceShopId, targetShopId } = useConnector();
  const location = useLocation();
  const navigate = useNavigate();
  const portalId = usePortalId();
  const stripe = useStripe();
  const elements = useElements();
  const { data } = useGetActivePlans(sourceShopId, targetShopId, true);
  const isErrorRef = useRef(false);
  const [stateError, setStateError] = useState(initialErrorStateData);
  const [cardError, setCardError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const paymentDetail = location?.state;
  const planId: string = location?.state.planId;
  const planType: string = location?.state.plan_type;
  const [payment, setPayment] = useState<PaymentType>({
    full_name: '',
    phone: '',
    email: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    country: 'select',
    plan_id: '',
  });
  const formDetails = data?.data?.user_details;

  useEffect(() => {
    if (formDetails !== undefined) {
      setPayment({
        full_name: formDetails.name,
        phone: formDetails.phone,
        email: formDetails.email,
        address: formDetails.address.line1,
        city: formDetails.address.city,
        state: formDetails.address.state,
        zip: formDetails.address.postal_code,
        country: formDetails.address.country,
        plan_id: '',
      });
    }
  }, [formDetails]);
  const handleChange = (
    e:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent
  ) => {
    setPayment({ ...payment, [e.target.name]: e.target.value });
  };

  const validateForm = (validateFormData: PaymentType) => {
    const validate = {
      full_name: (full_name: string) =>
        full_name.trim().length > 0 ? true : t('name_is_required'),
      phone: (phone: number) =>
        phone
          .toString()
          .trim()
          .match(
            /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*/
          )
          ? true
          : t('phone_number_not_valid'),
      email: (email: string) =>
        email
          .trim()
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          )
          ? true
          : t('email_must_be_a_valid_email_address'),
      address: (address: string) =>
        address.trim().length > 0 ? true : t('address_is_required'),
      city: (city: string) =>
        city.trim().length > 0 ? true : t('city_is_required'),
      state: (state: string) =>
        state.trim().length > 0 ? true : t('state_is_required'),
      zip: (zip: string) =>
        zip.trim().length > 0 ? true : t('zip_is_required'),
      country: (country: string) =>
        country !== 'select' ? true : t('country_is_required'),

      plan_id: () => true,
    };
    const errObj = {
      ...initialErrorStateData,
    };
    let errorFieldsVar = false;
    _.forIn(validateFormData, (value, key) => {
      const error = validate[key as keyof typeof validate](value as never);
      if (error !== true) {
        errorFieldsVar = true;
      }
      // @ts-ignore
      errObj[key] = error ?? '';
    });
    isErrorRef.current = errorFieldsVar;
    setStateError({ ...stateError, ...errObj });
  };

  const handleSubmitSub = async (event: any) => {
    validateForm(payment);
    if (!stripe || !elements || isErrorRef.current) {
      return;
    }
    const cardElement = elements.getElement(CardElement);
    if (cardElement !== null) {
      setIsSubmitting(true);
      const result = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
        billing_details: {
          address: {
            city: payment.city,
            country: payment.country,
            line1: payment.address,
            postal_code: payment.zip,
            state: payment.state,
          },
          email: payment.email,
          name: payment.full_name,
          phone: payment.phone,
        },
      });
      if (result.error) {
        setIsSubmitting(false);
        isErrorRef.current = true;
        setCardError(result.error.message ?? '');
      } else {
        try {
          setIsSubmitting(true);
          const res = await axiosInstance.post(
            `${
              location.state.subscribed === 'Free' ||
              location.state.subscribed === undefined
                ? GET_START_SUBSCRIPTION
                : UPGRADE_SUBSCRIPTION
            }`,
            {
              payment_method: result.paymentMethod.id,
              email: payment.email,
              plan_id: planId,
              plan_type: `${planType === 'month' ? 'monthly' : 'yearly'}`,
              user_details: {
                address: {
                  city: payment.city,
                  country: payment.country,
                  line1: payment.address,
                  postal_code: payment.zip,
                  state: payment.state,
                },
                email: payment.email,
                name: payment.full_name,
                phone: payment.phone,
              },
            },
            {
              headers: {
                'Ced-Source-Id': sourceShopId,
                'Ced-Target-Id': targetShopId,
                'Ced-Source-Name': 'shopline',
                appTag: 'shopline_hubspot',
              },
            }
          );
          // eslint-disable-next-line camelcase
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          const { client_secret, status } = res.data.data;
          if (status === 'requires_source_action') {
            setIsSubmitting(true);
            stripe
              .confirmCardPayment(client_secret)
              .then(async function (result) {
                if (result.error) {
                  setIsSubmitting(false);
                  enqueueSnackbar(t('issue_payment_failed'), {
                    variant: 'error',
                  });
                } else {
                  try {
                    setIsSubmitting(true);
                    await axiosInstance.post(
                      CONFIRM_PAYMENT,
                      {
                        payment_intent: result.paymentIntent,
                      },
                      {
                        headers: {
                          'Ced-Source-Id': sourceShopId,
                          'Ced-Target-Id': targetShopId,
                          'Ced-Source-Name': 'shopline',
                          appTag: 'shopline_hubspot',
                        },
                      }
                    );
                    enqueueSnackbar(
                      `${
                        location.state.subscribed === 'Free' ||
                        location.state.subscribed === undefined
                          ? t('payment_successful')
                          : t('plan_urgraded')
                      }`,
                      {
                        variant: 'success',
                      }
                    );
                    navigate(SHOPLINE_NAVIGATION.general.plan(portalId));
                    setIsSubmitting(false);
                  } catch (error: any) {
                    console.log(error);
                    enqueueSnackbar(t('issue_payment_failed'), {
                      variant: 'error',
                    });
                    setIsSubmitting(false);
                  }
                }
              });
          } else {
            enqueueSnackbar(
              `${
                location.state.subscribed === 'Free' ||
                location.state.subscribed === undefined
                  ? t('payment_successful')
                  : t('plan_urgraded')
              }`,
              {
                variant: 'success',
                autoHideDuration: 4000,
              }
            );
            setIsSubmitting(false);
            navigate(SHOPLINE_NAVIGATION.general.plan(portalId));
          }
          setIsSubmitting(false);
        } catch (error: any) {
          setIsSubmitting(false);
          enqueueSnackbar(error.message, {
            variant: 'error',
            autoHideDuration: 4000,
          });
        }
      }
    }
  };

  return (
    <Page title={t('payment')}>
      <div className='mx-[40px] ml-[64px]'>
        <Box>
          <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
              <div className='flex flex-col gap-[30px]'>
                <Card sx={{ p: 3 }}>
                  <Typography
                    sx={{ margin: '15px 0px 15px 0px' }}
                    variant='subtitle2'
                    color='text secondary'
                  >
                    {t('billing_details')}
                  </Typography>
                  <Box
                    sx={{
                      display: 'grid',
                      rowGap: 3,
                      columnGap: 2,
                      gridTemplateColumns: {
                        xs: 'repeat(1, 1fr)',
                        sm: 'repeat(2, 1fr)',
                      },
                    }}
                  >
                    <TextField
                      id='outlined-basic'
                      name='full_name'
                      label={t('full_name')}
                      variant='outlined'
                      value={payment.full_name}
                      onChange={handleChange}
                      error={stateError.full_name.length ? true : false}
                      helperText={stateError.full_name}
                    />
                    <TextField
                      id='outlined-basic'
                      type='number'
                      name='phone'
                      label={t('phone')}
                      variant='outlined'
                      value={payment.phone}
                      onChange={handleChange}
                      error={stateError.phone.length ? true : false}
                      helperText={stateError.phone}
                    />
                    <TextField
                      id='outlined-basic'
                      name='email'
                      label={t('email_address')}
                      variant='outlined'
                      value={payment.email}
                      onChange={handleChange}
                      error={stateError.email.length ? true : false}
                      helperText={stateError.email}
                    />
                    <TextField
                      id='outlined-basic'
                      name='address'
                      label={t('address')}
                      variant='outlined'
                      value={payment.address}
                      onChange={handleChange}
                      error={stateError.address.length ? true : false}
                      helperText={stateError.address}
                    />
                    <TextField
                      id='outlined-basic'
                      name='city'
                      label={t('city')}
                      variant='outlined'
                      value={payment.city}
                      onChange={handleChange}
                      error={stateError.city.length ? true : false}
                      helperText={stateError.city}
                    />
                    <TextField
                      id='outlined-basic'
                      name='state'
                      label={t('state')}
                      variant='outlined'
                      value={payment.state}
                      onChange={handleChange}
                      error={stateError.state.length ? true : false}
                      helperText={stateError.state}
                    />
                    <TextField
                      id='outlined-basic'
                      name='zip'
                      label={t('zip')}
                      variant='outlined'
                      value={payment.zip}
                      onChange={handleChange}
                      error={stateError.zip.length ? true : false}
                      helperText={stateError.zip}
                    />
                    <FormControl fullWidth>
                      <InputLabel id='demo-simple-select-label'>
                        {t('country')}
                      </InputLabel>
                      <Select
                        id='demo-simple-select-helper'
                        label={t('country')}
                        name='country'
                        value={payment.country}
                        onChange={handleChange}
                        error={stateError.country.length ? true : false}
                      >
                        <MenuItem key='select' value={'select'}>
                          {t('select_country')}
                        </MenuItem>
                        {_.map(countries, function (value, key) {
                          return (
                            <MenuItem key={key} value={value.code}>
                              {value.country}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      {stateError.country.length > 0 && (
                        <FormHelperText error={true}>
                          {stateError.country}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Box>
                </Card>
                <Card sx={{ p: 3 }}>
                  <Typography
                    sx={{ margin: '15px 0px 15px 0px' }}
                    variant='subtitle2'
                    color='text secondary'
                  >
                    {t('payment_info')}
                  </Typography>

                  <CardInput />
                  {cardError.length > 0 && (
                    <FormHelperText error={true}>{cardError}</FormHelperText>
                  )}
                </Card>
              </div>
            </Grid>
            <Grid item xs={12} md={4}>
              <div className='flex flex-col gap-[30px]'>
                <Card sx={{ p: 3 }}>
                  <div className='flex gap-[8px]'>
                    <span className='font-bold'>Plan :</span>
                    <span className='font-bold text-[#0061FF]'>
                      {paymentDetail.plan}
                    </span>
                    <button
                      className='cursor-pointer border-none bg-[#FFFFFF] font-bold text-[#637381]'
                      onClick={() =>
                        navigate(
                          `${SHOPLINE_NAVIGATION.general.plan(
                            portalId
                          )}/plan-listing`,
                          {
                            state: { title: location.state.subscribed },
                          }
                        )
                      }
                    >
                      (Change)
                    </button>
                  </div>
                  <div className='mt-[40px] flex items-center justify-between'>
                    <div>
                      <span className='text-[20px] font-bold'>
                        ${paymentDetail.amount}
                      </span>
                      <span>/{paymentDetail.plan_type}</span>
                    </div>
                    <span className='text-[12px] text-[#229A16]'>
                      {paymentDetail.monthly_price * 12 -
                        paymentDetail.yearly_price !==
                        0 && paymentDetail.plan_type === 'year' ? (
                        <>
                          {t('saving_a_year', {
                            value:
                              paymentDetail.monthly_price * 12 -
                              paymentDetail.yearly_price,
                          })}
                        </>
                      ) : null}{' '}
                    </span>
                  </div>
                </Card>
                <Card sx={{ p: 3 }}>
                  <Typography
                    sx={{ margin: '15px 0px 15px 0px' }}
                    variant='subtitle2'
                    color='text secondary'
                  >
                    {t('order_summary')}
                  </Typography>
                  <TableContainer>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell>
                            <Typography variant='subtitle2' color='#637381'>
                              {t('sub_total')}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              color='#212B36'
                              sx={{
                                display: 'flex',
                                flexDirection: 'row-reverse',
                              }}
                              variant='subtitle2'
                            >
                              ${paymentDetail.amount}
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <Typography variant='subtitle2' color='#637381'>
                              {t('discount')}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              color='#212B36'
                              sx={{
                                display: 'flex',
                                flexDirection: 'row-reverse',
                              }}
                              variant='subtitle2'
                            >
                              -
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow sx={{ borderTop: '1px solid #919EAB' }}>
                          <TableCell>
                            <Typography variant='h5'>Total</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              color='#212B36'
                              sx={{
                                display: 'flex',
                                flexDirection: 'row-reverse',
                                color: '#0061FF',
                              }}
                              variant='subtitle2'
                            >
                              ${paymentDetail.amount}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Card>
                <Card sx={{ mb: 3 }}>
                  <div className='save-button'>
                    <LoadingButton
                      fullWidth
                      loading={isSubmitting}
                      variant='contained'
                      onClick={handleSubmitSub}
                    >
                      {t('complete_payment')}
                    </LoadingButton>
                  </div>
                </Card>
              </div>
            </Grid>
          </Grid>
        </Box>
      </div>
    </Page>
  );
};
