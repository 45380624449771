import { useDealStageMapping } from 'api/ShoplineApiHooks';
import useConnector from 'hooks/useConnector';
import DealStageMap from './child/DealStageMap';
import PartialPageSpinner from 'components/PartialPageSpinner';
import { SelectedObjectType } from 'pages/ShoplineType';

export default function DealStageMapping() {
  const { sourceShopId, targetShopId } = useConnector();
  const { data } = useDealStageMapping(sourceShopId, targetShopId);
  if (data === undefined || !data?.success) {
    return <PartialPageSpinner />;
  }
  const pipelineData = data?.data?.pipeline_mapping;
  const pipelineOptions = pipelineData?.pipelines;
  const titles = pipelineData?.source;
  const target = pipelineData?.target;
  const defaultPipeline = pipelineData?.saved?.pipeline;
  const defaultValues = pipelineData?.saved?.mapping;
  const stateObject: SelectedObjectType[] = [];
  const newTitleArray = titles.map(elem =>
    elem.replace(' ', '_').toLowerCase()
  );

  newTitleArray.map(
    (newTitle: string, index: number) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      stateObject[newTitle] = {
        name: titles[index],
        value: defaultValues[index],
      };

      return stateObject;
    },
    [newTitleArray]
  );

  return (
    <DealStageMap
      disable={false}
      targets={target}
      pipeline={pipelineOptions}
      defaultValues={defaultValues}
      stateObject={stateObject}
      savedPipeline={defaultPipeline}
    />
  );
}
