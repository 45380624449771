import {
  Box,
  Card,
  Container,
  Grid,
  Paper,
  Table,
  TableContainer,
  TableBody,
  Pagination,
} from '@mui/material';
import { TableHeadCustom, TableNoData } from 'components/table';
import { useTranslation } from 'react-i18next';
import Page from '../../../components/Page';
import PartialPageSpinner from 'components/PartialPageSpinner';
import Scrollbar from 'components/Scrollbar';
import { FieldMappings, FieldMappingType } from 'pages/ShoplineType';
import useConnector from 'hooks/useConnector';
import _ from 'lodash';
import { requestGetDealFieldMapping } from 'api/ShoplineApiHooks';
import DealMappingTableRow from './child/DealMappingTableRow';
import {
  DEAL_FIELD_MAPPING_API_URL,
  ROWS_PER_PAGE,
} from 'api/ShoplineApiConstants';
import { useState } from 'react';
import { useQuery } from 'react-query';

export default function DealFieldMapping() {
  const { t } = useTranslation();
  const { sourceShopId, targetShopId } = useConnector();
  const [page, setPage] = useState(1);
  const TABLE_HEAD = [
    { id: 'Shopline', label: t('Shopline'), align: 'left' },
    { id: '', label: '', align: 'center' },
    { id: 'Hubspot', label: t('Hubspot'), align: 'left' },
    { id: 'status', label: t('status'), align: 'center' },
    { id: 'action', label: '' },
  ];
  const limit = ROWS_PER_PAGE;
  const { data, isLoading } = useQuery<FieldMappingType>(
    [DEAL_FIELD_MAPPING_API_URL, page, limit],
    () => requestGetDealFieldMapping(sourceShopId, targetShopId, page, limit)
  );
  if (data === undefined) {
    return <PartialPageSpinner />;
  }
  let contentLoad: React.ReactNode = null;

  const mapPages = (data: FieldMappings[]) => {
    const rows: React.ReactNode[] = [];
    data.forEach((row, index) => {
      rows.push(<DealMappingTableRow key={index} row={row} />);
    });

    return rows;
  };

  const products = data?.data;
  const totalCount = data?.totalCount;
  const totalPage = Math.ceil(totalCount / limit);

  if (products) {
    contentLoad = products.length ? (
      <TableBody>{mapPages(products)}</TableBody>
    ) : (
      <>
        <TableNoData isNotFound={true} Message={t('deal_mapping')} />
      </>
    );
  }

  return (
    <Page title={t('deals')}>
      {/* <Container maxWidth='xl' sx={{ padding: '5px' }}> */}
      {/* <Box sx={{ flexGrow: 1 }} /> */}
      <Card sx={{ mx: 4 }}>
        <Grid container sx={{ p: 1 }} direction='row'></Grid>
        {isLoading ? (
          <PartialPageSpinner />
        ) : (
          <>
            <Scrollbar>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 800 }} aria-label='simple table'>
                  <TableHeadCustom headLabel={TABLE_HEAD} />
                  {contentLoad}
                </Table>
              </TableContainer>
            </Scrollbar>
          </>
        )}
      </Card>
      <Card
        sx={{
          display: 'flex',
          paddingBottom: '25px',
          justifyContent: 'center',
          paddingTop: '10px',
        }}
      >
        {totalCount > limit ? (
          <Pagination
            color='primary'
            variant='outlined'
            count={totalPage}
            page={page}
            onChange={(e, pageNo) => setPage(pageNo)}
          />
        ) : (
          ''
        )}
      </Card>
      {/* </Container> */}
    </Page>
  );
}
