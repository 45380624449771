import { LoadingButton } from '@mui/lab';
import {
  Box,
  CardHeader,
  FormControl,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from '@mui/material';
import { SAVE_SETTINGS_API_URL } from 'api/ShoplineApiConstants';
import useConnector from 'hooks/useConnector';
import useResponsive from 'hooks/useResponsive';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import { PipelineOption, SelectedObjectType } from 'pages/ShoplineType';
import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axiosInstance from 'utils/axios';
import { capitalizeFirstLetter } from 'utils/letterChange';

type Props = {
  disable: boolean;
  stateObject: SelectedObjectType[];
  targets: { [key: string]: string };
  pipeline: PipelineOption[];
  defaultValues: { [key: number]: string };
  savedPipeline: string;
};

const DealStageMap: FunctionComponent<Props> = ({
  disable,
  stateObject,
  targets,
  pipeline,
  savedPipeline,
}) => {
  const smUp = useResponsive('up', 'sm');
  const { t } = useTranslation();
  let defaultId = '';
  let defaultStages = {};
  _.forEach(pipeline, function (target) {
    if (target.id === savedPipeline) {
      defaultId = target.id;
      defaultStages = target.stages;
    }
  });

  const { sourceShopId, targetShopId } = useConnector();
  const [stateSource, setSources] = useState(
    Object.fromEntries(Object.entries(stateObject))
  );
  const [deafultTargets, setDeafultTargets] = useState(defaultStages);
  const [pipelineState, setPipelineState] = useState(defaultId);
  const [defaultValues, setDefaultValues] = useState(
    Object.fromEntries(Object.entries(stateObject))
  );

  const handlePipelineChange = (
    e:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent
  ) => {
    _.forEach(pipeline, function (pipe) {
      if (pipe.id === e.target.value) {
        setDeafultTargets(pipe.stages);
        setPipelineState(e.target.value);
        const valueToSet = Object.keys(pipe.stages)[0];
        const defValues = defaultValues;
        for (const i in defValues) {
          defValues[i].value = valueToSet;
        }
        setDefaultValues(defValues);
      }
    });
  };

  const handleSubmitMapping = async () => {
    const mapping = [];
    for (const prop in stateSource) {
      mapping.push(stateSource[prop].value);
    }
    try {
      await axiosInstance
        .post(SAVE_SETTINGS_API_URL, {
          headers: {
            'Ced-Source-Id': sourceShopId,
            'Ced-Target-Id': targetShopId,
          },
          targetShopId: targetShopId,
          sourceShopId: sourceShopId,
          pipeline_mapping: {
            mapping: mapping,
            pipeline: pipelineState,
          },
        })
        .then(result => {
          if (result.data.success) {
            enqueueSnackbar(capitalizeFirstLetter(result.data.message), {
              variant: 'success',
            });
          } else {
            enqueueSnackbar(capitalizeFirstLetter(result.data.message), {
              variant: 'error',
            });
          }
        });
    } catch (error: any) {
      enqueueSnackbar(capitalizeFirstLetter(error.message), {
        variant: 'error',
      });
    }
  };

  const handleResetMapping = () => {
    setSources(defaultValues);
  };

  const handleChange = (
    e:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent
  ) => {
    const copy = {
      ...stateSource,
      [e.target.name]: {
        name: stateSource[e.target.name].name,
        value: e.target.value,
      },
    };
    setSources(copy);
  };
  const { enqueueSnackbar } = useSnackbar();
  const mapFields = () => {
    return _.map(stateSource, (source, key) => (
      <Stack direction='row' key={key}>
        <Grid container spacing={2} sx={{ width: '40%' }}>
          <Grid item xs={8}>
            <Typography
              variant='h6'
              color={'black'}
              sx={{ mb: 3, py: 1, justifyContent: 'center' }}
              noWrap
            >
              {source.name}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <FormControl sx={{ width: '250px' }}>
              <Select
                labelId='demo-simple-select-label'
                name={key}
                //@ts-ignore
                value={source.value}
                onChange={handleChange}
              >
                {_.map(deafultTargets, function (stage, key) {
                  return (
                    <MenuItem key={key} value={key}>
                      {stage}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Stack>
    ));
  };

  return (
    <Grid item xs={12} md={6} lg={8}>
      <Stack spacing={3}>
        <CardHeader
          sx={{
            display: 'flex',
            flexDirection: smUp ? null : 'column',
          }}
          title={t('sync_order_status_with_deal_stages')}
          subheader={t('sync_order_status_line_1')}
          action={
            <FormControl sx={{ width: '250px' }}>
              <Select
                labelId='demo-simple-select-label'
                name={'pipeline'}
                value={pipelineState}
                //@ts-ignore
                onChange={handlePipelineChange}
              >
                {_.map(pipeline, function (target, key) {
                  return (
                    <MenuItem key={target.id} value={target.id}>
                      {target.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          }
        />
      </Stack>
      <Grid container spacing={3} sx={{ p: 5 }}>
        <Grid item xs={12} md={12}>
          <Box
            sx={{
              display: 'grid',
              rowGap: 2,
              columnGap: 8,
              gridTemplateColumns: {
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)',
              },
            }}
          >
            {mapFields()}
          </Box>
          <Stack
            sx={{
              p: 5,
              justifyContent: 'center',
              margin: '0 auto',
              display: 'flex',
              flexDirection: 'row',
              padding: '40px 0',
            }}
          >
            <Stack sx={{ pr: '10px' }}>
              <LoadingButton
                type='submit'
                variant='contained'
                onClick={handleSubmitMapping}
              >
                {t('save_mapping')}
              </LoadingButton>
            </Stack>
            <Stack>
              <LoadingButton
                type='submit'
                variant='outlined'
                onClick={handleResetMapping}
              >
                {t('reset_to_default_mapping')}
              </LoadingButton>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default DealStageMap;
