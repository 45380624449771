import { ONBOARD_USER_LOGIN } from 'api/ShoplineApiConstants';
import { getAllConnectedAppsData } from 'api/ShoplineApiHooks';
import axios from 'axios';
import LoadingScreen from 'components/LoadingScreen';
import useAuth from 'hooks/useAuth';
import { useSnackbar } from 'notistack';
import { ReactNode, createContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { PATH_PAGE, SHOPLINE_NAVIGATION } from 'routes/paths';
import { setSession } from 'utils/jwt';
import { capitalizeFirstLetter } from 'utils/letterChange';
import Client, { shared, Redirect } from '@shoplinedev/appbridge';

export type ConnectedAppsContextProps = {
  sourceShopId: number;
  targetShopId: number;
  hubspotOnboarded: boolean;
  checkHubspot: boolean;
  portalId: number;
  loaded: boolean;
  refetchConnectedAddData?: () => void;
};

export type HubspotInstalled = {
  _id: number;
  onboarded?: boolean;
  hub_id: number;
  is_connected: boolean;
  hub_domain: string;
};

export type ShoplineInstalled = {
  _id: number;
};

export type ConnectedAppsDataType = {
  success: boolean;
  message: string;
  code?: string;
  ip?: string;
  data: {
    shopline: {
      installed: ShoplineInstalled[];
    };
    hubspot: {
      installed: HubspotInstalled[];
    };
  };
};

const initialState: ConnectedAppsContextProps = {
  sourceShopId: 0,
  targetShopId: 0,
  hubspotOnboarded: false,
  checkHubspot: false,
  portalId: 0,
  loaded: false,
};

const ConnectedAppsContext = createContext(initialState);

type ConnectedAppsProviderProps = {
  children: ReactNode;
};

function ConnectedAppsProvider({ children }: ConnectedAppsProviderProps) {
  const [marketplace, setMarketplace] = useState({
    shoplineSourceShopId: 0,
    hubspotTargetShopId: 0,
    portalId: 0,
    checkHubspot: true,
    hubspotOnboarded: false,
    loaded: false,
  });
  const { isAuthenticated } = useAuth();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const appkey: any = searchParams.get('appkey');
  const handle: any =
    searchParams.get('handle') || sessionStorage.getItem('handle');
  const lang = searchParams.get('lang');
  const timestamp = searchParams.get('timestamp');
  const sign = searchParams.get('sign');
  const adminUserToken = searchParams.get('admin_user_token');

  useEffect(() => {
    const onBoard = async () => {
      setLoading(true);
      sessionStorage.setItem('handle', handle);
      try {
        const res = await axios.post(ONBOARD_USER_LOGIN, {
          appkey: appkey,
          handle: handle,
          lang: lang,
          timestamp: timestamp,
          sign: sign,
        });
        if (res.data.success) {
          setSession(res.data.data.token);
          setLoading(false);
          navigate(SHOPLINE_NAVIGATION.login(res.data.data.token || ''));
        } else {
          if (res.data.data?.url) {
            const app = Client.createApp({
              appKey: appkey,
              host: shared.getHost(),
            });

            const redirect = Redirect.create(app);
            // Redirect
            redirect.replaceTo(res.data.data.url);
          } else {
            setLoading(false);
            navigate(PATH_PAGE.page404);
          }
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
        navigate(PATH_PAGE.page404);
      }
    };

    if (adminUserToken) {
      setSession(adminUserToken);
      navigate(SHOPLINE_NAVIGATION.login(adminUserToken || ''));
    } else {
      onBoard();
    }
  }, []);

  const mutation = useMutation(
    () =>
      getAllConnectedAppsData(
        (adminUserToken || window.sessionStorage.getItem('accessToken')) ?? ''
      ),
    {
      onError: () => {
        enqueueSnackbar(capitalizeFirstLetter(t('something_went_wrong')), {
          variant: 'error',
        });
      },
      onSuccess: data => {
        if (data.success) {
          const shoplineLength = data.data.shopline.installed.length;
          const hubspotLength = data.data.hubspot.installed.length;
          const hubspotcheck = hubspotLength > 0 ? true : false;
          if (shoplineLength) {
            const sourceId = data.data.shopline.installed[shoplineLength - 1];
            const targetId = data.data.hubspot?.installed.filter(
              connected => connected.is_connected
            );
            const portalDetails = targetId[0]?.hub_id;
            setMarketplace({
              shoplineSourceShopId: sourceId?._id,
              hubspotTargetShopId: targetId[0]?._id,
              checkHubspot: hubspotcheck,
              hubspotOnboarded: hubspotLength
                ? targetId[0]?.onboarded ?? false
                : false,
              portalId: portalDetails,
              loaded: true,
            });
          }
        } else {
          enqueueSnackbar(capitalizeFirstLetter(data.message), {
            variant: 'error',
          });
        }
      },
    }
  );

  useEffect(() => {
    isAuthenticated && mutation.mutate();
  }, [isAuthenticated]);

  if (loading || mutation.isLoading) {
    return <LoadingScreen />;
  }

  return (
    <ConnectedAppsContext.Provider
      value={{
        sourceShopId: marketplace.shoplineSourceShopId,
        targetShopId: marketplace.hubspotTargetShopId,
        checkHubspot: marketplace.checkHubspot,
        portalId: marketplace.portalId,
        hubspotOnboarded: marketplace.hubspotOnboarded,
        loaded: marketplace.loaded,
        refetchConnectedAddData: mutation.mutate,
      }}
    >
      {children}
    </ConnectedAppsContext.Provider>
  );
}

export { ConnectedAppsProvider, ConnectedAppsContext };
