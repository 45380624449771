import { LoadingButton } from '@mui/lab';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  Button,
  TableBody,
  Tooltip,
} from '@mui/material';

import {
  CREATE_LIST_API_URL,
  CREATE_WORKFLOW_API_URL,
} from 'api/ShoplineApiConstants';
import { useSnackbar } from 'notistack';
import { List } from 'pages/ShoplineType';
import { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axiosInstance from 'utils/axios';
import { capitalizeFirstLetter } from 'utils/letterChange';

type Props = {
  dataMap: List[];
  type: 'list' | 'workflow';
  sourceShopId: number;
  targetShopId: number;
  refetch: () => void;
};

const ListAndWorkflowsTable: FunctionComponent<Props> = ({
  dataMap,
  type,
  sourceShopId,
  targetShopId,
  refetch,
}) => {
  const { t } = useTranslation();
  const [buttonLoading, setButtonLoading] = useState('');
  const [preventFetch, setPreventFetch] = useState<boolean>(true);

  const { enqueueSnackbar } = useSnackbar();

  const createListAndWorkflows = async (type: string, name: string) => {
    let url = '';
    let params = {};
    if (type === 'list') {
      url = CREATE_LIST_API_URL;
      params = {
        sourceShopId,
        targetShopId,
        list: name,
      };
    } else {
      url = CREATE_WORKFLOW_API_URL;
      params = {
        sourceShopId,
        targetShopId,
        workflow: name,
      };
    }
    try {
      if (!preventFetch) {
        enqueueSnackbar(t('previous_proccess_in_progress'), {
          variant: 'warning',
          autoHideDuration: 3000,
        });
      }
      if (preventFetch) {
        setButtonLoading(name);
        setPreventFetch(false);
        const response = await axiosInstance.post(url, params, {
          headers: {
            'Ced-Source-Id': sourceShopId,
            'Ced-Target-Id': targetShopId,
          },
        });

        if (response.data.success) {
          setPreventFetch(true);
          enqueueSnackbar(capitalizeFirstLetter(response.data.message), {
            variant: 'success',
          });
        } else {
          setPreventFetch(true);
          enqueueSnackbar(capitalizeFirstLetter(response.data.message), {
            variant: 'error',
          });
        }
        setButtonLoading('');
        refetch();
      }
    } catch (error: any) {
      setButtonLoading('');
      enqueueSnackbar(capitalizeFirstLetter(error.message), {
        variant: 'error',
      });
    }
  };
  const wSize1 = type === 'workflow' ? '85%' : '90%';
  const wSize = type === 'workflow' ? '15%' : '10%';

  return (
    <Table sx={{ minWidth: 800 }} aria-label='simple table'>
      <TableHead>
        <TableRow>
          <TableCell align='left'>{t(type)}</TableCell>
          <TableCell align='center'>{t('status')}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {dataMap?.map((data, index) => {
          return (
            <TableRow key={index}>
              <TableCell align='left' width={wSize1}>
                <Typography variant='subtitle2'>{data.name}</Typography>
                <Typography variant='body2'>{data.desc}</Typography>
              </TableCell>
              <TableCell align='center' width={wSize}>
                {data.created ? (
                  <Button disabled variant='outlined'>
                    {t('created')}
                  </Button>
                ) : (
                  <Tooltip
                    title={
                      index > 4
                        ? !dataMap[4].created
                          ? 'Please create above fields first'
                          : ''
                        : ''
                    }
                    placement='bottom'
                    arrow
                  >
                    <span className='cursor-pointer'>
                      <LoadingButton
                        onClick={() => createListAndWorkflows(type, data.name)}
                        variant='outlined'
                        disabled={
                          index < 5 ? false : dataMap[4].created ? false : true
                        }
                        loading={buttonLoading === data.name}
                      >
                        {t('create')}
                      </LoadingButton>
                    </span>
                  </Tooltip>
                )}
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

export default ListAndWorkflowsTable;
