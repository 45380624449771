import React, { FunctionComponent, useState } from 'react';
import {
  Card,
  Typography,
  Stack,
  Switch,
  Accordion,
  AccordionSummary,
  Button,
  CardHeader,
  Divider,
  AccordionDetails,
} from '@mui/material';
import useResponsive from 'hooks/useResponsive';
import { index } from 'd3-array';
import { useTranslation } from 'react-i18next';
import { Settings } from 'pages/ShoplineType';
import { SAVE_SETTINGS_API_URL } from 'api/ShoplineApiConstants';
import { useSnackbar } from 'notistack';
import useConnector from 'hooks/useConnector';
import axiosInstance from 'utils/axios';
import Iconify from 'components/Iconify';
import { capitalizeFirstLetter } from 'utils/letterChange';

type Props = {
  settings: Settings;
};
const SettingsCard: FunctionComponent<Props> = ({ settings }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { sourceShopId, targetShopId } = useConnector();
  const smUp = useResponsive('up', 'sm');
  const [expanded, setExpanded] = useState({
    generalSettings: true,
  });
  const [toggleSettings, setToggleSettings] = useState({
    abandoncart_sync: settings.abandoncart_setting.abandoncart_sync,
    // hs_tracking_script: settings.hs_tracking_script,
    // contacts_two_way_sync: settings.contacts_two_way_sync,
    contact_tags_two_way_sync: settings.contact_tags_two_way_sync,
  });

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded({ ...expanded, [panel]: isExpanded });
    };

  const changeSettings = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setToggleSettings({
      ...toggleSettings,
      [event.target.name]: event.target.checked,
    });

    try {
      const response = await axiosInstance.post(SAVE_SETTINGS_API_URL, {
        targetShopId: targetShopId,
        sourceShopId: sourceShopId,
        [event.target.name]: event.target.checked,
        headers: {
          'Ced-Source-Id': sourceShopId,
          'Ced-Target-Id': targetShopId,
        },
      });
      if (response.data.success) {
        enqueueSnackbar(capitalizeFirstLetter(response.data.message), {
          variant: 'success',
        });
      } else {
        enqueueSnackbar(capitalizeFirstLetter(response.data.message), {
          variant: 'error',
        });
      }
    } catch (err: any) {
      enqueueSnackbar(capitalizeFirstLetter(err.message), {
        variant: 'error',
      });
    }
  };

  return (
    <Accordion
      expanded={expanded.generalSettings}
      sx={{ m: smUp ? 5 : 0, ml: smUp ? 8 : 0 }}
      onChange={handleChange('generalSettings')}
      square={true}
      key={'index'}
      disableGutters
    >
      <AccordionSummary
        expandIcon={
          <Button
            variant='outlined'
            endIcon={
              <Iconify
                icon={
                  expanded.generalSettings ? 'ep:arrow-up' : 'ep:arrow-down'
                }
              />
            }
            sx={{ pointerEvents: 'auto' }}
          >
            {t('manage')}
          </Button>
        }
        aria-controls={`panel${'index'}-content`}
        id={`panel${index}-header`}
        sx={{
          pointerEvents: 'none',
          pl: 1,
          pr: 2,
          '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
            transform: 'rotate(0deg)',
          },
        }}
      >
        <Stack flexDirection='column'>
          <CardHeader
            title={t('general_setting')}
            subheader={t('all_sync_related_settings')}
          />
        </Stack>
      </AccordionSummary>
      <Divider variant='middle' />
      <AccordionDetails sx={{ m: 1 }}>
        <Stack
          sx={{
            display: 'flex',
            flexDirection: 'row',
            padding: '5px',
          }}
        >
          <Typography variant='body1' sx={{ paddingTop: '5px', width: '25%' }}>
            {t('enable_two_way_sync_for_contact_tags')}
          </Typography>
          <Stack sx={{ paddingLeft: '150px' }}>
            <Switch
              name='contact_tags_two_way_sync'
              checked={toggleSettings.contact_tags_two_way_sync}
              onChange={changeSettings}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          </Stack>
        </Stack>
        <Stack
          sx={{
            display: 'flex',
            flexDirection: 'row',
            padding: '5px',
          }}
        >
          <Typography variant='body1' sx={{ paddingTop: '5px', width: '25%' }}>
            {t('enable_abondoned_cart_sync')}
          </Typography>
          <Stack sx={{ paddingLeft: '150px' }}>
            <Switch
              name='abandoncart_sync'
              checked={toggleSettings.abandoncart_sync}
              onChange={changeSettings}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          </Stack>
        </Stack>
        {/* <Stack
          sx={{
            display: 'flex',
            flexDirection: 'row',
            padding: '5px',
          }}
        >
          <Typography variant='body1' sx={{ paddingTop: '5px', width: '25%' }}>
            {t('enable_hubspot_tracking_script')}
          </Typography>
          <Stack sx={{ paddingLeft: '150px' }}>
            <Switch
              name='hs_tracking_script'
              checked={toggleSettings.hs_tracking_script}
              onChange={changeSettings}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          </Stack>
        </Stack> */}
      </AccordionDetails>
    </Accordion>
  );
};

export default SettingsCard;
