const en = {
  shopline_integration: 'Shopline Integration',
  getting_started_with_shopline_hubspot_integration:
    'Getting started with HubSpot and Shopline',
  with_shopline_hubspot_integration_you_can_sync_shopline_contact_customer_with_hubspot_crm:
    'With this Shopline HubSpot integration, you can automatically sync all your Shopline contacts and customers with HubSpot’s CRM and marketing platform.',
  once_set_up_integration_you_will_be_able_to:
    'Once you set up this integration, you will be able to',
  see_every_action_contact_including_page_view_past_orders_abondoned_card_and_more:
    'See every action each contact has taken including their page views, past orders, abandoned cards and more - in HubSpot crm tidy timeline view',
  segment_contact_customers_list_based_previous_interactions_with_store:
    'Segment contacts and customers into lists based on their previous interactions with your store',
  easily_create_and_send_beautiful_responsive_emails_to_drive_sales:
    'Easily create and send beautiful, responsive emails to drive sales',
  measure_store_performance_with_customer_reports_and__dashboards:
    'Measure your store’s performance with custom reports and dashboards',
  to_get_started_connect_hubspot_account_dont_have_account_create_one_then_return_to_this_window_to_connect:
    'To get started, connect your HubSpot account. If you don’t have a HubSpot account, create one then return to this window to connect it.',
  connect_your_account: 'Connect your account',
  create_free_hubspot_account: 'Create a free HubSpot account',
  redirecting_to_hubspot_account_page: 'Redirecting to HubSpot account page',
  lorem_ipsum:
    'Lorem ipsum dolor sit ametLorem ipsum dolor sit amet, consectetur adipiscing elit. A, ut ipsum lectus purus.Lorem ipsum dolor sit amet, consectetur adipiscing elit. A, ut ipsum lectus purus. ',
  hubspot_integration: 'HubSpot Integration',
  by_makewebbetter: 'by MakeWebBetter',
  please_wait_while_we_setup_your_account:
    'Please wait... while we are setup your account',
  waiting_screen_description: 'There is no pain in any of the great works.....',
  starter_plan: 'STARTER PLAN',
  saving_24_per_year: 'Saving $24/year',
  saving_49_per_year: 'Saving $49/year',
  months_updates: '12 months updates',
  months_of_support: '6 months of support',
  javascript_version: 'JavaScript version',
  typescript_version: 'TypeScript version',
  pro_plan: 'PRO PLAN',
  design_resources: 'Design Resources',
  commercial_applications: 'Commercial applications',
  choose_plan: 'Choose Plan',
  selected_plan_for_your_bussiness: 'Select plan for your business',
  need_any_help: 'Need Any Help ?',
  import_from_shopline: 'Import Data from Shopline',
  import_all_the_existing_data_from_your_store:
    'Import all the existing data from your store.',
  choose_the_perfect_plan_for_your_need:
    'Choose the perfect plan for your needs. Always flexible to grow',
  sync_now: 'Sync Now',
  import_now: 'Import Now',
  home: 'Home',
  skip_data_sync: 'Skip data sync',
  contact: 'Contact',
  deal: 'Deal',
  products: 'Products',
  settings: 'Settings',
  logs: 'Logs',
  hubspot_portal_id: 'HubSpot {{value}}',
  historical_data_sync_enabled: 'Data Sync {{value}}',
  contact_synced: 'CONTACTS SYNCED',
  deals_synced: 'DEALS SYNCED',
  products_synced: 'PRODUCTS SYNCED',
  waiting_to_sync: '{{value}} waiting to Sync',
  last_sync: 'Last Sync: {{value}}',
  sync_error_occureds: 'Sync Errors',
  manage_notifications: 'Manage notifications',
  alert_picklist_sync_error_found: 'Alert Picklist Sync Error Found',
  this_is_an_info_alert: 'This is an Info alert — check it out!',
  creating_workflows: 'Creating Workflows . . .',
  creating_pipelines: 'Creating Pipelines . . .',
  creating_lists: 'Creating Lists . . .',
  yes_take_me_to_hubspot: 'Yes, take me to HubSpot',
  setup_group_properties: 'Set up groups & properties in HubSpot',
  setup_line_1:
    'In order to view your Shopline data correctly in HubSpot, you need to set up groups and properties in your HubSpot account.',
  setup_line_2:
    'Once you set up groups and properties, you can easily see the following information about your contacts and customers:',
  setup_line_3:
    'Group and property creation is in progress. This should only take a few moments. Thanks for your patience!',
  setup_line_4:
    'Set up lists to segment your contacts and customers based on their previous actions and behaviors.',
  create_groups_properties: 'Create Groups & Properties',
  view_dashboard: 'View Dashboard',
  dashboard: 'Dashboard',
  product_information: 'Product Information',
  order_information: 'Order Information',
  customer_information: 'Customer Information',
  previous_purchase: 'Previous purchases',
  abandoned_cart_details: 'Abandoned cart details',
  and_more: 'And more',
  create_lists_in_hubspot: 'Create lists in HubSpot',
  lists_creation_in_progress:
    'Lists creation is in progress. This should only take a few moments. Thanks for your patience!',
  leads_contact: ' Leads: contacts that have not yet made any orders',
  create_list: 'Create Lists',
  customer_contact: ' Customers: contacts that have made at least one order',
  abandoned_cart_contacts:
    ' Abandoned Cart: contacts that have added products to their carts, but have not purchased',
  workflows_creation_in_progress:
    'Workflows creation is in progress. This should only take a few moments. Thanks for your patience!',
  next_step: 'Next Step',
  gat_tailored_onboarding_emails_straight_to_your_inbox:
    'Get tailored onboarding emails straight to your inbox',
  once_sync_your_data_you_ll_see_you_Shopline_information_on_hubspot:
    'Once you sync your data, you’ll see all your Shopline information on HubSpot so you can start engaging with them right away.',
  almost_done_last_step_to_sync_existing_Shopline_data_to_hubspot:
    'You’re almost done! The last step is to sync your existing Shopline data to HubSpot. This will sync all your Contacts, Deals, and Products data to HubSpot.',
  sync_order_status_with_deal_stages: 'Sync order statuses with deal stages',
  sync_order_status_line_1:
    'So you can manage your eCommerce pipeline in shopline',
  shopline_order_status_deal_stages: 'Shopline Order Status & Deal stages',
  reset_to_default_mapping: 'Reset to Default Mapping',
  congrats: 'Congratulations',
  save: 'Save',
  please_contact_support: 'Please Contact Support',
  export: 'Export',
  view_in_hubspot: 'View in HubSpot',
  sync_selected_data_row: 'Sync Selected Data Row',
  first_name: 'First Name',
  last_name: 'Last Name',
  company: 'Company',
  email: 'Email',
  phone: 'Phone',
  search_customer: 'Search Email',
  has_been_found: 'has been found',
  customer: 'Customer',
  you_can_see_your_sync_progress_in_the_activity_tab:
    'You can see your sync progress in the Activity tab.',
  sync_unsuccessfull: 'Sync unsuccessfull',
  unable_to_sync_all_the_data:
    'Unable to sync the Data. Please try again to sync.',
  close_this_window_to_see_updated_data_tables:
    'Close this window to see updated data table',
  select_atleast_one_product_to_export:
    'Please select atleast one product to export',
  status: 'Status',
  all_the_data_is_successfully_synced: 'Data is successfully synced.',
  sync_in_progress: 'Sync in progress',
  sync_successfull: 'Sync successfull',
  name: 'Name',
  please_sync_first: 'Please sync first',
  search_product: 'Search Product Name',
  search_deals: 'Search Deal ID',
  price: 'Price',
  title: 'Title',
  go_to_support: 'Go to Support',
  product_type: 'Product Type',
  contact_values: 'Contact Values',
  deal_status: 'Deal Status',
  sorry_text_404:
    'Sorry, we couldn’t find the page you’re looking for. Perhaps you’ve mistyped the URL? Be sure to check your spelling.',
  no_activities_found: 'No Activities Found',
  activities: 'Activities',
  synced: 'Synced',
  active: 'Active',
  inactive: 'Inactive',
  not_synced: 'Not Synced',
  product_mapping: 'Product Field Mapping',
  deal_mapping: 'Deal Field Mapping',
  contact_mapping: 'Contact Field Mapping',
  view_details: 'View Details',
  hubspot_data_logs: 'HubSpot Data Logs',
  note: 'Note: HubSpot logs could get used to filtering the HubSpot errors/warning occurred during data syncing.',
  error_type: 'Error Type:',
  object_type: 'Object Type:',
  internal_object_id: 'Internal Object ID',
  action: 'Action',
  created_at: 'Created at',
  view_all: 'View All',
  no: 'No',
  operation: 'Operation',
  deal_contact: 'Deal Contact',
  line_item: 'Line Item',
  line_item_deal: 'Line Item Deal',
  all: 'All',
  token_got_expired_please_refresh: 'Token got expired please refresh',
  select_a_time_period: 'Select a Time Period',
  start_date: 'Start Date',
  end_date: 'End Date',
  clear_all: 'Clear All',
  from: 'From',
  save_mapping: 'Save mapping',
  go_to_dashboard: 'Go To Dashboard',
  date_range_for_user: 'Date range for user/order sync',
  to: 'To',
  created: 'Created',
  create: 'Create',
  lists: 'Lists',
  workflow: 'Workflow',
  workflow_line_1:
    'Workflows contain a set of actions that are automatically executed based on a trigger event.',
  workflow_details: 'Workflow Details',
  setup_onboarding: 'Setup Onboarding',
  congrats_hubspot_connected: 'Congratulations HubSpot Connected',
  success: 'Success',
  syncing_started: 'Syncing process is now started',
  starting_sync: 'Starting syncing',
  sync_error_occured: 'Some error occured',
  sync_updates: 'Syncing updates',
  start_setup_onboarding: 'Start Onboarding',
  recent_notifications_to_stay_up_to_date:
    'Recent notifications to stay up-to-date. ',
  enable_two_way_sync_for_contact_tags: 'Enable two way sync for contact tags',
  enable_abondoned_cart_sync: 'Enable abandoned cart sync',
  enable_hubspot_tracking_script: 'Enable HubSpot tracking script',
  groups: 'Groups',
  log_detail: 'Log Details',
  close: 'Close',
  general_setting: 'General Settings',
  rfm_roi_settings: 'RFM & ROI Settings',
  rfm_and_roi_line_1:
    'With RFM settings, you can segment your customers based on their buying activities like spending value, order frequency, etc.',
  gropus_and_properties_line1:
    'Groups & properties store data on certain objects in HubSpot such as contacts, companies, deals, and tickets.',
  list_line_1:
    'In Lists, you can store records that share similar characteristics with each other. You can use lists to segment your data.',
  score: 'Score',
  recency: 'Recency',
  frequency: 'Frequency',
  monetary: 'Monetary',
  less_than: 'Less than',
  more_than: 'More than',
  rating_for_rfm_segmentation: '(Rating for RFM Segmentation)',
  days_since_last_order: '(Days Since last Order)',
  total_orders_placed: '(Total Orders Placed)',
  total_money_spent: '(Total Money Spent)',
  roi_calculation_setting: 'ROI Calculation Setting',
  rfm_settings_manage_roi_tracking: 'RFM Settings (Manage ROI tracking)',
  calculate_roi_for_the_selected_status:
    'Calculate ROI for the selected status',
  roi_setting: 'ROI Setting',
  rfm_setting: 'RFM Setting',
  invalid_token: 'Invalid Token',
  deals: 'Deals',
  customers: 'Customers',
  deal_number: 'Deal Number',
  product_id: 'Product ID',
  unsynced: 'Unsynced',
  all_sync_related_settings: 'All syncing related settings',
  something_went_wrong: 'Something went wrong',
  select_a_date_range_for_syncing: 'Select a date range for syncing',
  select_your_plan: 'Select your plan',
  coming_soon: 'Coming Soon . . . ',
  plan_pricing: 'Plan & Pricing',
  plan: 'Plan',
  total_order: 'Total Orders',
  available_order: 'Available Orders',
  used_order: 'Used Orders',
  your_active_plan: 'YOUR ACTIVE PLAN',
  cancel_plan: 'Cancel Plan',
  upgrade_plan: 'Upgrade Plan',
  start_plan: 'Start Plan',
  credit: 'Credit',
  monthly: 'Monthly',
  month: 'month',
  year: 'year',
  yearly: 'Yearly',
  save_yearly: '(Save {{value}}%)',
  name_is_required: 'Name is required',
  phone_number_not_valid: 'Phone number not valid',
  email_must_be_a_valid_email_address: 'Email must be a valid email address',
  address_is_required: 'Address is required',
  city_is_required: 'City is required',
  state_is_required: 'State is required',
  zip_is_required: 'Zip is required',
  country_is_required: 'Country is required',
  amount_does_not_match_with_plan: 'Amount does not match with plan',
  issue_payment_failed: 'Payment failed',
  payment_successful: 'Payment successful',
  plan_urgraded: 'Plan Upgraded Successfully',
  payment: 'Payment',
  billing_details: 'Billing Details',
  full_name: 'Full Name',
  email_address: 'Email Address',
  address: 'Address',
  city: 'city',
  state: 'State',
  zip: 'Zip',
  country: 'Country',
  select_country: 'Select Country',
  payment_info: 'Payment Info',
  order_summary: 'Order Summary',
  discount: 'Discount',
  apply: 'Apply',
  complete_payment: 'Complete Payment',
  saving_a_year: 'Saving ${{value}} a year',
  sub_total: 'Sub Total',
  add_new_portal: 'Add new Portal',
  subscription_cancel_redirect_plan:
    'Subscription cancelled redirect to plan list',
  previous_proccess_in_progress: 'Previous proccess in progress, kindly wait',
  invoice_history: 'Invoice History',
  no_record: 'No Record Found',
  confirm_popup:
    'Upload tasks will get terminated if you switch at the moment.\n \nDo you really want to switch the portal ?',
  importing: 'Importing',
  your_plan_has_been_expired: 'Your Plan has been expired!',
  expiry_date: 'Expiry Date',
  Hubspot: 'HubSpot',
  you_dont_have_any_active_plan: `You don't have any active plan!`,
  ongoing: 'Ongoing',
  completed: 'completed',
  groups_properties: 'Groups & Properties',
  list: 'List',
  enabled: 'Enabled',
  disabled: 'Disabled',
  manage: 'manage',
};

export default en;
