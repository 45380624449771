// @mui
import {
  Badge,
  Button,
  Card,
  Container,
  Grid,
  Stack,
  Switch,
  Typography,
  Box,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import Page from 'components/Page';
import { useState } from 'react';
import useConnector from 'hooks/useConnector';
import { useGetAllPlans } from 'api/ShoplineApiHooks';
import PartialPageSpinner from 'components/PartialPageSpinner';
import _ from 'lodash';
import { CheckListItems } from 'components/CheckListItem';
import { useLocation, useNavigate } from 'react-router';
import { SHOPLINE_NAVIGATION } from 'routes/paths';
import usePortalId from 'hooks/usePortalId';
import useResponsive from 'hooks/useResponsive';
import { PlanDescription } from 'components/PlanDescription';

const ButtonSX = {
  backgroundColor: 'transparent',
  color: '#0061FF',
  border: '1px solid',
  '&:hover': {
    backgroundColor: '#0061FF',
    color: '#FFFFFF',
  },
};

const servicesArray: { code: any; textColor: string; iconColor: string }[] = [
  {
    code: [
      'Sync unlimited customers',
      'Sync unlimited customers',
      'Sync unlimited customers',
    ],
    textColor: `#212B36`,
    iconColor: `#212B36`,
  },
  {
    code: [
      'Sync unlimited products',
      'Sync unlimited products',
      'Sync unlimited products',
    ],
    textColor: `#212B36`,
    iconColor: `#212B36`,
  },
  {
    code: [
      'Sync up to 100 orders/month',
      'Sync up to 500 orders/month',
      'Sync unlimited orders',
    ],
    textColor: `#212B36`,
    iconColor: `#212B36`,
  },
  {
    code: [
      'Sync abandoned carts',
      'Sync abandoned carts',
      'Sync abandoned carts',
    ],
    textColor: `#212B36`,
    iconColor: `#212B36`,
  },
  {
    code: [
      '90 Contact custom properties',
      'Unlimited historical data sync (Orders Limited to 500/month)',
      'Unlimited historical data sync',
    ],
    textColor: `#212B36`,
    iconColor: `#212B36`,
  },
  {
    code: [
      '5 Deal custom properties',
      '90 Contact custom properties',
      '90 Contact custom properties',
    ],
    textColor: `#212B36`,
    iconColor: `#212B36`,
  },
  {
    code: [
      '3 product custom properties',
      '5 Deal custom properties',
      '5 Deal custom properties',
    ],
    textColor: `#212B36`,
    iconColor: `#212B36`,
  },
  {
    code: [
      '3 Smart lists',
      '3 product custom properties',
      '3 product custom properties',
    ],
    textColor: `#212B36`,
    iconColor: `#212B36`,
  },
  {
    code: ['RFM Segmentation', '3 Smart lists', '3 Smart lists'],
    textColor: `#212B36`,
    iconColor: `#212B36`,
  },
  {
    code: [
      'Techincal support for Onboarding Process',
      '15 Ready to use Workflows',
      '15 Ready to use Workflows',
    ],
    textColor: `#212B36`,
    iconColor: `#212B36`,
  },
  {
    code: ['', 'RFM Segmentation', 'RFM Segmentation'],
    textColor: `#212B36`,
    iconColor: `#212B36`,
  },
  {
    code: ['', 'Technical support', 'Prioritized Technical support'],
    textColor: `#212B36`,
    iconColor: `#212B36`,
  },
];

export default function Plans() {
  const { t } = useTranslation();
  const [checked, setChecked] = useState(false);
  const { sourceShopId, targetShopId } = useConnector();
  const portalId = usePortalId();
  const { data } = useGetAllPlans(sourceShopId, targetShopId);
  const smUp = useResponsive('up', 'sm');
  const location = useLocation();
  const navigate = useNavigate();
  if (data?.data === undefined) {
    return <PartialPageSpinner />;
  }
  const rows = data?.data?.data.rows;
  const limitedRows = rows.filter(row => row.title !== 'Free');
  const choosePlan = (
    plan: string,
    amount: string,
    plan_type: string,
    monthly_price: number,
    yearly_price: number,
    planId: string,
    title: string,
    subscribed: string
  ) => {
    navigate(`${SHOPLINE_NAVIGATION.general.plan(portalId)}/payment`, {
      state: {
        plan: plan,
        amount: amount,
        plan_type: plan_type,
        monthly_price: monthly_price,
        yearly_price: yearly_price,
        planId: planId,
        title: title,
        subscribed: subscribed,
      },
    });
  };

  const mapFields = () => {
    return _.map(limitedRows, row => (
      <Grid item md={4} xs={12}>
        <Card
          sx={{
            position: 'relative',
            padding: '40px',
            border: '1px solid rgba(145, 158, 171, 0.32)',
            marginBottom: '30px',
          }}
        >
          {row.title === 'Advanced' && (
            <Badge
              sx={{
                position: 'absolute',
                right: 10,
                top: 10,
                backgroundColor: 'rgba(84, 214, 44, 0.16)',
                fontSize: '12px',
                padding: '5px 10px',
                color: '#229A16',
                borderRadius: '15px',
              }}
            >
              POPULAR
            </Badge>
          )}
          <Typography variant='h5' align='center' textTransform='uppercase'>
            {row.title}
          </Typography>
          <Typography variant='body1' align='center' m={1} color='#637381'>
            {row.description}
          </Typography>
          <Stack direction='row' alignItems='center' justifyContent='center'>
            <Typography
              variant='h2'
              mb={2}
              position='relative'
              className='before:absolute before:top-0 before:left-[-15px] before:text-[16px] before:text-[#637381] before:content-["$"]'
            >
              {!checked ? row.monthly_price : row.yearly_price}
            </Typography>
            <Typography variant='h6' sx={{ color: '#637381' }}>
              /{!checked ? t('month') : t('year')}
            </Typography>
          </Stack>
          {row.monthly_price * 12 - row.yearly_price !== 0 && checked ? (
            <Typography className='flex justify-center pb-6 text-[12px] text-[#229A16]'>
              {t('saving_a_year', {
                value: row.monthly_price * 12 - row.yearly_price,
              })}
            </Typography>
          ) : null}
          <Stack className='flex flex-col gap-[20px]'>
            {_.map(servicesArray, service => (
              <CheckListItems
                text={service.code}
                textColor={service.textColor}
                iconColor={service.iconColor}
                plan={row.title}
              />
            ))}
          </Stack>
          <Box sx={{ flexGrow: 1 }} />
          <Stack sx={{ marginTop: '40px' }}>
            <Button
              sx={ButtonSX}
              disabled={location.state?.title === row.title}
              onClick={() => {
                choosePlan(
                  row.title,
                  `${!checked ? row.monthly_price : row.yearly_price}`,
                  `${!checked ? 'month' : 'year'}`,
                  row.monthly_price,
                  row.yearly_price,
                  row.plan_id,
                  row.title,
                  location.state?.title
                );
              }}
            >
              Choose Plan
            </Button>
          </Stack>
        </Card>
      </Grid>
    ));
  };

  return (
    <Page title={t('logs')}>
      <div className='mx-[40px] ml-[64px] mb-[30px]'>
        <PlanDescription />
      </div>
      <div className='relative mx-[40px] ml-[64px]'>
        <Typography variant='h4' align='center' m={2}>
          {t('plan_pricing')}
        </Typography>
        <Grid sx={{ top: 0, right: 0, position: smUp ? 'absolute' : '' }}>
          <Stack direction='row'>
            <Typography
              variant='h5'
              m={1}
              color={!checked ? 'rgba(0, 97, 255, 1)' : ''}
            >
              {t('monthly')}
              <Switch
                sx={{
                  '.MuiSwitch-track': {
                    backgroundColor: '#90B2E8',
                  },
                  '.MuiSwitch-thumb': {
                    color: '#2065D1',
                  },
                }}
                onChange={() => setChecked(!checked)}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            </Typography>
            <Typography
              mt={1}
              variant='h5'
              color={checked ? 'rgba(0, 97, 255, 1)' : ''}
            >
              {t('yearly')}
            </Typography>
          </Stack>
        </Grid>
        <Grid container mt={5} spacing={4}>
          {mapFields()}
        </Grid>
      </div>
    </Page>
  );
}
